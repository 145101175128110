import React from "react";
import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  withStyles,
} from "@material-ui/core";

export function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    zIndex: 5,
    color: "white",
    position: "absolute",
    transform: `translate(-${top}%, -${left}%)`,
  } as React.StyleHTMLAttributes<any>;
}

export const ColorCreate = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText("#FFF"),
    backgroundColor: "#428bca",
    "&:hover": {
      backgroundColor: "#3276b1",
    },
  },
}))(Button);

export const ColorCancel = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText("#FFF"),
    backgroundColor: "#CE4257",
    "&:hover": {
      backgroundColor: "#b83044",
    },
  },
}))(Button);

export const CssTextField = withStyles({
  root: {
    "& input": {
      color: "white",
    },
    "& label": {
      color: "white",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#428bca",
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#428bca",
        color: "white",
      },
      "&:hover fieldset": {
        borderColor: "#428bca",
        color: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#428bca",
        color: "white",
      },
    },
  },
})(TextField);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const widget = [
  {
    type: "TABLE_WIDGET",
    name: "Table",
    pos: { x: 0, y: 1 },
    size: { x: 16, y: 12 },
    props: [
      {
        key: "tableData",
        value: "{{Read.data}}",
      },
      {
        key: "dynamicBindings",
        value: {
          tableData: true,
        },
      },
    ],
  },
  {
    type: "BUTTON_WIDGET",
    name: "Button",
    pos: { x: 0, y: 0 },
    size: { x: 2, y: 1 },
    props: [
      {
        key: "text",
        value: "Add New",
      },
      {
        key: "onClick",
        value: "{{showModal('Modal5')}}",
      },
    ],
  },
  {
    type: "BUTTON_WIDGET",
    name: "Button",
    pos: { x: 12, y: 0 },
    size: { x: 2, y: 1 },
    props: [
      {
        key: "text",
        value: "Update",
      },
      {
        key: "onClick",
        value: "{{showModal('Modal6')}}",
      },
    ],
    disabled: true,
  },
  {
    type: "BUTTON_WIDGET",
    name: "Button",
    pos: { x: 14, y: 0 },
    size: { x: 2, y: 1 },
    props: [
      {
        key: "text",
        value: "Delete",
      },
      {
        key: "buttonStyle",
        value: "DANGER_BUTTON",
      },
      {
        key: "onClick",
        value: "{{Delete.run(() => Read.run(), () => {})}}",
      },
    ],
    disabled: true,
  },
  {
    type: "MODAL_WIDGET",
    name: "Modal",
    pos: { x: 0, y: 2 },
    props: [
      {
        key: "width",
        value: 600,
      },
      {
        key: "height",
        value: 600,
      },
    ],
    size: { x: 0, y: 0 },
  },
  {
    type: "MODAL_WIDGET",
    name: "Modal",
    pos: { x: 0, y: 2 },
    props: [
      {
        key: "width",
        value: 600,
      },
      {
        key: "height",
        value: 600,
      },
    ],
    size: { x: 0, y: 0 },
  },
];
