import React, { useEffect, useRef } from "react";
import { Tour, TourProps } from "antd";

const HowToCreateServiceTour = (props: IHowToCreateServiceTour) => {
  const refCreateServiceButton = useRef<any>(null);
  //   const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    const createServiceBtnDOM = document.querySelector(
      ".create-service-btn-tour-step",
    );

    refCreateServiceButton.current = createServiceBtnDOM;
  }, []);

  const steps: TourProps["steps"] = [
    {
      placement: "rightBottom",
      title: "Create service",
      description: `The "Services" in UBOS allows you to manage various services, including the Flow Builder, UI editor, and Database. This module provides a centralized location to configure and work with these services efficiently.`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/01/service.gif"}
          alt="loading..."
        />
      ),
      style: {
        width: "600px",
      },
      onClose: () => props.handleFinishTour(),
      target: () => refCreateServiceButton.current,
    },
  ];
  return (
    <Tour open={true} steps={steps} onFinish={() => props.handleFinishTour()} />
  );
};

interface IHowToCreateServiceTour {
  handleFinishTour: () => void;
}

export default HowToCreateServiceTour;
