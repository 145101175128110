export enum WidgetTypes {
  FUNCTION_WIDGET = "FUNCTION_WIDGET",
  BUTTON_WIDGET = "BUTTON_WIDGET",
  TEMPLATE_WIDGET = "TEMPLATE_WIDGET",
  TEXT_WIDGET = "TEXT_WIDGET",
  ACCORDION_WIDGET = "ACCORDION_WIDGET",
  IMAGE_WIDGET = "IMAGE_WIDGET",
  MOB_HEADER_WIDGET = "MOB_HEADER_WIDGET",
  REPEAT_WIDGET = "REPEAT_WIDGET",
  INPUT_WIDGET = "INPUT_WIDGET",
  CONTAINER_WIDGET = "CONTAINER_WIDGET",
  DATE_PICKER_WIDGET = "DATE_PICKER_WIDGET",
  TABLE_WIDGET = "TABLE_WIDGET",
  DROP_DOWN_WIDGET = "DROP_DOWN_WIDGET",
  CHECKBOX_WIDGET = "CHECKBOX_WIDGET",
  RADIO_GROUP_WIDGET = "RADIO_GROUP_WIDGET",
  TABS_WIDGET = "TABS_WIDGET",
  KANBAN_BOARD_WIDGET = "KANBAN_BOARD_WIDGET",
  FILE_MANAGER = "FILE_MANAGER",
  MODAL_WIDGET = "MODAL_WIDGET",
  REPEAT_MODAL_WIDGET = "REPEAT_MODAL_WIDGET",
  TRANSFORM_WIDGET = "TRANSFORM_WIDGET",
  RICH_TEXT_EDITOR_WIDGET = "RICH_TEXT_EDITOR_WIDGET",
  CHART_WIDGET = "CHART_WIDGET",
  FORM_WIDGET = "FORM_WIDGET",
  FORM_BUTTON_WIDGET = "FORM_BUTTON_WIDGET",
  MAP_WIDGET = "MAP_WIDGET",
  CANVAS_WIDGET = "CANVAS_WIDGET",
  ICON_WIDGET = "ICON_WIDGET",
  FILE_PICKER_WIDGET = "FILE_PICKER_WIDGET",
  VIDEO_WIDGET = "VIDEO_WIDGET",
  SKELETON_WIDGET = "SKELETON_WIDGET",
  FILE_PICKER_S3_WIDGET = "FILE_PICKER_S3_WIDGET",
  LABEL_WIDGET = "LABEL_WIDGET",
  SLIDER_WIDGET = "SLIDER_WIDGET",
  TRANSFORMERS_WIDGET = "TRANSFORMERS_WIDGET",
  IFRAME_WIDGET = "IFRAME_WIDGET",
}

export type WidgetType = keyof typeof WidgetTypes;

export type ContainerOrientation = "HORIZONTAL" | "VERTICAL";

export const PositionTypes: { [id: string]: string } = {
  ABSOLUTE: "ABSOLUTE",
  CONTAINER_DIRECTION: "CONTAINER_DIRECTION",
};
export type PositionType = typeof PositionTypes[keyof typeof PositionTypes];

export type CSSUnit =
  | "px"
  | "cm"
  | "mm"
  | "in"
  | "pt"
  | "pc"
  | "em"
  | "ex"
  | "ch"
  | "rem"
  | "vw"
  | "vh"
  | "vmin"
  | "vmax"
  | "%";

export type RenderMode =
  | "COMPONENT_PANE"
  | "CANVAS"
  | "PAGE"
  | "CANVAS_SELECTED";

export const RenderModes: { [id: string]: RenderMode } = {
  COMPONENT_PANE: "COMPONENT_PANE",
  CANVAS: "CANVAS",
  PAGE: "PAGE",
  CANVAS_SELECTED: "CANVAS_SELECTED",
};

export const CSSUnits: { [id: string]: CSSUnit } = {
  PIXEL: "px",
  RELATIVE_FONTSIZE: "rem",
  RELATIVE_PARENT: "%",
};

export const GridDefaults = {
  DEFAULT_CELL_SIZE: 1,
  DEFAULT_WIDGET_WIDTH: 200,
  DEFAULT_WIDGET_HEIGHT: 100,
  DEFAULT_GRID_COLUMNS: 32,
  DEFAULT_GRID_ROW_HEIGHT: 40,
  CANVAS_EXTENSION_OFFSET: 2,
};

// export const CONTAINER_GRID_PADDING =
//   (GridDefaults.DEFAULT_GRID_ROW_HEIGHT / 2) * 0.8;
export const CONTAINER_GRID_PADDING = 3;

export const WIDGET_PADDING = (GridDefaults.DEFAULT_GRID_ROW_HEIGHT / 2) * 0.2;

export const WIDGET_CLASSNAME_PREFIX = "WIDGET_";
export const MAIN_CONTAINER_WIDGET_ID = "0";
export const MAIN_CONTAINER_WIDGET_NAME = "MainContainer";

export const WIDGET_DELETE_UNDO_TIMEOUT = 7000;

export const dslDefault = {
  backgroundColor: "none",
  bottomRow: 1280,
  canExtend: true,
  children: [],
  containerStyle: "none",
  detachFromLayout: true,
  dynamicBindings: {},
  leftColumn: 0,
  minHeight: 1292,
  parentColumnSpace: 1,
  parentRowSpace: 1,
  rightColumn: 1492,
  snapColumns: 32,
  snapRows: 33,
  topRow: 0,
  type: "CANVAS_WIDGET",
  version: 6,
  widgetId: "0",
  widgetName: "MainContainer",
};
