import React, { Fragment } from "react";
import { CommonComponentProps, Classes } from "components/ads/common";
import { getInitialsAndColorCode } from "utils/AppsmithUtils";
import { useDispatch, useSelector } from "react-redux";
import { getThemeDetails } from "selectors/themeSelectors";
import Text, { TextType } from "components/ads/Text";
import styled, { createGlobalStyle } from "styled-components";
import { Position } from "@blueprintjs/core";
import { ReactComponent as LogoutIcon } from "assets/icons/ads/logout.svg";
import Menu from "components/ads/Menu";
import ThemeSwitcher from "./ThemeSwitcher";
import MenuDivider from "components/ads/MenuDivider";
import MenuItem from "components/ads/MenuItem";
import {
  getOnSelectAction,
  DropdownOnSelectActions,
} from "./CustomizedDropdown/dropdownHelpers";
import { ReduxActionTypes } from "constants/ReduxActionConstants";

import { ReactComponent as TemplateIcon } from "../../assets/images/work_space/templateIcon.svg";
import { ReactComponent as UserIcon } from "../../assets/images/work_space/user.svg";
import { ReactComponent as WorkspaceIcon } from "../../assets/images/work_space/SideIcon.svg";
import { ReactComponent as Monitor } from "../../assets/images/work_space/monitor.svg";
import { ReactComponent as Billing } from "../../assets/images/work_space/dolar-icon.svg";
import { ReactComponent as DocsIcon } from "../../assets/images/work_space/docs.svg";

type TagProps = CommonComponentProps & {
  onClick?: (text: string) => void;
  userName?: string;
};

export const ProfileImage = styled.div<{ backgroundColor?: string }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
`;

const ProfileMenuStyle = createGlobalStyle`
  .bp3-popover {
    box-shadow: none;
  }
  .profile-menu {
    .bp3-popover .bp3-popover-content{
      margin-top: 2px;
    }
  }
`;

const UserInformation = styled.div`
  padding: ${props => props.theme.spaces[6]}px;
  display: flex;
  align-items: center;
  background: #dddddd;
  .user-name {
    flex-basis: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .${Classes.TEXT} {
      color: black;
    }
  }

  .user-image {
    margin-right: ${props => props.theme.spaces[4]}px;
    div {
      cursor: default;
    }
  }
`;

const Logout = styled.div`
  /* padding: ${props => props.theme.spaces[6]}px; */
  padding: 8px 14px;
  display: flex;
  gap: 5px;
  align-items: center;
  border-top: 1px solid #c2c2c282;
  background: #dddddd;
  &:hover {
    background: #efefef;
  }
  cursor: pointer;
  .user-name {
    flex-basis: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .${Classes.TEXT} {
      color: black;
    }
  }

  .user-image {
    /* margin-right: 13px; */
    display: flex;
    div {
      cursor: default;
    }
  }
`;

const NavigateItem = styled.div`
  background: #dddddd;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 14px;
  cursor: pointer;
  transition: 0.3s ease;
  transition: 0.5s;

  &:hover {
    background: #efefef;
    transition: 0.5s;

    svg {
      transition: 0.5s;
    }
  }

  svg {
    transition: 0.5s;
    width: 20px;
  }

  svg > path {
    fill: #6c767e;
  }
`;

export default function ProfileDropdown(props: TagProps) {
  const dispatch = useDispatch();

  const themeDetails = useSelector(getThemeDetails);

  const initialsAndColorCode = getInitialsAndColorCode(
    props.userName,
    themeDetails.theme.colors.appCardColors,
  );

  const Profile = (
    <ProfileImage backgroundColor={"#3b82ff"}>
      <Text type={TextType.H6} highlight>
        {initialsAndColorCode[0]}
      </Text>
    </ProfileImage>
  );

  const openItem = (
    id: string,
    component: string,
    title: string,
    props?: any,
    type?: string,
  ) => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    const newItem = {
      id: JSON.stringify({ id, type }),
      component,
      title,
      props,
    };

    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const openTemplateManager = (
    id: string,
    type: string,
    component: string,
    props: any,
  ) => {
    const newItem = { id: JSON.stringify({ id, type }), component, props };

    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const isWorkspacePage = window.location.pathname === "/workspace";

  const menuItems = [
    {
      onClick: () =>
        openItem("UserSettings", "UserSettings", "User Settings", {
          navigateTo: "UserSettings",
        }),
      icon: <UserIcon></UserIcon>,
      label: "User Settings",
    },
    {
      onClick: () =>
        openItem("Credits", "UserSettings", "Billing", {
          navigateTo: "Credits",
        }),
      icon: <Billing></Billing>,
      label: "Billing",
    },
    // {
    //   onClick: () =>
    //     openItem(
    //       "WorkspaceSettings",
    //       "WorkspaceSetting",
    //       "Workspace Settings",
    //       {},
    //       "WorkspaceSettings",
    //     ),
    //   icon: <WorkspaceIcon></WorkspaceIcon>,
    //   label: "Workspace Settings",
    // },
    {
      onClick: () =>
        openTemplateManager(
          "TemplateManagerV2",
          "TemplateManagerV2",
          "TemplateManagerV2",
          {
            url: "https://ubos.tech/templates",
            title: "Asset Marketplace",
          },
        ),
      icon: <TemplateIcon></TemplateIcon>,
      label: "Asset Marketplace",
    },
    {
      onClick: () =>
        openTemplateManager(
          "TemplateManagerV2",
          "TemplateManagerV2",
          "TemplateManagerV2",
          {
            url: `https://template-manager.${location.host}/template_setting`,
            title: "My Templates",
          },
        ),
      icon: <TemplateIcon></TemplateIcon>,
      label: "My Templates",
    },
    // {
    //   onClick: () =>
    //     openItem("TemplateManager", "TemplateManager", "Asset Marketplace"),
    //   icon: <TemplateIcon></TemplateIcon>,
    //   label: "Asset Marketplace",
    // },
    // {
    //   onClick: () =>
    //     openItem("Logs", "UserSettings", "Logs", {
    //       navigateTo: "Load",
    //     }),
    //   icon: <Monitor></Monitor>,
    //   label: "Logs",
    // },
    {
      onClick: () => window.open("https://documentation.ubos.tech/docs/intro"),
      icon: <DocsIcon></DocsIcon>,
      label: "Docs",
    },
  ];

  return (
    <Fragment>
      <ProfileMenuStyle />
      <Menu
        className="profile-menu"
        position={Position.BOTTOM}
        target={Profile}
      >
        <UserInformation>
          <div className="user-image">{Profile}</div>
          <div className="user-name">
            <Text type={TextType.P1} highlight>
              {props.userName}
            </Text>
          </div>
        </UserInformation>
        {!isWorkspacePage &&
          menuItems.map(item => (
            <NavigateItem key={item.id} onClick={item.onClick}>
              {item.icon}
              <span>{item.label}</span>
            </NavigateItem>
          ))}

        {/* <NavigateItem onClick={openCreditsPage}>
          <UserIcon></UserIcon>
          <span>User settings</span>
        </NavigateItem>
        <NavigateItem onClick={openTemplateManager}>
          <TemplateIcon></TemplateIcon>
          <span>Asset Marketplace</span>
        </NavigateItem> */}
        {/*<MenuDivider />
        <ThemeSwitcher />
        <MenuDivider />*/}
        <Logout
          onClick={() =>
            getOnSelectAction(DropdownOnSelectActions.DISPATCH, {
              type: ReduxActionTypes.LOGOUT_USER_INIT,
            })
          }
        >
          {/* <MenuItem
            icon="logout"
            text="Sign Out"
            className="t--logout-icon white_logout"
            onSelect={() =>
              getOnSelectAction(DropdownOnSelectActions.DISPATCH, {
                type: ReduxActionTypes.LOGOUT_USER_INIT,
              })
            }
          /> */}
          <div className="user-image">
            <LogoutIcon fill="#6c767e" height={"20px"} />{" "}
          </div>
          <div className="user-name">
            <Text type={TextType.P1} highlight>
              {"Sign Out"}
            </Text>
          </div>
        </Logout>
      </Menu>
    </Fragment>
  );
}
