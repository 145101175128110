import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "reducers";
import {
  getDataSources,
  getInstalledTemplateList,
} from "selectors/editorSelectors";
import { Datasource } from "api/DatasourcesApi";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import WorkspaceMigrationModal from "./WorkspaceMigrationModal";
import IntroductoryModal from "./Modals/IntroductoryModal";
import SuccessInstallTemplateModal from "./Modals/SuccessInstallTemplateModal";
import LeaveWorkspaceModal from "./Modals/LeaveWorkspaceModal";

const ModalWrapper = (props: any) => {
  const dispatch = useDispatch();

  const userModals = useSelector((state: AppState) => {
    return state.ui.users.modals;
  });
  const installedTemplateList: Datasource[] = useSelector(
    getInstalledTemplateList,
  );
  const datasources: Datasource[] = useSelector(getDataSources);
  const currentOrg = useSelector((state: AppState) => {
    return state.ui.orgs.currentOrg;
  });

  const userTours = useSelector((state: AppState) => {
    return state.ui.users.userTours;
  });
  const [activeModal, setActiveModal] = useState() as any;

  const updModalState = (data: any) => {
    dispatch({
      type: ReduxActionTypes.UPD_USER_MODAL,
      payload: data,
    });
  };

  useEffect(() => {
    const modals = Object.keys(userModals).map(key => ({
      name: key,
      isOpen: userModals[key].isOpen,
    }));
    const firstOpenModal = modals.find(modal => modal.isOpen);
    const leaveWorkspaceModal = modals.find(
      modal => modal.name === "leaveWorkspaceModal" && modal.isOpen,
    );
    const firstLogin = modals.find(
      modal => modal.name === "firstLogin" && modal.isOpen,
    );
    if (firstLogin) {
      setActiveModal(firstLogin);
    } else {
      if (leaveWorkspaceModal) {
        // set higher priority modal
        setActiveModal(leaveWorkspaceModal);
      } else {
        setActiveModal(firstOpenModal);
      }
    }
  }, [userModals]);

  useEffect(() => {
    if (
      userModals &&
      userModals.workspaceMigrationModal.isOpen &&
      datasources.length > 0 &&
      !currentOrg.plan_id
    ) {
      const getOrgIdDS = datasources[0].organizationId;

      if (getOrgIdDS === currentOrg._id)
        updModalState({
          workspaceMigrationModal: {
            isOpen: true,
          },
        });
    } else {
      updModalState({
        workspaceMigrationModal: {
          isOpen: false,
        },
      });
    }
  }, [currentOrg, datasources]);

  useEffect(() => {
    const isOpen = installedTemplateList && installedTemplateList.length > 0;

    updModalState({
      openTemplateModal: { isOpen },
    });
  }, [installedTemplateList]);

  const _isOpenTour = Object.keys(userTours)
    .map(key => ({
      name: key,
      isOpen: userTours[key].isOpen,
    }))
    ?.find(modal => modal.isOpen);
  // add more priority for openTemplateModal
  const isOpenTour = _isOpenTour
    ? activeModal?.name === "openTemplateModal"
      ? false
      : true
    : false;

  return (
    <>
      {!isOpenTour && activeModal && activeModal.name && (
        <>
          {activeModal.name === "introductoryModal" &&
            updModalState({
              introductoryModal: {
                isOpen: false,
              },
            })
            // <IntroductoryModal updModalState={updModalState} />/
          }
          {/* {activeModal.name === "installFirstTemplateModal" && (
            <InstallFirstTemplateModal
              handleTemplateManager={props.handleTemplateManager}
              updModalState={updModalState}
            />
          )} */}
          {activeModal.name === "openTemplateModal" && (
            <SuccessInstallTemplateModal updModalState={updModalState} />
          )}
          {activeModal.name === "workspaceMigrationModal" && (
            <WorkspaceMigrationModal
              handleMigrateWorkspace={props.handleMigrateWorkspace}
              updModalState={updModalState}
            />
          )}
          {activeModal.name === "leaveWorkspaceModal" && (
            <LeaveWorkspaceModal updModalState={updModalState} />
          )}
        </>
      )}
    </>
  );
};

export default ModalWrapper;
