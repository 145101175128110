import { Skeleton } from "antd";
import styled from "styled-components";

export const StyledSkeleton = styled(Skeleton)`
  padding: 0 10px;

  .ant-skeleton-content > .ant-skeleton-paragraph {
    margin: 10px 0;

    li {
      &:not(:first-child) {
        margin-block-start: 10px;
      }
    }
  }
`;

export const Wrapper = styled.div`
  &:hover {
    .service-actions-wrapper {
      /* display: flex; */
    }
  }
`;

export const WrapperItemCollapse = styled.div`
  .bp3-collapse-body {
    border-left: 1px solid #afb4b8;
    margin-left: 8px;
  }

  .active {
    /* border: 1px solid #3b82ff; */
    background: #e8e8e8;
  }
`;

export const WrapperCollapse = styled.div`
  width: 100%;
  height: 22px;
  cursor: pointer;
  display: flex;
  padding: 3px 2px 2px 3px;
  justify-content: space-between;
  font-size: 12px;
  line-height: 12px;
  font-family: "Roboto";
  color: #495870;

  .open-context-menu {
    border: 1px solid #3b82ff;
  }

  &:hover {
    background: #e8e8e8;
  }

  /* div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 5px;

    div {
      width: 10px;
    }

    .bp3-collapse-body {
      border-left: 1px solid #6c767e;
      margin-left: 8px;
    }
  }  */
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5px;

  > div > svg {
    width: 10px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* span {
      padding-left: 4px;
    }

    .icon_open_close {
      width: 10px;
    } */

  .bp3-collapse-body {
    border-left: 1px solid #6c767e;
    margin-left: 8px;
  }
`;

export const AddServiceWrapper = styled.div`
  /* width: 16px; */
  cursor: pointer;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfdfdf;
  &:hover {
    background: #f3f3f3;
    color: #dfdfdf;
  }
`;

export const AddServiceBtn = styled.button`
  border: none;
  background-color: #3b82ff;
  padding: 1px 2px;
  border-radius: 4px;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: #2676ff;
  }
`;

export const ActionWrapp = styled.div`
  /* display: none; */
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 5px;

  div {
    /* width: 18px; */
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 1px; */

    svg {
      width: 15px;
      height: 15px;
    }

    &:hover {
      background: #e8e8e8;
      /* padding: 1px; */
      border-radius: 5px;
    }
  }
`;

export const EmptyProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  color: #495870;
  font-size: 12px;

  p {
    margin: 0;
  }

  button {
    border: none;
    background-color: #3b82ff;
    padding: 5px 0;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    color: #fff;
    margin: 0 auto;
  }
`;
