import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
// import EditableText, {
//   EditInteractionKind,
// } from "../../../components/ads/EditableText";
import styled from "styled-components";
import UploadModal from "../../../components/designSystems/appsmith/help/UploadModal";
import { ReactComponent as ArrowUp } from "../../../assets/images/work_space/rowup.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/work_space/arrowdown.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/work_space/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";
import {
  finishUserTourHandler,
  getUserCurrentUserForTour,
} from "pages/organization/helpers";
import { AppState } from "reducers";
import { getCurrentUser, getUserTours } from "selectors/usersSelectors";
import DatasourcesApi from "api/DatasourcesApi";
import { AppToaster } from "components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import { Button } from "antd";
import { DeleteOutlined, FolderOpenOutlined } from "@ant-design/icons";

const Pagination = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 15px;

  button {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
  }
  button:hover {
    background: #e9efff;
  }
  .items {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #6c767e;
    margin-right: 10px;
  }
  select {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 15px;
    cursor: pointer;
  }
`;

const Table = forwardRef(
  ({ userColumns, data, renderRowSubComponent }: any, ref) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      page,
      prepareRow,
      visibleColumns,
      gotoPage,
      previousPage,
      nextPage,
      pageCount,
      canNextPage,
      canPreviousPage,
      pageOptions,
      setPageSize,
      setGlobalFilter,
      state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
      {
        columns: userColumns,
        data,
        initialState: {
          sortBy: [
            {
              id: "Update Date",
              desc: true,
            },
          ],
        },
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination,
    );

    useImperativeHandle(ref, () => ({
      filter(value: string) {
        setGlobalFilter(value || undefined);
      },
    }));

    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr
                key={index}
                className="header"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, index: number) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div className="header_table">
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div className="sorting_table">
                              <div style={{ height: "10px" }}></div>
                              <ArrowDown></ArrowDown>
                            </div>
                          ) : (
                            <div className="sorting_table">
                              <ArrowUp></ArrowUp>
                              <div style={{ height: "10px" }}></div>
                            </div>
                          )
                        ) : (
                          <div className="sorting_table">
                            <ArrowUp></ArrowUp>
                            <ArrowDown></ArrowDown>
                          </div>
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                // Use a React.Fragment here so the table markup is still valid
                // eslint-disable-next-line react/jsx-key
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index1) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {/* {row.isExpanded ? (
                    <tr>
                      <td className="expanded" colSpan={visibleColumns.length}>
                        <SubTable row={row}></SubTable>
                      </td>
                    </tr>
                  ) : null} */}
                </>
              );
            })}
          </tbody>
        </table>

        <Pagination className="pagination">
          <span className="items">
            Page{" "}
            <span>
              {pageIndex + 1} of {pageOptions?.length}
            </span>{" "}
          </span>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}/page
              </option>
            ))}
          </select>
        </Pagination>
      </>
    );
  },
);

Table.displayName = "Table";
export default Table;

export const range = (len: any) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

export function makeData(orgs: any, ref?: any) {
  return orgs
    .filter((elem: any) => elem.applications.length > 0)
    .map((item: any, index: number, array: any[]) => {
      const isLast = index === array.length - 1;
      const serverInfo = item.organization.serverInfo[0];
      // const sharedUsersWithoutOwner = item.organization.sharedUsers.filter(
      //   (el: any) => el.type !== "administrator",
      // );

      const data = {
        name: item.organization.name,
        create_date: item.organization.createdAt,
        update_date: item.organization.updatedAt,
        status: item.organization.status,
        isSharedOrg: item.organization.isSharedOrg,
        sharedUsersCount: item.organization.sharedUsers?.length,
        buttons: {
          hrefEdit:
            "workspace/" +
            item.applications[0].id +
            "/pages/" +
            item.applications[0].pages[0].id +
            "/edit",
          href:
            "workspace/" +
            item.applications[0].id +
            "/pages/" +
            item.applications[0].pages[0].id,
          id: item.organization.id,
          name: item.organization.name,
          appId: item.applications[0].id,
          last: isLast,
          userPermissions: item.organization.userPermissions,
          ref,
        },
      } as any;

      if (serverInfo) {
        data.serverPlan = {
          name: `${serverInfo.description_name} (${serverInfo.cpu} vCPU - ${serverInfo.ram} GB RAM)`,
          icon: serverInfo.provider_info.icon,
        };
      } else {
        data.serverPlan = {
          name: "No current plan",
          icon: "",
        };
      }

      return data;
    });
}

const EditName = styled.div`
  width: 150px;
  .bp3-editable-text {
    height: 16px !important;
    padding: 0px 12px !important;
  }
  span {
    color: black !important;
  }
  input {
    background: white !important;
    color: black !important;
    border: 1px solid #ececec !important;
  }
  .bp3-editable-text {
    background: white !important;
    color: black !important;
  }
  .icon-wrapper {
    display: none !important;
  }
`;

export function DateCell({ value }: any) {
  return (
    <span>
      {value &&
        moment(value)
          .local()
          .format("DD-MM-YYYY hh:mm:ss a")}{" "}
    </span>
  );
}

export function NameCell(props: any) {
  const { value, row } = props;
  const isSharedOrg = row.original.isSharedOrg;

  const Wrapp = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    span:nth-child(2) {
      color: #3b82ff;
      border: 1px solid #3b82ff;
      border-radius: 25px;
      padding: 10px 15px;
      width: 75px;
      text-align: center;
    }
  `;

  return (
    <Wrapp>
      <span>{value}</span>
      {isSharedOrg && <span>Shared</span>}

      {/* <EditName>
        <EditableText
          defaultValue={value}
          editInteractionKind={EditInteractionKind.SINGLE}
          placeholder={"enter name"}
          savingState={value}
          onBlur={value => {
            console.log(value);
          }}
        />
      </EditName> */}
    </Wrapp>
  );
}

export function SharedUsersCell(props: any) {
  const { value } = props;

  const colorGrey = "#9E9E9E";
  const colorBlue = "#3b82ff";
  let color = colorGrey;

  if (value > 0) color = colorBlue;

  const Wrapp = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      position: relative;
      text-align: center;
      padding: 5px;
      border: 1px solid ${color};
      border-radius: 50%;

      span {
        position: absolute;
        top: -3px;
        right: -10px;
        background: ${color};
        color: #ffffff;
        padding: 8px 5px;
        border-radius: 50%;
        font-size: 13px;
      }

      svg {
        width: 20px;
        height: 20px;
        fill: ${color};
      }
    }
  `;

  return (
    <Wrapp>
      <div>
        <UserIcon></UserIcon>
        <span>{value}</span>
      </div>
    </Wrapp>
  );
}

export function ServerTypeCell({ value }: any) {
  const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
  `;
  console.log(value, "valuevalue");
  return (
    <Wrapper>
      {value.name !== "No current plan" && (
        <img src={value.icon} width={25} alt="logo" />
      )}
      <span>{value.name}</span>
    </Wrapper>
  );
}

export function StatusCell(props: any) {
  const { value } = props;

  const colorRed = "#FF5252";
  const colorGreen = "#8BC34A";
  const colorGrey = "#9E9E9E";
  let color;

  if (value === "active") color = colorGreen;
  else color = colorRed;

  if (!value) color = colorGrey;

  const Wrapp = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    span {
      width: 70px;
      padding: 10px 15px;
      color: ${color};
      border: 1px solid ${color};
      border-radius: 25px;
    }
  `;

  return (
    <Wrapp>
      <span>{value ? value : "-"}</span>
    </Wrapp>
  );
}

const WrapperAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
    width: 45%;
  }
  button:hover {
    background: #e9efff;
  }
`;

export function ButtonCell({ value }: any) {
  const user = useSelector((state: AppState) => getCurrentUser(state));
  const userTours = useSelector((state: AppState) => getUserTours(state));

  const history = useHistory();
  const dispatch = useDispatch();
  const navigation = useCallback(() => {
    history.push(value.href);
  }, [value]);
  const navigationEdit = useCallback(() => {
    if (userTours && !userTours.openWorkspace) {
      finishUserTourHandler(
        { userId: user.id, tourName: "openWorkspace" },
        dispatch,
      );
      getUserCurrentUserForTour({}, dispatch);
    }

    dispatch({
      type: ReduxActionTypes.CUR_APP,
      payload: value.appId,
    });

    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_FIRST_INIT });
    dispatch({ type: ReduxActionTypes.FETCH_APPLICATION_FIRST_INIT });

    history.push(value.hrefEdit);
  }, [value]);
  const deleteOrg = (value: any) => {
    dispatch({
      type: ReduxActionTypes.DELETE_ORG_INIT,
      payload: {
        orgId: value.id,
        nameOrg: value.name,
      },
    });
  };
  const refModalDelete = useRef<{ openModal: any }>();
  const openModalDelete = () => {
    refModalDelete?.current?.openModal();
  };

  const preDeleteOrg = async () => {
    const getDSByOrgId = await DatasourcesApi.fetchDatasources(value.id);
    let isActionAllowed = true; // Початково дозволяємо дію

    if (Array.isArray(getDSByOrgId.data) && getDSByOrgId.data.length > 0) {
      for (const item of getDSByOrgId.data) {
        if (Array.isArray(item.datasources) && item.datasources.length > 0) {
          isActionAllowed = false; // Якщо хоча б один datasource не порожній, забороняємо дію
          break; // Виходимо з циклу, бо більше немає сенсу перевіряти інші об'єкти
        }
      }
    } else {
      isActionAllowed = true; // Якщо data порожня, дозволяємо дію
    }

    if (isActionAllowed) {
      openModalDelete();
      // виконати дію
    } else {
      AppToaster.show({
        message:
          "Workspace deletion not allowed. Services are present within the workspace",
        type: ToastType.WARNING,
      });
      // заборонити дію
    }
  };

  const commonButtonStyle = {
    marginLeft: 0,
    marginRight: 32,
  };

  const buttonStyle =
    (value.userPermissions.length === 2 &&
      value.userPermissions.includes("inviteUsers:organization") &&
      (value.userPermissions.includes("read:services") ||
        value.userPermissions.includes("manage:services"))) ||
    (value.userPermissions.length === 3 &&
      value.userPermissions.includes("inviteUsers:organization") &&
      value.userPermissions.includes("read:services") &&
      value.userPermissions.includes("manage:services"))
      ? commonButtonStyle
      : {};

  const DeleteButton = styled(Button)`
    :hover {
      background: #fff2f0 !important;
    }
  `;

  return (
    <WrapperAction>
      {value.last ? (
        <Button
          style={buttonStyle}
          icon={<FolderOpenOutlined />}
          ref={value.ref}
          onClick={navigationEdit}
        >
          open
        </Button>
      ) : (
        <Button
          style={buttonStyle}
          icon={<FolderOpenOutlined />}
          onClick={navigationEdit}
        >
          open
        </Button>
      )}
      {/* <button onClick={navigationEdit}>open</button> */}
      {value.userPermissions.some(
        e => e == "manage:organizations" || e == "manage:workspace",
      ) ? (
        <DeleteButton
          onClick={preDeleteOrg}
          shape="circle"
          icon={<DeleteOutlined />}
          danger
        ></DeleteButton>
      ) : (
        // <button >delete workspace</button>
        <></>
      )}

      <ModalDelete
        ref={refModalDelete}
        name={`workspace ${value.name} ?`}
        item={value}
        remove={deleteOrg}
      />
    </WrapperAction>
  );
}
