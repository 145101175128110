import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import { Colors } from "../../../constants/Colors";
import { Icon } from "@blueprintjs/core";

const WrapperModal = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: white;
  position: relative;
  width: 300px;
  .visible-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .wrapper_button {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    position: relative;
    button {
      width: 90%;
      background: white;
      height: 30px;
      border: 1px solid black;
      border-radius: 4px;
      cursor: pointer;
    }

    button:hover {
      background: #eee8e8;
    }
  }
`;

export const ModalInform = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));

  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      return !prev;
    });
  }, [isOpen, setIsOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={toggleOverlay}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <WrapperModal>
        <Icon
          className="visible-icon"
          icon="small-cross"
          iconSize={20}
          color={"black"}
          onClick={toggleOverlay}
        />
        <div className="title">{props.inform || "something went wrong"}</div>
        <div className="wrapper_button">
          <button onClick={toggleOverlay}>close</button>
        </div>
      </WrapperModal>
    </Modal>
  );
});
ModalInform.displayName = "ModalInform";
