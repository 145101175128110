import { AxiosPromise } from "axios";
import Api from "./Api";
import { ApiResponse } from "./ApiResponses";
import { OrgRole, Org } from "constants/orgConstants";

class ApiDatabase extends Api {
  static baseUrl = "v1";

  static dbInfo(id: string) {
    return Api.get(ApiDatabase.baseUrl + `/db_info/${id}`);
  }

  static dbCreate(data: any) {
    return Api.post(ApiDatabase.baseUrl + `/createDataBase`, data);
  }

  static dbDelete(data: any) {
    return Api.post(ApiDatabase.baseUrl + `/deleteDataBase`, data);
  }

  static dbTypes() {
    return Api.get(ApiDatabase.baseUrl + `/get_db_types`);
  }
}

export default ApiDatabase;
