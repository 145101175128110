import React, { Component } from "react";
import { connect } from "react-redux";
import { AppState } from "../../reducers";
import _ from "lodash";
import { AUTOCOMPLETE_CLOSE_KEY_CODES, EditorProps } from "./CodeEditor";
import { getDataTreeForAutocomplete } from "../../selectors/dataTreeSelectors";

import CodeMirror, { EditorConfiguration } from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/duotone-dark.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/mode/multiplex";
import "codemirror/addon/tern/tern.css";
import "components/editorComponents/CodeEditor/modes";
import {
  EditorSize,
  EditorTheme,
  EditorThemes,
  Hinter,
  TabBehaviour,
} from "./CodeEditor/EditorConfig";
import { bindingMarker } from "./CodeEditor/markHelpers";
import { bindingHint } from "./CodeEditor/hintHelpers";
import {
  EditorWrapper,
  HintStyles,
} from "components/editorComponents/CodeEditor/styledComponents";
import { getThemeDetails } from "../../selectors/themeSelectors";
import { updateWidgetPropertyRequest } from "../../actions/controlActions";
import { RenderModes } from "../../constants/WidgetConstants";
import { Theme, Tooltip, Typography, withStyles } from "@material-ui/core";
import styled from "styled-components";

type Items = {
  items: any;
  property: any;
  value: any;
  updateWidgetProperty: any;
  hinting?: any;
  marking?: any;
  height: any;
  title: string;
};
type StateProps = {
  dynamicData: any;
  marking: any;
  currentTheme: any;
};
type Props = Items & StateProps;

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export const CodeWrapper = styled.div`
  padding: 0 15px 15px 15px;
  .CodeMirror {
    background: #ececec;
    .CodeMirror-gutters {
      background: #d9d9d9;
    }
    .CodeMirror-linenumber {
      color: #6c767e;
    }
    pre {
      .cm-def {
        color: #2f79fc;
      }
      .cm-keyword {
        color: #a80034;
      }
    }
  }
`;

class TransformEditorJS extends Component<Props, any> {
  static defaultProps = {
    marking: [bindingMarker],
    hinting: [bindingHint],
  };
  state = {
    isFocused: false,
    isOpen: false,
  };
  textArea = React.createRef<HTMLTextAreaElement>();
  editor!: CodeMirror.Editor;
  hinters: Hinter[] = [];

  componentDidMount(): void {
    if (this.textArea.current) {
      const options: EditorConfiguration = {
        mode: "javascript",
        theme: "duotone-dark",
        viewportMargin: 10,
        tabSize: 2,
        autoCloseBrackets: true,
        indentWithTabs: true,
        smartIndent: true,
        lineWrapping: true,
        lineNumbers: true,
        addModeClass: true,
        scrollbarStyle: "native",
      };
      options.extraKeys = {};
      options.extraKeys["Tab"] = false;

      this.editor = CodeMirror.fromTextArea(this.textArea.current, options);

      this.editor.on("change", _.debounce(this.handleChange, 1000));
      this.editor.on("change", this.handleAutocompleteVisibility);
      this.editor.on("keyup", this.handleAutocompleteHide);
      this.editor.on("focus", this.handleEditorFocus);
      this.editor.on("blur", this.handleEditorBlur);
      this.editor.setSize("100%", "100%");
      // Set value of the editor
      let inputValue = this.props.value || "";
      if (typeof inputValue === "object") {
        inputValue = JSON.stringify(inputValue, null, 2);
      } else if (
        typeof inputValue === "number" ||
        typeof inputValue === "string"
      ) {
        inputValue += "";
      }
      this.editor.setValue(inputValue);
      this.updateMarkings();
      this.startAutocomplete();
      this.handleAutocompleteVisibility(this.editor);
    }
  }

  componentDidUpdate(prevProps: any): void {
    if (this.props.height !== prevProps.height) {
      this.editor.setSize("100%", "100%");
    }
    this.editor.refresh();
    if (!this.state.isFocused) {
      // const currentMode = this.editor.getOption("mode");
      const editorValue = this.editor.getValue();
      let inputValue = this.props.value;
      // Safe update of value of the editor when value updated outside the editor
      if (typeof inputValue === "object") {
        inputValue = JSON.stringify(inputValue, null, 2);
      } else if (
        typeof inputValue === "number" ||
        typeof inputValue === "string"
      ) {
        inputValue += "";
      }
      if ((!!inputValue || inputValue === "") && inputValue !== editorValue) {
        this.editor.setValue(inputValue);
      }
      this.updateMarkings();
    } else {
      // Update the dynamic bindings for autocomplete
      if (prevProps.dynamicData !== this.props.dynamicData) {
        this.hinters.forEach(
          hinter => hinter.update && hinter.update(this.props.dynamicData),
        );
      }
    }
    this.handleAutocompleteVisibility(this.editor);
  }

  updateMarkings = () => {
    this.props.marking.forEach(
      (helper: any) => this.editor && helper(this.editor),
    );
  };

  startAutocomplete() {
    if (this.props.hinting) {
      this.hinters = this.props.hinting.map((helper: any) => {
        return helper(this.editor, this.props.dynamicData);
      });
    }
  }

  handleEditorBlur = () => {
    this.handleChange();
    this.setState({ isFocused: false });
  };

  handleEditorFocus = () => {
    this.setState({ isFocused: true });
    this.editor.refresh();
  };

  handleAutocompleteHide = (cm: any, event: KeyboardEvent) => {
    if (AUTOCOMPLETE_CLOSE_KEY_CODES.includes(event.code)) {
      cm.closeHint();
    }
  };

  handleAutocompleteVisibility = (cm: CodeMirror.Editor) => {
    this.hinters.forEach(hinter => hinter.showHint(cm));
  };

  handleChange = (instance?: any, changeObj?: any) => {
    const value = this.editor.getValue();
    this.setState({ value: value });
    this.props.updateWidgetProperty(
      this.props.items.widgetId,
      this.props.property,
      value,
    );
    this.updateMarkings();
  };

  render() {
    return (
      <EditorWrapper
        editorTheme={EditorTheme.DARK}
        theme={this.props.currentTheme}
        hasError={false}
        size={EditorSize.EXTENDED}
        isFocused={this.state.isFocused}
        disabled={false}
        borderLess={false}
        style={{
          display: "flex",
          flexDirection: "column",
          background: "rgb(255 255 255)",
          height: "100%",
          padding: "5px 5px 0 px",
          overflow: "auto",
        }}
      >
        <HtmlTooltip
          placement="top-start"
          title={
            <div style={{ color: "black" }}>
              <Typography color="inherit">Example code</Typography>
              {`const array = {{Dropdown1.options}}.filter(e=>e.id !== '1');`}
              <br />
              {`const concat = {{Input1.text}} + 'test' + {{Input2.text}};`}
              <br />
              {`for (const i of {{Dropdown1.options}}){`}
              <br />
              {`}`}
              <br />
              {`let result;`}
              <br />
              {`if (array.length > 2) {`}
              <br />
              {`result = true;`}
              <br />
              {`else {`}
              <br />
              {`result = false;`}
              <br />
              {`}`}
              <br />
              {`return result;`}
              <br />
            </div>
          }
        >
          <div
            style={{
              background: "rgb(255 255 255)",
              height: "30px",
              color: "black",
              padding: "6px",
              fontFamily: "NovemberHebrew-Regular",
            }}
          >
            {this.props.title}
          </div>
        </HtmlTooltip>
        <CodeWrapper>
          <HintStyles editorTheme={EditorTheme.LIGHT} />
          <textarea ref={this.textArea} value={this.props.value} />
        </CodeWrapper>
      </EditorWrapper>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    dynamicData: getDataTreeForAutocomplete(state),
    marking: [bindingMarker],
    currentTheme: getThemeDetails(state).theme,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  updateWidgetProperty: (
    widgetId: string,
    propertyName: string,
    propertyValue: any,
  ) =>
    dispatch(
      updateWidgetPropertyRequest(
        widgetId,
        propertyName,
        propertyValue,
        RenderModes.CANVAS,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransformEditorJS);
