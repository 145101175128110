import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  reduxForm,
  InjectedFormProps,
  change,
  formValueSelector,
} from "redux-form";
import {
  HTTP_METHOD_OPTIONS,
  HTTP_METHODS,
} from "constants/ApiEditorConstants";
import styled from "styled-components";
import FormLabel from "components/editorComponents/FormLabel";
import FormRow from "components/editorComponents/FormRow";
import { BaseButton } from "components/designSystems/blueprint/ButtonComponent";
import { PaginationField } from "api/ActionAPI";
import DropdownField from "components/editorComponents/form/fields/DropdownField";
import { API_EDITOR_FORM_NAME } from "constants/forms";
import { BaseTabbedView } from "components/designSystems/appsmith/TabbedView";
import Pagination from "./Pagination";
import { PaginationType, RestAction } from "entities/Action";
import { Icon } from "@blueprintjs/core";
import { HelpMap, HelpBaseURL } from "constants/HelpConstants";
import CollapsibleHelp from "components/designSystems/appsmith/help/CollapsibleHelp";
import KeyValueFieldArray from "components/editorComponents/form/fields/KeyValueFieldArray";
import PostBodyData from "./PostBodyData";
import ApiResponseView from "components/editorComponents/ApiResponseView";
import EmbeddedDatasourcePathField from "components/editorComponents/form/fields/EmbeddedDatasourcePathField";
import { AppState } from "reducers";
import { getApiName } from "selectors/formSelectors";
import ActionNameEditor from "components/editorComponents/ActionNameEditor";
import ActionSettings from "pages/Editor/ActionSettings";
import { apiActionSettingsConfig } from "mockResponses/ActionSettings";
import { BASE, getFlows } from "../../../api/Nodered";
import Select from "react-select";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { collapseDuration } from "react-select/src/animated/transitions";
import { DEFAULT_DATASOURCE } from "../../../entities/Datasource";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${props => props.theme.headerHeight});
  overflow: auto;
  width: 100%;
  ${FormLabel} {
    padding: ${props => props.theme.spaces[3]}px;
  }
  ${FormRow} {
    padding: ${props => props.theme.spaces[2]}px;
    & > * {
      margin-right: 10px;
    }
    ${FormLabel} {
      padding: 0;
      width: 100%;
    }
  }
`;

const MainConfiguration = styled.div`
  padding-top: 10px;
  padding-left: 17px;
`;

const ActionButtons = styled.div`
  flex: 0 1 150px;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
`;

const ActionButton = styled(BaseButton)`
  &&& {
    max-width: 72px;
    &:last-of-type {
      margin-left: 16px;
    }
    min-height: 32px;
  }
`;

const DatasourceWrapper = styled.div`
  width: 100%;
`;

const SecondaryWrapper = styled.div`
  display: flex;
  height: calc(100% - 120px);
  border-top: 1px solid #d0d7dd;
  margin-top: 15px;
`;
export const NodeRedWrapper = styled.div`
  margin: 0px 18px 0px 5px;
  width: 100%;
`;
const TabbedViewContainer = styled.div`
  flex: 1;
  padding-top: 12px;
  &&& {
    ul.react-tabs__tab-list {
      padding-left: 23px;
    }
  }
`;

export const BindingText = styled.span`
  color: ${props => props.theme.colors.bindingTextDark};
  font-weight: 700;
`;

const StyledOpenDocsIcon = styled(Icon)`
  svg {
    width: 12px;
    height: 18px;
  }
`;
const RequestParamsWrapper = styled.div`
  flex: 4;
  border-right: 1px solid #d0d7dd;
  height: 100%;
  overflow-y: auto;
  padding-top: 6px;
  padding-left: 17px;
  padding-right: 10px;
`;

const SettingsWrapper = styled.div`
  padding-left: 15px;
  ${FormLabel} {
    padding: 0px;
  }
`;

const HeadersSection = styled.div`
  margin-bottom: 32px;
`;

interface APIFormProps {
  pluginId: string;
  onRunClick: (paginationField?: PaginationField) => void;
  onDeleteClick: () => void;
  isRunning: boolean;
  isDeleting: boolean;
  paginationType: PaginationType;
  appName: string;
  orgId?: any;
  httpMethodFromForm: string;
  actionConfigurationBody: Record<string, unknown> | string;
  actionConfigurationHeaders?: any;
  actionName: string;
  apiId: string;
  apiName: string;
}

type Props = APIFormProps & InjectedFormProps<RestAction, APIFormProps>;

export const NameWrapper = styled.div`
  width: 49%;
  display: flex;
  justify-content: space-between;
  input {
    margin: 0;
    box-sizing: border-box;
  }
`;

const ApiEditorForm: React.FC<Props> = (props: Props) => {
  const {
    pluginId,
    onDeleteClick,
    onRunClick,
    handleSubmit,
    isDeleting,
    isRunning,
    actionConfigurationHeaders,
    actionConfigurationBody,
    httpMethodFromForm,
    actionName,
  } = props;

  const dispatch = useDispatch();
  const [valueNodeRed, setValueNodeRed] = useState("");
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const keycloakAccessToken = useSelector((state: AppState) => {
    return state.ui.users.currentUser?.keycloakAccessToken;
  });
  const dataSourcesList: any = useSelector(
    (state: AppState) => state.entities.datasources.list,
  );
  const datasourceForm: any = useSelector(
    (state: any) => state.form?.ApiEditorForm,
  );

  const httpIns = currentApplication?.settings_data?.httpIns || [];

  const allowPostBody =
    httpMethodFromForm && httpMethodFromForm !== HTTP_METHODS[0];
  const handleNodeRed = useCallback(
    item => {
      setValueNodeRed(item.value);
    },
    [valueNodeRed],
  );

  const handleNodeRedHttpIn = useCallback(
    (event: any) => {
      const datasourceUrl = event.value.url;
      const { pluginId, orgId } = props;
      const datasource = datasourceForm;
      const urlHasUpdated =
        datasourceUrl !== datasource.datasourceConfiguration?.url;
      if (urlHasUpdated) {
        dispatch(
          change(API_EDITOR_FORM_NAME, "datasource", event.value.datasource),
        );
        props.change("actionConfiguration.path", event.value.url);
        props.change(
          "actionConfiguration.httpMethod",
          event.value.method.toUpperCase(),
        );
      }
    },
    [httpIns, props.change, change, datasourceForm],
  );
  const reloadNodeRead = useCallback(() => {
    const promise = [];
    for (const source of dataSourcesList) {
      if (
        source.settings_data &&
        source.settings_data?.applicationId === currentApplication?.id
      ) {
        promise.push(
          getFlows(source.settings_data.urlNodeRed, keycloakAccessToken),
        );
      }
    }

    Promise.all(promise).then(values => {
      const optionNodeRed = [];
      for (const resApi of values) {
        for (const item of resApi.data) {
          if (item.type === "http in") {
            const url = resApi?.config?.url?.split("/flows")[0];
            const findData = dataSourcesList.find((item: any) => {
              return item.datasourceConfiguration?.url === url;
            });
            const info = item.info ? " | " + item.info : "";
            optionNodeRed.push({
              label:
                item.method.toUpperCase() +
                " | " +
                item.name +
                " | " +
                item.url +
                info,
              value: {
                url: item.url,
                method: item.method,
                datasource: findData,
              },
            });
          }
        }
      }

      dispatch({
        type: ReduxActionTypes.UPDATE_APPLICATION_SETTINGS,
        payload: {
          id: currentApplication?.id,
          settings_data: {
            ...currentApplication?.settings_data,
            httpIns: optionNodeRed,
          },
        },
      });
    });
  }, []);
  const dataSourcesListFiltered = useMemo(() => {
    return dataSourcesList
      .filter(
        (source: any) =>
          source?.settings_data?.applicationId === currentApplication?.id,
      )
      .map((item: any) => {
        return {
          label: item.name,
          value: item.datasourceConfiguration?.url,
        };
      });
  }, [dataSourcesList, currentApplication]);

  const httpInsFiltered = useMemo(() => {
    return httpIns.filter((source: any) => {
      return (
        source.value.datasource.datasourceConfiguration.url === valueNodeRed
      );
    });
  }, [httpIns, valueNodeRed]);
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <MainConfiguration>
          <FormRow>
            <NameWrapper className="t--nameOfApi">
              <ActionNameEditor />
            </NameWrapper>
            <ActionButtons className="t--formActionButtons">
              <ActionButton
                text="Delete"
                accent="error"
                onClick={onDeleteClick}
                loading={isDeleting}
                className="t--apiFormDeleteBtn"
              />
              <ActionButton
                text="Run"
                accent="primary"
                filled
                onClick={() => {
                  onRunClick();
                }}
                loading={isRunning}
                className="t--apiFormRunBtn"
              />
            </ActionButtons>
          </FormRow>
          <FormRow>
            <DropdownField
              placeholder="Method"
              name="actionConfiguration.httpMethod"
              className="t--apiFormHttpMethod"
              options={HTTP_METHOD_OPTIONS}
              isSearchable={false}
            />
            <DatasourceWrapper className="t--dataSourceField">
              <EmbeddedDatasourcePathField
                name="actionConfiguration.path"
                pluginId={pluginId}
              />
            </DatasourceWrapper>
          </FormRow>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NodeRedWrapper>
              <Select
                options={dataSourcesListFiltered}
                placeholder="Enter NodeRed"
                onChange={handleNodeRed}
                onFocus={reloadNodeRead}
              />
            </NodeRedWrapper>
            {/*<button onClick={reloadNodeRead}>sync</button>*/}
          </div>
          <div
            style={{
              paddingRight: "23px",
              marginTop: "10px",
            }}
          >
            <NodeRedWrapper>
              <Select
                options={httpInsFiltered}
                placeholder="Enter HttpIn"
                onChange={handleNodeRedHttpIn}
              />
            </NodeRedWrapper>
          </div>
        </MainConfiguration>
        <SecondaryWrapper>
          <TabbedViewContainer>
            <BaseTabbedView
              tabs={[
                {
                  key: "apiInput",
                  title: "API Input",
                  panelComponent: (
                    <RequestParamsWrapper>
                      <CollapsibleHelp>
                        <span>{`Having trouble taking inputs from widget?`}</span>
                        <a
                          href={`${HelpBaseURL}${HelpMap["API_BINDING"].path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" Learn How "}
                          <StyledOpenDocsIcon icon="document-open" />
                        </a>
                      </CollapsibleHelp>
                      <HeadersSection>
                        <KeyValueFieldArray
                          name="actionConfiguration.headers"
                          label="Headers"
                          actionConfig={actionConfigurationHeaders}
                          placeholder="Value"
                          dataTreePath={`${actionName}.config.headers`}
                        />
                      </HeadersSection>
                      <KeyValueFieldArray
                        name="actionConfiguration.queryParameters"
                        label="Params"
                        dataTreePath={`${actionName}.config.queryParameters`}
                      />
                      {allowPostBody && (
                        <PostBodyData
                          actionConfigurationHeaders={
                            actionConfigurationHeaders
                          }
                          actionConfiguration={actionConfigurationBody}
                          change={props.change}
                          dataTreePath={`${actionName}.config`}
                        />
                      )}
                    </RequestParamsWrapper>
                  ),
                },
                {
                  key: "pagination",
                  title: "Pagination",
                  panelComponent: (
                    <Pagination
                      onTestClick={props.onRunClick}
                      paginationType={props.paginationType}
                    />
                  ),
                },
                {
                  key: "settings",
                  title: "Settings",
                  panelComponent: (
                    <SettingsWrapper>
                      <ActionSettings
                        actionSettingsConfig={apiActionSettingsConfig}
                      />
                    </SettingsWrapper>
                  ),
                },
              ]}
            />
          </TabbedViewContainer>

          <ApiResponseView />
        </SecondaryWrapper>
      </Form>
    </>
  );
};

const selector = formValueSelector(API_EDITOR_FORM_NAME);

export default connect((state: AppState) => {
  const httpMethodFromForm = selector(state, "actionConfiguration.httpMethod");
  const actionConfigurationBody = selector(state, "actionConfiguration.body");
  const actionConfigurationHeaders = selector(
    state,
    "actionConfiguration.headers",
  );
  const orgId = state.ui.orgs.currentOrg.id;
  const apiId = selector(state, "id");
  const actionName = getApiName(state, apiId) || "";
  return {
    actionName,
    apiId,
    httpMethodFromForm,
    orgId,
    actionConfigurationBody,
    actionConfigurationHeaders,
  };
})(
  reduxForm<RestAction, APIFormProps>({
    form: API_EDITOR_FORM_NAME,
  })(ApiEditorForm),
);
