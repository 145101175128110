import React, { useEffect, ReactNode, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Canvas from "./Canvas";
import {
  getIsFetchingPage,
  getCurrentPageId,
  getCanvasWidgetDsl,
  getCurrentPageName,
  getCurrentApplicationId,
  getCurrentApplicationLayoutSettings,
} from "selectors/editorSelectors";
import Centered from "components/designSystems/appsmith/CenteredWrapper";
import EditorContextProvider from "components/editorComponents/EditorContextProvider";
import { Spinner } from "@blueprintjs/core";
import {
  useWidgetSelection,
  useWindowSizeHooks,
} from "utils/hooks/dragResizeHooks";
import AnalyticsUtil from "utils/AnalyticsUtil";
import * as log from "loglevel";
import { getCanvasClassName } from "utils/generators";
import { flashElementById } from "utils/helpers";
import { useParams } from "react-router";
import { fetchPage } from "actions/pageActions";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";
import {
  getCurrentApplication,
  getCurrentApplicationSettingsData,
} from "selectors/applicationSelectors";
import image from "../../assets/images/phone.png";
import { theme } from "../../constants/DefaultTheme";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import { AppState } from "../../reducers";
import { getWidget } from "../../sagas/selectors";
import { debounce, maxBy } from "lodash";

const EditorWrapper: any = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  height: ${(props: any) =>
    props.mobile ? "632px" : `calc(100vh - ${props.theme.headerHeight})`};
  margin-top: ${(props: any) => (props.mobile ? "138px" : "0px")};
`;

const CanvasContainer = styled.section`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
`;

/* eslint-disable react/display-name */
const WidgetsEditor = () => {
  PerformanceTracker.startTracking(PerformanceTransactionName.EDITOR_MOUNT);
  const { focusWidget, selectWidget } = useWidgetSelection();
  const wrapperRef = useRef<any>();
  const params = useParams<{ applicationId: string; pageId: string }>();
  const dispatch = useDispatch();

  const widgets = useSelector(getCanvasWidgetDsl);
  const isFetchingPage = useSelector(getIsFetchingPage);
  const currentPageId = useSelector(getCurrentPageId);
  const currentApplicationId = useSelector(getCurrentApplicationId);
  const currentPageName = useSelector(getCurrentPageName);
  const currentApp = useSelector(getCurrentApplication);
  const currentAppSettingsData = useSelector(getCurrentApplicationSettingsData);

  const appLayoutSettings = useSelector(getCurrentApplicationLayoutSettings);
  const mainContainer = useSelector((state: AppState) => getWidget(state, "0"));

  const changeSizeCanvas = useCallback(() => {
    if (wrapperRef.current) {
      const { rightColumn } = mainContainer;
      let newWidthLayout;
      if (currentAppSettingsData.isMobile) {
        newWidthLayout = 438;
      } else {
        newWidthLayout = wrapperRef.current.offsetWidth - 40;
      }
      if (rightColumn !== newWidthLayout) {
        dispatch({
          type: ReduxActionTypes.UPDATE_CANVAS_LAYOUT,
          payload: {
            width: newWidthLayout,
          },
        });
      }
    }
  }, [wrapperRef.current, mainContainer.rightColumn]);
  changeSizeCanvas();
  useEffect(() => {
    changeSizeCanvas();
  }, [wrapperRef.current, mainContainer.rightColumn]);
  useEffect(() => {
    PerformanceTracker.stopTracking(PerformanceTransactionName.EDITOR_MOUNT);
    PerformanceTracker.stopTracking(PerformanceTransactionName.CLOSE_SIDE_PANE);
  });

  // Switch page
  useEffect(() => {
    if (currentPageId !== params.pageId && !!params.pageId) {
      dispatch(fetchPage(params.pageId, currentApplicationId));
    }
  }, [currentPageId, params.pageId, dispatch]);

  // log page load
  useEffect(() => {
    if (currentPageName !== undefined && currentPageId !== undefined) {
      AnalyticsUtil.logEvent("PAGE_LOAD", {
        pageName: currentPageName,
        pageId: currentPageId,
        appName: currentApp?.name,
        mode: "EDIT",
      });
    }
  }, [currentPageName, currentPageId]);

  // navigate to widget
  useEffect(() => {
    if (!isFetchingPage && window.location.hash.length > 0) {
      const widgetIdFromURLHash = window.location.hash.substr(1);
      flashElementById(widgetIdFromURLHash);
      if (document.getElementById(widgetIdFromURLHash))
        selectWidget(widgetIdFromURLHash);
    }
  }, [isFetchingPage, selectWidget]);

  const handleWrapperClick = useCallback(() => {
    focusWidget && focusWidget();
    selectWidget && selectWidget();
  }, [focusWidget, selectWidget]);

  const pageLoading = (
    <Centered>
      <Spinner />
    </Centered>
  );
  let node: ReactNode;
  if (isFetchingPage) {
    node = pageLoading;
  }
  if (!isFetchingPage && widgets) {
    // widgets.rightColumn = 1530;
    node = <Canvas dsl={widgets} />;
  }

  log.debug("Canvas rendered");
  return (
    <EditorContextProvider>
      <EditorWrapper
        mobile={currentAppSettingsData?.isMobile}
        onClick={handleWrapperClick}
        ref={wrapperRef}
        id="wrapperRefId"
      >
        {currentAppSettingsData?.isMobile && (
          <img
            src={image}
            style={{
              position: "absolute",
              margin: "auto",
              left: "50%",
              width: "630px",
              transform: "translate(-50%)",
              height: "880px",
              top: "-140px",
              paddingRight: "25px",
            }}
            alt=""
          />
        )}
        <CanvasContainer key={currentPageId} className={getCanvasClassName()}>
          {node}
        </CanvasContainer>
      </EditorWrapper>
    </EditorContextProvider>
  );
};

export default WidgetsEditor;
