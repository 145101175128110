import React from "react";
import { Icon, IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Colors } from "constants/Colors";
import { ReactComponent as Open } from "../../../../assets/images/work_space/open.svg";
import { ReactComponent as Close } from "../../../../assets/images/work_space/close.svg";

export const CollapseToggle = (props: {
  isOpen: boolean;
  isVisible: boolean;
  onClick: () => void;
  disabled: boolean;
  className: string;
}) => {
  const handleClick = (e: any) => {
    props.onClick();
    e.stopPropagation();
  };
  const icon = props.isOpen ? (
    <div onClick={handleClick}>
      <Open className={props.className}></Open>
    </div>
  ) : (
    <div onClick={handleClick}>
      <Close className={props.className}></Close>
    </div>
  );
  if (!props.isVisible) return <span></span>;
  return icon;
};
export default CollapseToggle;
