import React, { memo, useCallback } from "react";
import { Page, ReduxActionTypes } from "constants/ReduxActionConstants";
import Entity, { EntityClassNames } from "../Entity";
import { useParams } from "react-router";
import { ExplorerURLParams } from "../helpers";
import { BUILDER_PAGE_URL } from "constants/routes";
import history from "utils/history";
import { updatePage, WidgetAddChild } from "actions/pageActions";
import PageContextMenu from "./PageContextMenu";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "reducers";
import { DataTreeAction } from "entities/DataTree/dataTreeFactory";
import { homePageIcon, pageIcon } from "../ExplorerIcons";
import { getPluginGroups } from "../Actions/helpers";
import ExplorerWidgetGroup from "../Widgets/WidgetGroup";
import { resolveAsSpaceChar } from "utils/helpers";
import { CanvasStructure } from "reducers/uiReducers/pageCanvasStructure";
import { Datasource } from "api/DatasourcesApi";
import { Plugin } from "api/PluginApi";
import {
  getExistingActionNames,
  getExistingPageNames,
  getExistingWidgetNames,
} from "../../../../sagas/selectors";
import { generateReactKey } from "../../../../utils/generators";
import {
  MAIN_CONTAINER_WIDGET_ID,
  WidgetTypes,
} from "../../../../constants/WidgetConstants";

type ExplorerPageEntityProps = {
  page: Page;
  widgets?: CanvasStructure;
  transformWidget?: CanvasStructure;
  actions: any[];
  datasources: Datasource[];
  plugins: Plugin[];
  step: number;
  searchKeyword?: string;
  showWidgetsSidebar: (pageId: string) => void;
};
export const ExplorerPageEntity = memo(
  (props: ExplorerPageEntityProps & { glContainer: any; glEventHub: any }) => {
    const params = useParams<ExplorerURLParams>();

    const listTransformers = useSelector((state: AppState) => {
      return getTransformer(state);
    });
    const existingWidgetNames = useSelector((state: AppState) => {
      return getExistingWidgetNames(state)
        .concat(getExistingActionNames(state))
        .concat(getExistingPageNames(state));
    });
    const dispatch = useDispatch();

    const currentPageId = useSelector((state: AppState) => {
      return state.entities.pageList.currentPageId;
    });
    const isCurrentPage = currentPageId === props.page.pageId;

    const switchPage = useCallback(() => {
      if (!!params.applicationId) {
        history.push(BUILDER_PAGE_URL(params.applicationId, props.page.pageId));
      }
    }, [props.page.pageId, params.applicationId]);

    const contextMenu = (
      <PageContextMenu
        key={props.page.pageId}
        applicationId={params.applicationId}
        pageId={props.page.pageId}
        name={props.page.pageName}
        hidePage={props.page.hidePage}
        className={EntityClassNames.CONTEXT_MENU}
        isDefaultPage={props.page.isDefault}
      />
    );

    const icon = props.page.isDefault ? homePageIcon : pageIcon;

    const addWidgetsFn = useCallback(
      () => props.showWidgetsSidebar(props.page.pageId),
      [props.page.pageId],
    );
    const addTransformer = useCallback(() => {
      dispatch({
        type: ReduxActionTypes.WIDGET_ADD_CHILD,
        payload: createTransform(listTransformers.length, existingWidgetNames),
      });
    }, [props.page.pageId, existingWidgetNames, listTransformers.length]);

    return (
      <Entity
        icon={icon}
        name={props.page.pageName}
        className="page"
        step={props.step}
        action={switchPage}
        entityId={props.page.pageId}
        active={isCurrentPage}
        isDefaultExpanded={isCurrentPage || !!props.searchKeyword}
        updateEntityName={updatePage}
        contextMenu={contextMenu}
        onNameEdit={resolveAsSpaceChar}
      >
        <ExplorerWidgetGroup
          step={props.step + 1}
          searchKeyword={props.searchKeyword}
          widgets={props.widgets}
          pageId={props.page.pageId}
          addWidgetsFn={addWidgetsFn}
        />

        {getPluginGroups(
          props.page,
          props.step + 1,
          props.actions as DataTreeAction[],
          props.datasources,
          props.plugins,
          props.glContainer,
          props.glEventHub,
          props.searchKeyword,
        )}
        <ExplorerWidgetGroup
          step={props.step + 1}
          searchKeyword={props.searchKeyword}
          widgets={props.transformWidget}
          pageId={props.page.pageId}
          transform={true}
          addWidgetsFn={addTransformer}
        />
      </Entity>
    );
  },
);

ExplorerPageEntity.displayName = "ExplorerPageEntity";
(ExplorerPageEntity as any).whyDidYouRender = {
  logOnDifferentValues: false,
};

export default ExplorerPageEntity;

export function getTransformer(state: AppState) {
  const arr = [];
  for (const key in state.entities.canvasWidgets) {
    const item = state.entities.canvasWidgets[key];
    if (item.type == "TRANSFORM_WIDGET") {
      arr.push(item);
    }
  }
  return arr;
}
function createName(names: [string]) {
  let name = "transformer";
  for (let i = 0; i < 1000; i++) {
    const newName = "transformer" + i;
    if (names.indexOf(newName) === -1) {
      name = newName;
      break;
    }
  }
  return name;
}
function createTransform(length: any, names: any) {
  const transformWidgetId = generateReactKey();
  const props: WidgetAddChild = {
    widgetId: MAIN_CONTAINER_WIDGET_ID,
    widgetName: createName(names),
    type: WidgetTypes.TRANSFORM_WIDGET,
    newWidgetId: transformWidgetId,
    parentRowSpace: 1,
    parentColumnSpace: 1,
    leftColumn: 0,
    topRow: 0,
    columns: 0,
    rows: 0,
    tabId: "",
  };
  return props;
}
