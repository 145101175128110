export const CLICK_ON = () => "🙌 Click on ";
export const PRESS = () => "🎉 Press ";
export const OPEN_THE_DEBUGGER = () => " to open the debugger";
export const NO_LOGS = () => "No logs to show";
export const TROUBLESHOOT_ISSUE = () => "Troubleshoot issue";

export function createMessage(
  format: (...strArgs: any[]) => string,
  ...args: any[]
) {
  return format(...args);
}
