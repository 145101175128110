import React, { useEffect, useRef, useState } from "react";
import { DockLayout } from "rc-dock";
import {
  createIdConsole,
  createIdEditorPage,
  createIdNodeRed,
  createIdTransformer,
  createIdWrapperEditorPage,
  creatIdEditorPage,
  defaultLayoutUi,
  groups,
  ItemTransformer,
  NodeRed,
  replaceItemDockLayout,
  savedSettings,
  savedSettingsUi,
} from "./DoclLayoutSettings";
import { useSelector } from "react-redux";
import { AppState } from "../../reducers";
import ApplicationApi from "../../api/ApplicationApi";
import DebuggerTabs from "../../components/editorComponents/Debugger/DebuggerTabs";

export const LayoutUi = () => {
  const [contentOld, setContentOld] = useState<any>();
  const layoutRefUi = useRef<any>(null);
  const dockLayoutItem = useSelector((state: AppState) => {
    return state.layout.dockLayoutItem;
  });

  const currentApplicationID = useSelector((state: AppState) => {
    return state.ui.applications?.currentApplication?.id;
  });

  useEffect(() => {
    (async () => {
      const settings = await ApplicationApi.getUiLayoutApplication(
        currentApplicationID || "",
      );
      if (settings.data.layout_setting_ui) {
        setContentOld(replaceItemDockLayout(settings.data.layout_setting_ui));
      } else {
        setContentOld("default");
      }
    })();
  }, [currentApplicationID]);

  return (
    <div>
      {contentOld && (
        <DockLayout
          ref={layoutRefUi}
          // defaultLayout={defaultLayoutUi}
          defaultLayout={contentOld == "default" ? defaultLayoutUi : contentOld}
          groups={groups}
          onLayoutChange={e => {
            savedSettingsUi(layoutRefUi, currentApplicationID);
          }}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
          }}
        />
      )}
    </div>
  );
};
