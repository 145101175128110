import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Img } from "../../../assets/images/line.svg";
import { getFlowNodeRed } from "../../designSystems/appsmith/help/FileManagerApi";
import { IconName } from "../../ads/Icon";
import { Severity, SourceEntity } from "../../../entities/Console";
import ReactJson from "react-json-view";

const ContainerWrapper = styled.div`
  height: 100%;
  width: 100%;
  .red-ui-workspace-chart-grid line {
    fill: none;
    shape-rendering: crispEdges;
    stroke: #eee;
    stroke-width: 1px;
  }
  .red-ui-workspace-chart-background {
    fill: #fff;
  }
`;
const Close = styled.div`
  position: absolute;
  z-index: 101;
  right: 20px;
  font-size: 45px;
  line-height: 0.5;
  top: 20px;
  width: 33px;
  height: 33px;
  &&:hover {
    cursor: pointer;
  }
`;

const ListWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
`;

const WrapperData = styled.div`
  position: absolute;
  height: calc(100vh - 140px);
  z-index: 102;
  top: 50px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  background: #d2d0ce;
  padding: 20px;
  overflow: auto;
  max-width: 90%;
`;

const reactJsonProps = {
  name: null,
  enableClipboard: false,
  displayObjectSize: false,
  displayDataTypes: false,
  style: {
    fontSize: "13px",
  },
  collapsed: 1,
};

type Props = {
  executionData: any;
  close: any;
};

function ExecutionComponents(props: Props) {
  const [data, setData] = useState<any>();
  const [parameters, setParameters] = useState<any>();
  return (
    <ContainerWrapper>
      {data && (
        <WrapperData>
          <Close
            onClick={() => {
              setData(undefined);
            }}
          >
            x
          </Close>
          <div>
            <div>Parameters</div>
            <div>
              <ReactJson src={parameters} {...reactJsonProps} />
            </div>
          </div>
          <div>
            <div>Results JSON</div>
            <div>
              {" "}
              <ReactJson src={data} {...reactJsonProps} />
            </div>
          </div>
        </WrapperData>
      )}
      <Img />
      <Close
        onClick={() => {
          props.close(false);
        }}
      >
        x
      </Close>
      <ListWrapper>
        {props.executionData.flow.nodes.map((item: any) => {
          const count = getCountUse(
            item.id,
            props.executionData.execution.nodes,
          );
          return (
            <>
              <div
                key={item.id}
                id={item.id}
                onClick={() => {
                  const find = props.executionData.execution.nodes.find(
                    (items: any) => item.id === items.destination.id,
                  );
                  if (find) {
                    setParameters(item);
                    setData(find);
                  }
                }}
                style={{
                  position: "absolute",
                  top: `${item.y}px`,
                  left: `${item.x}px`,
                  background: "white",
                  borderRadius: "5px",
                  height: "30px",
                  display: "flex",
                  border: ` ${
                    count > 0 ? "1px solid #1ee01e" : "1px solid #999"
                  }`,
                  minWidth: "120px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    background: "rgb(166, 187, 207)",
                    borderRight: "1px solid rgba(0,0,0,0.1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>{count}</span>
                </div>
                <div
                  style={{
                    padding: "6px",
                    paddingRight: "20px",
                    background: "rgb(166, 187, 207 , 0.5)",
                    width: "100%",
                  }}
                >
                  {item.name ? item.name : item.type}
                </div>
                {item.wires[0] && item.wires[0]?.length != 0 ? (
                  <div
                    style={{
                      position: "absolute",
                      right: "-5px",
                      top: "50%",
                      background: "#d9d9d9",
                      border: "1px solid #999",
                      width: "10px",
                      height: "10px",
                      transform: "translateY(-50%)",
                      borderRadius: "2px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              {(item.wires as []).map((items: any, index: number) => {
                const lines = items.map((id: any) => {
                  const findItem = props.executionData.flow.nodes.find(
                    (item: any) => {
                      return item.id === id;
                    },
                  );

                  if (findItem) {
                    return (
                      <>
                        <svg
                          key={id}
                          width={5000}
                          style={{ position: "absolute", top: 0, zIndex: -1 }}
                          height={5000}
                        >
                          <path
                            style={{
                              stroke: "#999",
                              strokeWidth: "3",
                              fill: " none",
                              pointerEvents: "none",
                            }}
                            d={generateLinkPath(
                              item.x + calcWidth(item),
                              item.y + 14,
                              findItem?.x,
                              findItem?.y + 14,
                            )}
                          />
                        </svg>
                        <div
                          style={{
                            position: "absolute",
                            left: findItem?.x - 5,
                            top: findItem?.y + 14,
                            background: "#d9d9d9",
                            border: "1px solid #999",
                            width: "10px",
                            height: "10px",
                            transform: "translateY(-50%)",
                            borderRadius: "2px",
                            zIndex: 55,
                          }}
                        ></div>
                      </>
                    );
                  } else {
                    return "";
                  }
                });
                return lines ? lines : "";
              })}
            </>
          );
        })}
      </ListWrapper>
    </ContainerWrapper>
  );
}

export default ExecutionComponents;

function calcWidth(item: any) {
  let width = 120;
  if (item.name) {
    const widthName = item.name.length * 7.5;
    if (widthName > 60) {
      width = widthName + 60;
    }
  } else {
    const widthName = item.type.length * 7.5;
    if (widthName > 60) {
      width = widthName + 60;
    }
  }

  return 120;
}

function generateLinkPath(
  origX: number,
  origY: number,
  destX: number,
  destY: number,
  sc = 1,
) {
  const node_width = 120;
  const node_height = 30;
  const dy = destY - origY;
  const dx = destX - origX;
  const delta = Math.sqrt(dy * dy + dx * dx);
  let scale = 0.75;
  const scaleY = 0;
  if (dx * sc > 0) {
    if (delta < node_width) {
      scale = 0.75 - 0.75 * ((node_width - delta) / node_width);
      // scale += 2*(Math.min(5*node_width,Math.abs(dx))/(5*node_width));
      // if (Math.abs(dy) < 3*node_height) {
      //     scaleY = ((dy>0)?0.5:-0.5)*(((3*node_height)-Math.abs(dy))/(3*node_height))*(Math.min(node_width,Math.abs(dx))/(node_width)) ;
      // }
    }
  } else {
    scale =
      0.4 -
      0.2 *
        Math.max(
          0,
          (node_width - Math.min(Math.abs(dx), Math.abs(dy))) / node_width,
        );
  }
  if (dx * sc > 0) {
    return (
      "M " +
      origX +
      " " +
      origY +
      " C " +
      (origX + sc * (node_width * scale)) +
      " " +
      (origY + scaleY * node_height) +
      " " +
      (destX - sc * scale * node_width) +
      " " +
      (destY - scaleY * node_height) +
      " " +
      destX +
      " " +
      destY
    );
  } else {
    const midX = Math.floor(destX - dx / 2);
    let midY = Math.floor(destY - dy / 2);
    //
    if (dy === 0) {
      midY = destY + node_height;
    }
    const cp_height = node_height / 2;
    const y1 = (destY + midY) / 2;
    const topX = origX + sc * node_width * scale;
    const topY =
      dy > 0
        ? Math.min(y1 - dy / 2, origY + cp_height)
        : Math.max(y1 - dy / 2, origY - cp_height);
    const bottomX = destX - sc * node_width * scale;
    const bottomY =
      dy > 0
        ? Math.max(y1, destY - cp_height)
        : Math.min(y1, destY + cp_height);
    const x1 = (origX + topX) / 2;
    const scy = dy > 0 ? 1 : -1;
    const cp = [
      // Orig -> Top
      [x1, origY],
      [
        topX,
        dy > 0
          ? Math.max(origY, topY - cp_height)
          : Math.min(origY, topY + cp_height),
      ],
      // Top -> Mid
      // [Mirror previous cp]
      [
        x1,
        dy > 0
          ? Math.min(midY, topY + cp_height)
          : Math.max(midY, topY - cp_height),
      ],
      // Mid -> Bottom
      // [Mirror previous cp]
      [
        bottomX,
        dy > 0
          ? Math.max(midY, bottomY - cp_height)
          : Math.min(midY, bottomY + cp_height),
      ],
      // Bottom -> Dest
      // [Mirror previous cp]
      [(destX + bottomX) / 2, destY],
    ];
    if (cp[2][1] === topY + scy * cp_height) {
      if (Math.abs(dy) < cp_height * 10) {
        cp[1][1] = topY - (scy * cp_height) / 2;
        cp[3][1] = bottomY - (scy * cp_height) / 2;
      }
      cp[2][0] = topX;
    }
    return (
      "M " +
      origX +
      " " +
      origY +
      " C " +
      cp[0][0] +
      " " +
      cp[0][1] +
      " " +
      cp[1][0] +
      " " +
      cp[1][1] +
      " " +
      topX +
      " " +
      topY +
      " S " +
      cp[2][0] +
      " " +
      cp[2][1] +
      " " +
      midX +
      " " +
      midY +
      " S " +
      cp[3][0] +
      " " +
      cp[3][1] +
      " " +
      bottomX +
      " " +
      bottomY +
      " S " +
      cp[4][0] +
      " " +
      cp[4][1] +
      " " +
      destX +
      " " +
      destY
    );
  }
}

function getCountUse(id: string, nodes: any[]) {
  let count = 0;
  for (const item of nodes) {
    if (item.destination.id === id) {
      count++;
    }
  }
  return count;
}
