import React, { memo, useCallback } from "react";
import Entity from "../Entity";
import { pageGroupIcon } from "../ExplorerIcons";
import { noop } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getNextEntityName } from "utils/AppsmithUtils";
import { createPage } from "actions/pageActions";
import { useParams } from "react-router";
import { ExplorerURLParams } from "../helpers";
import { Page } from "constants/ReduxActionConstants";
import ExplorerPageEntity from "./PageEntity";
import { AppState } from "reducers";
import { CanvasStructure } from "reducers/uiReducers/pageCanvasStructure";
import { Datasource } from "api/DatasourcesApi";
import { Plugin } from "api/PluginApi";

type ExplorerPageGroupProps = {
  searchKeyword?: string;
  step: number;
  widgets?: Record<string, CanvasStructure>;
  actions: Record<string, any[]>;
  datasources: Record<string, Datasource[]>;
  plugins: Plugin[];
  showWidgetsSidebar: (pageId: string) => void;
};

const pageGroupEqualityCheck = (
  prev: ExplorerPageGroupProps,
  next: ExplorerPageGroupProps,
) => {
  return (
    prev.widgets === next.widgets &&
    prev.actions === next.actions &&
    prev.searchKeyword === next.searchKeyword
  );
};

export const ExplorerPageGroup = memo(
  (props: ExplorerPageGroupProps & { glContainer: any; glEventHub: any }) => {
    const dispatch = useDispatch();
    const params = useParams<ExplorerURLParams>();

    const pages = useSelector((state: AppState) => {
      return state.entities.pageList.pages;
    });

    const createPageCallback = useCallback(() => {
      const name = getNextEntityName(
        "Page",
        pages.map((page: Page) => page.pageName),
      );
      dispatch(createPage(params.applicationId, name));
    }, [dispatch, pages, params.applicationId]);

    const pageEntities = pages.map(page => {
      const pageWidgets = props.widgets && props.widgets[page.pageId];
      const newPagesWidget: any = { ...pageWidgets };
      const transformWidget: any = { ...pageWidgets };
      if (newPagesWidget && newPagesWidget.children) {
        newPagesWidget.children = newPagesWidget.children.filter(
          (e: any) =>
            e.type !== "TRANSFORM_WIDGET" && e.type !== "REPEAT_MODAL_WIDGET",
        );
        transformWidget.children = transformWidget.children.filter(
          (e: any) => e.type === "TRANSFORM_WIDGET",
        );
      }
      const pageActions = props.actions[page.pageId] || [];
      const datasources = props.datasources[page.pageId] || [];
      if (
        !newPagesWidget &&
        pageActions.length === 0 &&
        datasources.length === 0
      )
        return null;
      return (
        <ExplorerPageEntity
          glContainer={props.glContainer}
          glEventHub={props.glEventHub}
          key={page.pageId}
          step={props.step + 1}
          widgets={newPagesWidget}
          transformWidget={transformWidget}
          actions={pageActions}
          datasources={datasources}
          plugins={props.plugins}
          searchKeyword={props.searchKeyword}
          page={page}
          showWidgetsSidebar={props.showWidgetsSidebar}
        />
      );
    });

    if (pageEntities.filter(Boolean).length === 0) return null;

    return (
      <Entity
        name="Pages"
        className="group pages"
        icon={pageGroupIcon}
        isDefaultExpanded
        action={noop}
        entityId="Pages"
        step={props.step}
        onCreate={createPageCallback}
        searchKeyword={props.searchKeyword}
      >
        {pageEntities}
      </Entity>
    );
  },
  pageGroupEqualityCheck,
);

ExplorerPageGroup.displayName = "ExplorerPageGroup";

(ExplorerPageGroup as any).whyDidYouRender = {
  logOnDifferentValues: false,
};

export default ExplorerPageGroup;
