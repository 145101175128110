import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WidgetCard from "./WidgetCard";
import styled from "styled-components";
import { WidgetCardProps } from "widgets/BaseWidget";
import { getWidgetCards } from "selectors/editorSelectors";
import { getColorWithOpacity } from "constants/DefaultTheme";
import { IPanelProps, Icon, Classes } from "@blueprintjs/core";
import { Colors } from "constants/Colors";
import ExplorerSearch from "./Explorer/ExplorerSearch";
import { debounce } from "lodash";
import produce from "immer";
import { WIDGET_SIDEBAR_CAPTION } from "constants/messages";
import {
  deleteUiTemplate,
  pasteCustomWidget,
} from "../../actions/widgetActions";
import { AppState } from "../../reducers";
import { ReactComponent as GoBack } from "../../assets/images/work_space/goBack.svg";

const MainWrapper = styled.div`
  text-transform: capitalize;
  padding: 0 10px 20px 10px;
  height: 100%;
  overflow-y: auto;

  scrollbar-color: #6c767e;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px
      ${props => getColorWithOpacity(props.theme.colors.paneBG, 0.3)};
  }
  .header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #1e272e;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    outline: 1px solid ${props => props.theme.paneText};
    border-radius: ${props => props.theme.radii[1]}px;
  }
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${props => props.theme.spaces[1]}px;
  justify-items: stretch;
  align-items: stretch;
`;

const CloseIcon = styled(Icon)`
  &&.${Classes.ICON} {
    cursor: pointer;
    display: flex;
    justify-content: center;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
`;

const Header = styled.div`
  // display: grid;
  // grid-template-columns: 7fr 1fr;
  .go_back {
    display: flex;
    margin: 14px 0px 10px 0px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2px;
    cursor: pointer;
    span {
      margin-left: 4px;
      font-size: 12px;
      line-height: 12px;
      font-family: "Roboto";
      color: #3b82ff;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-around;
  text-transform: none;
  background: #ececec;
  border-radius: 4px;
  color: #6c767e;
  font-size: 12px;
  line-height: 20px;
  font-family: "Roboto";
  padding: 4px;
  h4 {
    margin-top: 0px;
  }
  p {
    opacity: 0.6;
  }
`;

const WidgetSidebar = (props: IPanelProps) => {
  const cards = useSelector(getWidgetCards);
  const dispatch = useDispatch();
  const [filteredCards, setFilteredCards] = useState(cards);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const filterCards = (keyword: string) => {
    let filteredCards = cards;
    if (keyword.trim().length > 0) {
      filteredCards = produce(cards, draft => {
        for (const [key, value] of Object.entries(cards)) {
          value.forEach((card, index) => {
            if (card.widgetCardName.toLowerCase().indexOf(keyword) === -1) {
              delete draft[key][index];
            }
          });
          draft[key] = draft[key].filter(Boolean);
          if (draft[key].length === 0) {
            delete draft[key];
          }
        }
      });
    }
    setFilteredCards(filteredCards);
  };
  const clearSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    filterCards("");
  };

  const search = debounce((e: any) => {
    filterCards(e.target.value.toLowerCase());
  }, 300);
  useEffect(() => {
    const el: HTMLInputElement | null = searchInputRef.current;

    el?.addEventListener("keydown", search);
    el?.addEventListener("cleared", search);
    return () => {
      el?.removeEventListener("keydown", search);
      el?.removeEventListener("cleared", search);
    };
  }, [searchInputRef, search]);
  const groups = Object.keys(filteredCards);
  const listTemplate = useSelector(
    (state: AppState) => state.ui.templateWidgets.customTemplates,
  );
  const customWidgets = [];
  if (listTemplate) {
    for (const item of listTemplate) {
      const findWidget = item.value.list.find((widget: any) => {
        return widget.widgetId === item.value.widgetId;
      });
      if (findWidget) {
        const columns = findWidget.rightColumn - findWidget.leftColumn;
        const rows = findWidget.bottomRow - findWidget.topRow;
        customWidgets.push({
          widget: item,
          card: {
            columns: columns,
            key: "CreateFromTemplate",
            rows: rows,
            type: "TEMPLATE_WIDGET",
            widgetCardName: item.name,
            _id: item._id,
          },
        });
      }
    }
  }

  return (
    <>
      <ExplorerSearch
        ref={searchInputRef}
        clear={clearSearchInput}
        placeholder="Search widgets..."
      />

      <MainWrapper>
        <Header>
          <div className="go_back" onClick={props.closePanel}>
            <GoBack /> <span>Go back</span>
          </div>

          <Info>
            <p>{WIDGET_SIDEBAR_CAPTION}</p>
          </Info>
        </Header>
        {groups.map((group: string) => (
          <React.Fragment key={group}>
            <h5 className="header">{group}</h5>
            <CardsWrapper>
              {filteredCards[group].map((card: WidgetCardProps) => (
                <WidgetCard details={card} key={card.key} />
              ))}
            </CardsWrapper>
          </React.Fragment>
        ))}
        <React.Fragment>
          <h5 className="header">{"Custom Template"}</h5>
          <CardsWrapper>
            {customWidgets.map((item: any, index: number) => (
              <div key={index}>
                <WidgetCard
                  details={item.card}
                  deleteIcon={(id: string) => {
                    dispatch(deleteUiTemplate(id));
                  }}
                />
              </div>
            ))}
          </CardsWrapper>
        </React.Fragment>
      </MainWrapper>
    </>
  );
};

WidgetSidebar.displayName = "WidgetSidebar";

export default WidgetSidebar;
