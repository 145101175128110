import React, { useEffect, useRef } from "react";
import { Tour, TourProps } from "antd";

const HowToCloneTemplateTour = (props: IHowToCloneTemplateTour) => {
  const refAssetMarketplaceMenuItem = useRef<any>(null);

  // const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    const assetMarketplaceDOM = document.querySelector(
      ".asset-marketplace-tour-step",
    );

    refAssetMarketplaceMenuItem.current = assetMarketplaceDOM;
  }, []);

  const steps: TourProps["steps"] = [
    {
      placement: "rightBottom",
      title: "Asset Marketplace",
      description: `Asset Marketplace is a repository of reusable, templates, app
        blueprints, connectors, UI components, tools and business solutions
        to help you swiftly kickstart your product or solution on the UBOS
        platform`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/04/template-gif.gif"}
          alt="How to clone template"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: () => props.handleFinishTour(),
      target: () => refAssetMarketplaceMenuItem.current,
    },
  ];
  return (
    <Tour open={true} steps={steps} onFinish={() => props.handleFinishTour()} />
  );
};

interface IHowToCloneTemplateTour {
  handleFinishTour: () => void;
}

export default HowToCloneTemplateTour;
