import React from "react";
import "./wdyr";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import "./template.css";
import "./widgetCustom.css";

import { ThemeProvider } from "constants/DefaultTheme";
import { appInitializer } from "utils/AppsmithUtils";
import { Slide, ToastContainer } from "react-toastify";
import store from "./store";
import { LayersContext, Layers } from "constants/Layers";
import AppRouter from "./AppRouter";
import * as Sentry from "@sentry/react";
import { getThemeDetails } from "selectors/themeSelectors";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { AppDataState } from "reducers/entityReducers/appReducer";
import { setThemeMode } from "actions/themeActions";
import { ThemeMode } from "reducers/uiReducers/themeReducer";
import AppErrorBoundary from "./AppErrorBoundry";
import { getConfigsFromEnvVars } from "./configs";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { getOrgs } from "selectors/organizationSelectors";
import { getCurrentUser } from "selectors/usersSelectors";
import { QUERY_KEY_TEMPLATE_ID } from "constants/routes";
import { User } from "constants/userConstants";
appInitializer();
import ChatwootWidget from "./chatwoot";

const ENV_CONFIG = getConfigsFromEnvVars();
export const IS_DEPLOY = ENV_CONFIG.isDeploy;
// export const IS_DEPLOY = false;

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
      <Provider store={store}>
        <LayersContext.Provider value={Layers}>
          <ThemedAppWithProps />
        </LayersContext.Provider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

class ThemedApp extends React.Component<{
  currentTheme: any;
  orgs?: any;
  setTheme: (themeMode: ThemeMode) => void;
  fetchOrgs: () => void;
  user: User;
}> {
  componentDidMount() {
    // console.log(this.props, "init");
    const queryParams = new URLSearchParams(window.location.search);
    const templateId = queryParams.get(QUERY_KEY_TEMPLATE_ID);
    const aiBotV3 = queryParams.get("aiBotV3");
    // console.log(aiBotV3, "aiBotV3aiBotV3aiBotV3aiBotV3");

    if (templateId) {
      document.cookie = `${QUERY_KEY_TEMPLATE_ID}=${templateId}`;
    }
    if (aiBotV3 == "true") {
      document.cookie = `aiBotV3=true;path=/`;
    }
    this.props.fetchOrgs();

    if (localStorage.getItem("THEME") === "LIGHT") {
      this.props.setTheme(ThemeMode.LIGHT);
    }
  }

  // componentDidUpdate() {
  //   const { user } = this.props;

  //   if (user && window?.Intercom) {
  //     window.Intercom("boot", {
  //       app_id: "trrrx6g3",
  //       name: user.username,
  //       email: user.email,
  //     });
  //   }
  //   // if (user && window?.$chatwoot && window.$chatwoot.hasOwnProperty("setUser")) {
  //   //   console.log(window?.$chatwoot, "window?.$chatwoot")
  //   //   window.$chatwoot.on('widget:ready', function () {
  //   //   window.$chatwoot.setUser(user.email);
  //   //   });
  //   // }

  // }

  render() {
    return (
      <ThemeProvider theme={this.props.currentTheme}>
        <ChatwootWidget />
        <ToastContainer
          hideProgressBar
          draggable={false}
          transition={Slide}
          autoClose={5000}
          closeButton={false}
        />
        <AppErrorBoundary>
          <AppRouter />
        </AppErrorBoundary>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  currentTheme: getThemeDetails(state).theme,
  orgs: getOrgs(state),
  user: getCurrentUser(state),
});
const mapDispatchToProps = (dispatch: any) => ({
  setTheme: (mode: ThemeMode) => {
    dispatch(setThemeMode(mode));
  },
  fetchOrgs: () => {
    dispatch({
      type: ReduxActionTypes.GET_ALL_APPLICATION_INIT,
    });
  },
});

const ThemedAppWithProps = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemedApp);

ReactDOM.render(<App />, document.getElementById("root"));
