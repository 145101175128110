import React, { useState, useCallback, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, MenuProps } from "antd";
import { ToastType } from "react-toastify";
import { Collapse } from "@blueprintjs/core";
import Api from "api/Api";
import { AppToaster } from "components/editorComponents/ToastComponent";
import { ModalDelete } from "components/designSystems/component/ModalDelete";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { WrapperCollapse, WrapperItemCollapse, Title } from "./SidebarStyled";
import Service from "./Service";
import { FolderCreator, FolderEdit, handleOpenRequest } from "./FolderCreator";
import { AppState } from "../../../reducers";
import { openCreateServicePage } from "../../../utils/openLayoutItem";
import { ReactComponent as OpenFolderIcon } from "../../../assets/images/work_space/open-folder-icon.svg";
import { ReactComponent as CloseFolderIcon } from "../../../assets/images/work_space/close-folder-icon.svg";
import { ReactComponent as CloseArrow } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as OpenArrow } from "../../../assets/images/work_space/open.svg";
import { getCurrentUser } from "selectors/usersSelectors";

interface IFolder {
  datasources: any;
  folderId?: string;
  folderName?: string;
  isOpen?: boolean;
  actionPermission?: string;
  parentId?: string;
  children?: any;
  isFolder?: boolean;
}

interface IFolderProps {
  folder: IFolder;
  setDbItem: any;
  showModalRef: any;
}

const Folder = memo((props: IFolderProps) => {
  const { folder, setDbItem, showModalRef } = props;
  const dispatch = useDispatch();

  const organizationId = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );

  const user = useSelector((state: any) => getCurrentUser(state));
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );

  const refModalsDelete = useRef<any>({});

  const [isOpenFolder, setIsOpenFolder] = useState(folder.isOpen);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isEditingFolder, setIsEditingFolder] = useState(false);
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState("");

  const toggleCollapse = useCallback(() => {
    const open = !isOpenFolder;
    setIsOpenFolder(open);
    handleOpenRequest(open, folder.folderId, organizationId, dispatch);
  }, [isOpenFolder, folder.folderId, organizationId, dispatch]);

  const removeFolderRequest = async (body: any) => {
    try {
      const response = await Api.put("v2/organizations/removeFolder", body);
      if (response.data.error) {
        AppToaster.show({
          message: response.data.message
            ? response.data.message
            : "Something went wrong",
          type: ToastType.WARNING,
        });
      } else {
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
        openModalDelete();
      }
    } catch (error) {
      AppToaster.show({
        message: "Something went wrong. Try again",
        type: ToastType.WARNING,
      });
      console.error("removeFolderRequest error: ", error);
    }
  };

  const openModalDelete = useCallback(() => {
    if (refModalsDelete) {
      refModalsDelete.current.openModal();
    }
  }, []);

  const contextMenuConfig = [
    {
      label: "New Service",
      onClick: () => {
        openCreateServicePage(dispatch, {
          queryParams: {
            org: currentOrg,
            orgId: currentOrg,
            app: currentApplication?.id,
            folderId: currentFolderId,
            userId: user?.id,
            userEmail: user?.email,
          },
        });
        setIsOpenContextMenu(false);
      },
    },
    {
      label: "New Folder",
      onClick: () => {
        setIsCreatingFolder(true);
        setIsOpenFolder(true);
        setIsOpenContextMenu(false);
      },
    },
    {
      type: "divider",
    },
    {
      label: "Rename",
      onClick: () => {
        setIsEditingFolder(true), setIsOpenContextMenu(false);
      },
    },
    {
      label: "Delete",
      onClick: () => {
        openModalDelete(), setIsOpenContextMenu(false);
      },
    },
  ];
  const contextMenu: MenuProps["items"] = contextMenuConfig.map((el, idx) => ({
    ...el,
    key: idx,
    disabled: folder.actionPermission === "read:services",
    className: "sidebar-service-item",
    title:
      folder.actionPermission === "read:services" ? "No permission" : el.label,
  }));

  const contextMenuClassName =
    folder.folderId === currentFolderId && isOpenContextMenu
      ? "open-context-menu"
      : "";

  return (
    <WrapperItemCollapse>
      {folder.folderName ? (
        <>
          <Dropdown
            menu={{ items: contextMenu }}
            trigger={["contextMenu"]}
            key={folder.folderId}
            placement="bottomLeft"
            onOpenChange={open => {
              setIsOpenContextMenu(open);

              if (open) {
                setCurrentFolderId(folder.folderId || "");
              } else setCurrentFolderId("");
            }}
          >
            <WrapperCollapse
              onClick={toggleCollapse}
              className={`${isOpenFolder && "active"}`}
              style={{ paddingLeft: "8px" }}
            >
              {isEditingFolder && folder.folderId ? (
                <FolderEdit
                  folderName={folder.folderName}
                  folderId={folder.folderId}
                  isOpenFolder={isOpenFolder || false}
                  isEditingFolder={isEditingFolder}
                  setIsEditingFolder={setIsEditingFolder}
                />
              ) : (
                <Title className={contextMenuClassName}>
                  <div>{isOpenFolder ? <OpenArrow /> : <CloseArrow />}</div>
                  <div>
                    {isOpenFolder ? <OpenFolderIcon /> : <CloseFolderIcon />}
                  </div>
                  <span title={folder.folderName}>{folder.folderName}</span>
                </Title>
              )}
            </WrapperCollapse>
          </Dropdown>
          {folder.isFolder ? (
            <div style={{ paddingLeft: "8px" }}>
              <Collapse isOpen={isOpenFolder}>
                {folder?.children && folder?.children.length > 0 && (
                  <>
                    {folder.children.map((childFolder: any) => {
                      return (
                        <Folder
                          folder={childFolder}
                          key={childFolder.folderId || childFolder.id}
                          setDbItem={setDbItem}
                          showModalRef={showModalRef}
                        />
                      );
                    })}
                  </>
                )}
                {isCreatingFolder && (
                  <FolderCreator
                    setIsCreatingFolder={setIsCreatingFolder}
                    isCreatingFolder={isCreatingFolder}
                    parentId={folder.folderId}
                  />
                )}
                <Service
                  items={folder.datasources}
                  setDbItem={setDbItem}
                  showModalRef={showModalRef}
                />
              </Collapse>
            </div>
          ) : (
            <>
              {isCreatingFolder && (
                <div style={{ paddingLeft: "8px" }}>
                  <Collapse isOpen={true}>
                    <FolderCreator
                      setIsCreatingFolder={setIsCreatingFolder}
                      isCreatingFolder={isCreatingFolder}
                      parentId={folder.folderId}
                    />
                  </Collapse>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Service
            items={folder.datasources}
            setDbItem={setDbItem}
            showModalRef={showModalRef}
          />
        </>
      )}
      <ModalDelete
        ref={element => (refModalsDelete.current = element)}
        name={`Folder  ${folder.folderName}`}
        item={{ folderId: folder.folderId, organizationId }}
        remove={removeFolderRequest}
      />
    </WrapperItemCollapse>
  );
});

export default Folder;
