import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { SubmissionError } from "redux-form";
export type InviteUsersToOrgByRoleValues = {
  id: string;
  users?: string;
  role?: string;
};
export type InviteUsersToOrgFormValues = {
  usersByRole: InviteUsersToOrgByRoleValues[];
};

export type CreateOrganizationFormValues = {
  name: string;
  provider: string;
  region: string;
  resource: string;
};

export const createOrganizationSubmitHandler = (
  values: CreateOrganizationFormValues,
  dispatch: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.CREATE_ORGANIZATION_INIT,
      payload: {
        resolve,
        reject,
        name: values.name,
        provider: values.provider,
        region: values.region,
        resource: values.resource,
      },
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const finishUserTourHandler = (
  values: any,
  dispatch: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.FINISH_USER_TOUR,
      payload: values,
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const saveAdditionalInfoFromDatingModalHandler = (
  values: any,
  dispatch: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.SAVE_ADDITIONAL_INFO_FROM_DATING_MODAL,
      payload: { ...values },
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const addCreditHandler = (values: any, dispatch: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.ADD_CREDIT,
      payload: values,
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const addCreditCardHandler = (
  values: any,
  dispatch: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.ADD_CREDIT_CARD,
      payload: values,
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const getUserBalance = (values: any, dispatch: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.GET_USER_BALANCE,
      payload: {
        id: values.id,
      },
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const getUserCurrentUserForTour = (
  values: any,
  dispatch: any,
): Promise<any> => {
  return new Promise(() => {
    dispatch({
      type: ReduxActionTypes.FETCH_USER_INIT,
      payload: {},
    });
  }).catch(error => {
    console.log("error", "asdad", error);
    // throw new SubmissionError(error);
  });
};

export const installPromotionTemplateHandler = (
  values: any,
  dispatch: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.INSTALL_PROMOTION_TEMPLATE,
      payload: {
        resolve,
        reject,
        data: values,
      },
    });
  }).catch(error => {
    throw new SubmissionError(error);
  });
  // return new Promise((resolve, reject) => {
  //   dispatch({
  //     type: ReduxActionTypes.INSTALL_PROMOTION_TEMPLATE,
  //     payload: {
  //       id: values.id,
  //     },
  //   });
  // }).catch(error => {
  //   console.log("error", "asdad", error);
  //   // throw new SubmissionError(error);
  // });
};

export const inviteUsersToOrgSubmitHandler = (
  values: InviteUsersToOrgFormValues,
  dispatch: any,
): Promise<any> => {
  const data = values.usersByRole.map(value => ({
    roleId: value.role,
    emails: value.users ? value.users.split(",") : [],
  }));
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.INVITE_USERS_TO_ORG_INIT,
      payload: {
        resolve,
        reject,
        data,
      },
    });
  }).catch(error => {
    throw new SubmissionError(error);
  });
};

export const inviteUsersToOrg = (values: any, dispatch: any): Promise<any> => {
  const data = {
    roleName: values.role,
    usernames: values.users ? values.users.split(",") : [],
    orgId: values.orgId,
  };
  return new Promise((resolve, reject) => {
    dispatch({
      type: ReduxActionTypes.INVITE_USERS_TO_ORG_INIT,
      payload: {
        resolve,
        reject,
        data,
      },
    });
  }).catch(error => {
    throw new SubmissionError(error);
  });
};
