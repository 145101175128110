import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { IconName, IconNames } from "@blueprintjs/icons";
import { Button, Collapse, Icon, Overlay, Tooltip } from "@blueprintjs/core";
import { Help, ListItem, Name, Version } from "./JSDependencies";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../reducers";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";
import { IframeModal } from "./PluginGroup/IframeModal";
import { ReactComponent as CopyIcon } from "../../../assets/icons/control/copy.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/control/delete.svg";
import { ReactComponent as Add } from "../../../assets/images/work_space/add.svg";
import { AppToaster } from "../../../components/editorComponents/ToastComponent";
import {
  AddNodeRed,
  Info,
  NodeRedCollapse,
  WrapperCollapse,
  WrapperItemCollapse,
} from "./NodeRedCollapse";
import { ReactComponent as Close } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as Open } from "../../../assets/images/work_space/open.svg";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";
import { ReactComponent as FileCreateIcon } from "../../../assets/images/work_space/file-create-icon.svg";
import { CopyServiceIcon, DeleteServiceIcon } from "../Explorer/ActionButtons";
import { ActionWrapp } from "../Sidebar/SidebarStyled";

export const Wrapper = styled.div`
  &:hover {
    .service-actions-wrapper {
      display: flex;
    }
  }
`;

const IconWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddSericeStyled = styled.div`
  display: none;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;

  svg {
    width: 15px;
    height: 15px;
  }
`;

const DeleteIconWrapp = styled.div`
  svg {
    path {
      fill: #ff6464;
    }
  }
`;

export const IframeCollapse = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isErrorModal, setIsErrorModal] = useState(false);
  const openModal = () => {
    childRef?.current?.openModal();
  };

  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
  }));

  const dispatch = useDispatch();
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );

  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const icon = isOpen ? <Open></Open> : <Close></Close>;

  const openIframe = useCallback((item: any) => {
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: "nodeRedId",
        title: item.title,
        component: "nodeRed",
        props: item.props,
      },
    });
  }, []);

  const deleteIframe = useCallback(
    async (item: any) => {
      try {
        const newIframe = currentApplication?.global_setting?.iframe.filter(
          (iframe: any) => {
            return item.title !== iframe.title;
          },
        );
        dispatch({
          type: ReduxActionTypes.UPDATE_GLOBAL_APPLICATION_SETTINGS,
          payload: {
            id: currentApplication?.id,
            name: "iframe",
            value: newIframe,
          },
        });
        const deleteId = JSON.stringify({
          id: item.title,
          type: "nodeRed",
          props: item.props,
        });
        dispatch({
          type: ReduxActionTypes.REMOVE_LAYOUT_ITEM,
          payload: {
            id: deleteId,
            title: item.title,
            component: "nodeRed",
            props: item.props,
          },
        });
      } catch (e) {}
    },
    [currentApplication],
  );
  const childRef = useRef<{ openModal: any; setValue: any }>();
  const refModalsDelete = useRef<any>({});
  const openModalDelete = useCallback(index => {
    if (refModalsDelete) {
      refModalsDelete.current[index].openModal();
    }
  }, []);
  const handleCollapse = useCallback(async () => {
    setIsOpen(prevState => !prevState);
  }, []);
  return (
    <Wrapper>
      <WrapperItemCollapse>
        <WrapperCollapse style={{ background: "#dddddd" }}>
          <div
            onClick={handleCollapse}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="icon_open_close">{icon}</div>
            <span style={{ paddingLeft: "4px" }}>External app</span>
          </div>

          {/* <Tooltip content={"New external app"}>
            <AddSericeStyled
              onClick={openModal}
              className="service-actions-wrapper"
            >
              <FileCreateIcon />
            </AddSericeStyled>
          </Tooltip> */}
          <ActionWrapp
            className="service-actions-wrapper"
            style={{ textTransform: "initial" }}
          >
            <Tooltip content={"New External App"} usePortal={false}>
              <div>
                <FileCreateIcon onClick={() => openModal()} />
              </div>
            </Tooltip>
          </ActionWrapp>
        </WrapperCollapse>
        <Collapse isOpen={isOpen}>
          {currentApplication?.global_setting?.iframe?.map(
            (item: any, index: number) => {
              return (
                <ListItem key={item.title}>
                  <Name onClick={() => openIframe(item)}>
                    <span className="dots">{item.title}</span>
                  </Name>
                  <Info className="info_collapse">
                    {/* <DeleteIconWrapp>
                      <DeleteIcon
                        width={"20px"}
                        onClick={() => {
                          openModalDelete(index);
                        }}
                      ></DeleteIcon>
                    </DeleteIconWrapp> */}
                    <Tooltip content={"Delete service"}>
                      <IconWrapp onClick={() => openModalDelete(index)}>
                        <DeleteServiceIcon />
                      </IconWrapp>
                    </Tooltip>
                    <ModalDelete
                      ref={element =>
                        (refModalsDelete.current[index] = element)
                      }
                      name={`Iframe  ${item.title}`}
                      item={item}
                      remove={deleteIframe}
                    />
                    <Tooltip content={"Link to the service"}>
                      <IconWrapp
                        onClick={() => {
                          navigator &&
                            navigator.clipboard.writeText(item.props);
                          AppToaster.show({
                            type: "success",
                            message: "Copied Url",
                          });
                        }}
                      >
                        <CopyServiceIcon />
                      </IconWrapp>
                    </Tooltip>
                    {/* <CopyIcon
                      width={"20px"}
                      onClick={() => {
                        navigator && navigator.clipboard.writeText(item.props);
                        AppToaster.show({
                          type: "success",
                          message: "Copied Url",
                        });
                      }}
                    ></CopyIcon> */}
                  </Info>
                </ListItem>
              );
            },
          )}
        </Collapse>
        <IframeModal ref={childRef} />
      </WrapperItemCollapse>
    </Wrapper>
  );
});
IframeCollapse.displayName = "IframeCollapse";
