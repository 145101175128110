import React, { useEffect, useRef } from "react";
import { Tour, TourProps } from "antd";
import { useSelector } from "react-redux";
import { getUserBalance } from "selectors/usersSelectors";

const ChargeBalanceTour = (props: IChargeBalanceTour) => {
  const refUserBalance = useRef<any>(null);
  const userBalance = useSelector((state: any) => getUserBalance(state));

  useEffect(() => {
    const userBalanceDOM = document.querySelector(".user-balance-tour-step");

    refUserBalance.current = userBalanceDOM;
  }, [userBalance]);

  const steps: TourProps["steps"] = [
    {
      placement: "leftBottom",
      title: "How to top up the balance",
      description: `Right here, you have the option to top up your balance. To do this, enter the desired amount, agree to the terms and conditions, and confirm the transaction.`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/01/credits.gif"}
          alt="How to add credit"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: () => props.handleFinishTour(),
      className: "tour-charge-balance",
      target: () => refUserBalance.current,
    },
  ];

  return (
    <Tour
      open={refUserBalance.current !== null}
      steps={steps}
      onFinish={() => props.handleFinishTour()}
    />
  );
};

interface IChargeBalanceTour {
  handleFinishTour: () => void;
}

export default ChargeBalanceTour;
