import React, { useState, useEffect, useRef } from "react";
import { Tour, TourProps } from "antd";
import styled from "styled-components";

const HowToOpenServiceOrTemplate = (props: IHowToOpenServiceOrTemplate) => {
  const refAssetMarketplaceMenuItem = useRef<any>(null);
  const refCreateServiceButton = useRef<any>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isOpenTour, setIsOpenTour] = useState<boolean>(false);

  useEffect(() => {
    const isOpen = props.additionalProps.openLeftMenu;

    setIsOpenTour(isOpen);

    if (isOpen) {
      const tour = document.querySelector(".services-tour-step.pulsate");
      if (tour) tour?.classList.remove("pulsate");

      const assetMarketplaceDOM = document.querySelector(
        ".asset-marketplace-tour-step",
      );

      const createServiceBtnDOM = document.querySelector(
        ".create-service-btn-tour-step",
      );

      refAssetMarketplaceMenuItem.current = assetMarketplaceDOM;
      refCreateServiceButton.current = createServiceBtnDOM;
    }
  }, [props.additionalProps.openLeftMenu]);

  const handleClickNextStep = () => {
    setCurrentStep(prevState => prevState + 1);
  };

  const handleClickBackStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  const stepsCreateService: TourProps["steps"] = [
    {
      placement: "rightBottom",
      title: "Asset Marketplace",
      description: `Asset Marketplace is a repository of reusable, templates, app
        blueprints, connectors, UI components, tools and business solutions
        to help you swiftly kickstart your product or solution on the UBOS
        platform`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/04/template-gif.gif"}
          alt="How to clone template"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refAssetMarketplaceMenuItem.current,
    },
    {
      placement: "rightBottom",
      title: "Create service",
      description: `The "Services" in UBOS allows you to manage various services, including the Flow Builder, UI editor, and Database. This module provides a centralized location to configure and work with these services efficiently.`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/01/service.gif"}
          alt="How to create service"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: () => props.handleFinishTour(),
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      target: () => refCreateServiceButton.current,
    },
  ];

  return (
    <Tour
      open={isOpenTour}
      steps={stepsCreateService}
      current={currentStep}
      onFinish={() => props.handleFinishTour()}
    />
  );
};

interface IHowToOpenServiceOrTemplate {
  handleFinishTour: () => void;
  additionalProps?: any;
}

export default HowToOpenServiceOrTemplate;
