import React from "react";
import styled from "styled-components";
const StyledUbosLoader = styled.div`
  .loader {
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;
    z-index: 1000;
  }

  .letterO {
    transform-origin: 65% 49%;
    animation: rotate 2s 3s linear infinite both;
  }

  .right_eye {
    animation: blink 2s 1s ease-out infinite both;
  }

  .world {
    animation: worldsBottom 1.5s 0.5s ease-out both;
  }

  .world_top {
    animation: worldTop 1.5s ease-out both;
  }

  @keyframes blink {
    90% {
      transform: none;
      animation-timing-function: ease-in;
    }

    93% {
      transform: translateY(15px) scaleY(0);
    }

    100% {
      animation-timing-function: ease-out;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes worldTop {
    0% {
      transform: translateX(600px);
      animation-timing-function: ease-in;
      opacity: 0;
    }

    38% {
      transform: translateX(0);
      animation-timing-function: ease-out;
      opacity: 1;
    }

    55% {
      transform: translateX(68px);
      animation-timing-function: ease-in;
    }

    72% {
      transform: translateX(0);
      animation-timing-function: ease-out;
    }

    81% {
      transform: translateX(32px);
      animation-timing-function: ease-in;
    }

    90% {
      transform: translateX(0);
      animation-timing-function: ease-out;
    }

    95% {
      transform: translateX(8px);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translateX(0);
      animation-timing-function: ease-out;
    }
  }

  @keyframes worldsBottom {
    0% {
      transform: translateY(500px) scaleY(2.5) scaleX(0.2);
      transform-origin: 50% 100%;
    }

    100% {
      transform: translateY(0) scaleY(1) scaleX(1);
      transform-origin: 50% 50%;
    }
  }
`;

export const UbosLoader2 = () => {
  return (
    <StyledUbosLoader>
      <div className="loader">
        <svg
          width="420"
          height="142"
          viewBox="0 0 420 142"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className="world_top">
            <path
              d="M140.23 27.4099H186.71C196.88 27.4099 206.2 36.03 206.2 46.77V88.8599C206.089 93.9821 203.996 98.8617 200.361 102.472C196.726 106.083 191.833 108.144 186.71 108.22H119.71V0H140.2L140.23 27.4099ZM185.72 51.1399C185.729 50.4142 185.593 49.6939 185.32 49.0217C185.046 48.3495 184.64 47.7391 184.127 47.2263C183.613 46.7136 183.002 46.3088 182.329 46.0364C181.656 45.7639 180.936 45.6291 180.21 45.6399H140.21V89.99H180.21C180.937 90.0008 181.658 89.8656 182.331 89.5925C183.005 89.3194 183.616 88.914 184.13 88.4001C184.644 87.8863 185.049 87.2747 185.323 86.6013C185.596 85.9279 185.731 85.2065 185.72 84.48V51.1399Z"
              fill="#495870"
            />
            <path
              d="M293.984 27C298.694 27.0468 303.199 29.1007 306.532 32.7204C309.865 36.3402 311.76 41.2373 311.81 46.3601V88.4499C311.781 93.5748 309.897 98.4808 306.565 102.105C303.233 105.729 298.723 107.779 294.012 107.81H248.789C244.093 107.73 239.61 105.664 236.289 102.051C232.968 98.4367 231.072 93.5587 231 88.4499V46.3601C231.072 41.2513 232.968 36.3733 236.289 32.7595C239.61 29.1457 244.093 27.0799 248.789 27H293.984ZM292.945 50.7299C292.955 50.0038 292.831 49.2829 292.58 48.6101C292.329 47.9372 291.956 47.3262 291.483 46.8132C291.011 46.3002 290.448 45.8957 289.829 45.6237C289.211 45.3518 288.548 45.2178 287.88 45.2299H254.894C254.226 45.2178 253.563 45.3518 252.945 45.6237C252.326 45.8957 251.763 46.3002 251.291 46.8132C250.818 47.3262 250.445 47.9372 250.194 48.6101C249.943 49.2829 249.819 50.0038 249.828 50.7299V84.07C249.819 84.7966 249.943 85.518 250.194 86.1914C250.445 86.8647 250.817 87.4764 251.29 87.9902C251.762 88.504 252.325 88.9095 252.944 89.1826C253.563 89.4557 254.226 89.5908 254.894 89.58H287.907C288.576 89.5908 289.238 89.4557 289.858 89.1826C290.477 88.9095 291.039 88.504 291.512 87.9902C291.984 87.4764 292.357 86.8647 292.608 86.1914C292.859 85.518 292.983 84.7966 292.973 84.07L292.945 50.7299Z"
              fill="#495870"
              className="letterO"
            />
            <path
              d="M400.31 58.9199C405.435 58.9514 410.341 61.0011 413.965 64.625C417.589 68.2489 419.639 73.1552 419.67 78.28V88.8699C419.639 93.9948 417.589 98.9008 413.965 102.525C410.341 106.149 405.435 108.199 400.31 108.23H335.04V90.29H393.81C394.537 90.3008 395.258 90.1657 395.931 89.8926C396.605 89.6195 397.217 89.214 397.73 88.7002C398.244 88.1864 398.65 87.5745 398.923 86.9011C399.196 86.2277 399.331 85.5066 399.32 84.78V82.23C399.331 81.5039 399.196 80.7829 398.923 80.1101C398.649 79.4373 398.244 78.8263 397.73 78.3132C397.216 77.8002 396.604 77.3955 395.931 77.1235C395.257 76.8516 394.536 76.7178 393.81 76.73H354.4C351.856 76.7379 349.334 76.2426 346.982 75.2725C344.63 74.3023 342.492 72.8765 340.693 71.0771C338.894 69.2778 337.468 67.1405 336.498 64.7881C335.528 62.4356 335.032 59.9145 335.04 57.3699V46.78C335.048 41.6479 337.09 36.7281 340.719 33.0991C344.348 29.4701 349.268 27.4279 354.4 27.4199H419.67V45.3599H360.9C360.167 45.3257 359.436 45.4449 358.752 45.7097C358.068 45.9745 357.447 46.3791 356.928 46.8977C356.409 47.4163 356.005 48.0374 355.74 48.7214C355.475 49.4054 355.356 50.1372 355.39 50.8699V53.27C355.353 54.01 355.469 54.7495 355.73 55.4426C355.992 56.1357 356.394 56.7674 356.912 57.2979C357.429 57.8283 358.05 58.2463 358.737 58.5254C359.423 58.8045 360.159 58.9388 360.9 58.9199H400.31Z"
              fill="#495870"
            />
          </g>
          <g className="world">
            <path
              d="M2.76999 137.311C2.03696 137.305 1.33544 137.012 0.817093 136.494C0.298748 135.975 0.00523614 135.274 0 134.541V125.761H2.92999V133.921C2.92566 134.025 2.9432 134.129 2.98151 134.227C3.01981 134.324 3.07802 134.412 3.1524 134.485C3.22679 134.559 3.31571 134.616 3.41345 134.653C3.5112 134.69 3.61562 134.706 3.72 134.701H8.92999V125.761H11.86V137.311H2.76999Z"
              fill="#495870"
            />
            <path
              d="M17.2103 125.76H23.8503C24.5819 125.771 25.2812 126.064 25.8014 126.578C26.3216 127.093 26.6222 127.789 26.6403 128.521V134.521C26.6248 135.254 26.3253 135.952 25.8048 136.469C25.2844 136.986 24.5836 137.28 23.8503 137.291H14.2803V121.82H17.2103V125.76ZM23.7103 129.15C23.713 129.046 23.6945 128.942 23.6557 128.845C23.617 128.748 23.5589 128.66 23.485 128.586C23.4111 128.512 23.3229 128.454 23.2258 128.415C23.1287 128.376 23.0248 128.358 22.9203 128.36H17.2103V134.7H22.9203C23.0239 134.703 23.127 134.685 23.2234 134.647C23.3198 134.609 23.4076 134.551 23.4813 134.479C23.5551 134.406 23.6134 134.319 23.6527 134.223C23.6921 134.127 23.7116 134.024 23.7103 133.92V129.15Z"
              fill="#495870"
            />
            <path
              d="M38.2001 125.761V128.361H32.5901C32.4849 128.355 32.3796 128.371 32.2811 128.409C32.1826 128.447 32.0932 128.505 32.0187 128.579C31.9441 128.654 31.8861 128.743 31.8485 128.842C31.8108 128.94 31.7943 129.045 31.8001 129.151V137.311H28.8701V128.521C28.8754 127.789 29.1692 127.088 29.6879 126.571C30.2065 126.055 30.908 125.763 31.6401 125.761H38.2001Z"
              fill="#495870"
            />
            <path
              d="M48.4995 125.761C48.8624 125.759 49.2219 125.83 49.5573 125.968C49.8928 126.106 50.1975 126.31 50.4541 126.566C50.7107 126.823 50.9139 127.127 51.0521 127.463C51.1904 127.798 51.2609 128.158 51.2595 128.521V137.311H41.7895C41.0557 137.308 40.3527 137.015 39.8338 136.496C39.3149 135.977 39.0222 135.275 39.0195 134.541V133.031C39.0222 132.297 39.3149 131.594 39.8338 131.075C40.3527 130.556 41.0557 130.263 41.7895 130.261H48.3395V129.111C48.3438 129.006 48.3262 128.901 48.288 128.803C48.2498 128.706 48.1918 128.617 48.1176 128.543C48.0433 128.469 47.9546 128.41 47.8568 128.372C47.759 128.334 47.6544 128.316 47.5495 128.321H39.7295V125.761H48.4995ZM48.3395 134.761V132.821H42.7395C42.53 132.821 42.3291 132.904 42.1809 133.052C42.0328 133.2 41.9495 133.401 41.9495 133.611V133.981C41.9495 134.084 41.97 134.186 42.0099 134.282C42.0497 134.377 42.108 134.463 42.1815 134.536C42.255 134.608 42.3422 134.666 42.438 134.704C42.5338 134.743 42.6363 134.762 42.7395 134.761H48.3395Z"
              fill="#495870"
            />
            <path
              d="M56.5901 121.84V124.44H53.6602V121.84H56.5901ZM56.5901 125.76V137.31H53.6602V125.76H56.5901Z"
              fill="#495870"
            />
            <path
              d="M68.5895 125.761C69.3209 125.766 70.0209 126.058 70.539 126.574C71.057 127.09 71.3517 127.789 71.3595 128.521V137.311H68.4295V129.151C68.4353 129.045 68.4189 128.94 68.3812 128.842C68.3435 128.743 68.2855 128.654 68.211 128.579C68.1365 128.505 68.047 128.447 67.9486 128.409C67.8501 128.371 67.7448 128.355 67.6395 128.361H61.9495V137.311H59.0195V125.761H68.5895Z"
              fill="#495870"
            />
            <path
              d="M73.5898 128.521C73.5898 128.157 73.6616 127.798 73.8009 127.462C73.9402 127.127 74.1444 126.822 74.4018 126.565C74.6591 126.309 74.9646 126.106 75.3006 125.968C75.6366 125.83 75.9965 125.759 76.3598 125.761H83.2698C84.0028 125.761 84.7058 126.051 85.225 126.568C85.7442 127.086 86.0372 127.788 86.0399 128.521V130.041C86.0399 130.404 85.9681 130.764 85.8288 131.099C85.6895 131.435 85.4853 131.739 85.2279 131.996C84.9706 132.252 84.6651 132.455 84.3291 132.594C83.9931 132.732 83.6331 132.802 83.2698 132.801H76.5198V133.921C76.5155 134.025 76.533 134.129 76.5714 134.227C76.6097 134.324 76.6679 134.412 76.7422 134.485C76.8166 134.559 76.9056 134.616 77.0033 134.653C77.101 134.69 77.2055 134.706 77.3098 134.701H85.5898V137.271H76.4198C75.686 137.268 74.983 136.975 74.4641 136.457C73.9452 135.938 73.6525 135.235 73.6498 134.501L73.5898 128.521ZM77.3098 128.321C77.205 128.316 77.1004 128.334 77.0026 128.372C76.9048 128.41 76.816 128.469 76.7418 128.543C76.6676 128.617 76.6096 128.706 76.5714 128.803C76.5331 128.901 76.5156 129.006 76.5198 129.111V130.321H82.3199C82.4247 130.325 82.5293 130.307 82.6271 130.269C82.7249 130.231 82.8136 130.173 82.8878 130.099C82.9621 130.024 83.0201 129.936 83.0583 129.838C83.0965 129.74 83.1141 129.636 83.1098 129.531V129.111C83.1141 129.006 83.0965 128.901 83.0583 128.803C83.0201 128.706 82.9621 128.617 82.8878 128.543C82.8136 128.469 82.7249 128.41 82.6271 128.372C82.5293 128.334 82.4247 128.316 82.3199 128.321H77.3098Z"
              fill="#495870"
            />
          </g>
          <g className="world">
            <path
              d="M98.7796 125.76H105.42C106.152 125.768 106.853 126.06 107.373 126.575C107.894 127.091 108.194 127.788 108.21 128.521V134.521C108.194 135.254 107.895 135.952 107.374 136.469C106.854 136.986 106.153 137.28 105.42 137.291H95.8496V121.82H98.7796V125.76ZM105.28 129.15C105.282 129.046 105.264 128.942 105.225 128.845C105.186 128.748 105.128 128.66 105.054 128.586C104.98 128.512 104.892 128.454 104.795 128.415C104.698 128.376 104.594 128.358 104.49 128.36H98.7796V134.7H104.49C104.593 134.703 104.696 134.685 104.793 134.647C104.889 134.609 104.977 134.551 105.051 134.479C105.124 134.406 105.183 134.319 105.222 134.223C105.261 134.127 105.281 134.024 105.28 133.92V129.15Z"
              fill="#495870"
            />
            <path
              d="M113.209 137.311C112.476 137.305 111.775 137.012 111.257 136.494C110.738 135.975 110.445 135.274 110.439 134.541V125.761H113.369V133.921C113.365 134.025 113.383 134.129 113.421 134.227C113.459 134.324 113.517 134.412 113.592 134.485C113.666 134.559 113.755 134.616 113.853 134.653C113.951 134.69 114.055 134.706 114.159 134.701H119.369V125.761H122.299V137.311H113.209Z"
              fill="#495870"
            />
            <path
              d="M133.86 130.261C134.594 130.263 135.297 130.556 135.816 131.075C136.335 131.594 136.628 132.297 136.63 133.031V134.541C136.628 135.275 136.335 135.977 135.816 136.496C135.297 137.015 134.594 137.308 133.86 137.311H124.74V134.741H132.94C133.043 134.742 133.145 134.723 133.24 134.684C133.336 134.645 133.422 134.588 133.495 134.515C133.568 134.442 133.625 134.356 133.664 134.261C133.702 134.166 133.722 134.063 133.72 133.961V133.591C133.722 133.487 133.702 133.385 133.664 133.289C133.625 133.193 133.568 133.106 133.495 133.033C133.423 132.959 133.336 132.901 133.241 132.861C133.146 132.821 133.044 132.801 132.94 132.801H127.3C126.937 132.802 126.577 132.732 126.241 132.594C125.905 132.455 125.6 132.252 125.342 131.996C125.085 131.739 124.881 131.435 124.741 131.099C124.602 130.764 124.53 130.404 124.53 130.041V128.521C124.533 127.788 124.826 127.086 125.345 126.568C125.864 126.051 126.567 125.761 127.3 125.761H135.93V128.321H128.23C128.125 128.316 128.021 128.334 127.923 128.372C127.825 128.41 127.736 128.469 127.662 128.543C127.588 128.617 127.53 128.706 127.492 128.803C127.454 128.901 127.436 129.006 127.44 129.111V129.451C127.435 129.557 127.451 129.663 127.489 129.762C127.526 129.862 127.584 129.952 127.658 130.029C127.732 130.105 127.821 130.165 127.92 130.205C128.018 130.245 128.124 130.264 128.23 130.261H133.86Z"
              fill="#495870"
            />
            <path
              d="M141.79 121.84V124.44H138.87V121.84H141.79ZM141.79 125.76V137.31H138.87V125.76H141.79Z"
              fill="#495870"
            />
            <path
              d="M153.79 125.761C154.52 125.768 155.219 126.061 155.736 126.577C156.254 127.093 156.549 127.79 156.56 128.521V137.311H153.59V129.151C153.594 129.046 153.576 128.941 153.538 128.844C153.5 128.746 153.442 128.657 153.368 128.583C153.293 128.508 153.205 128.45 153.107 128.412C153.009 128.374 152.905 128.356 152.8 128.361H147.1V137.311H144.18V125.761H153.79Z"
              fill="#495870"
            />
            <path
              d="M158.79 128.521C158.789 128.157 158.86 127.797 158.998 127.461C159.137 127.124 159.341 126.819 159.599 126.563C159.857 126.306 160.163 126.103 160.499 125.965C160.836 125.828 161.196 125.758 161.56 125.761H168.46C169.193 125.761 169.896 126.051 170.415 126.568C170.934 127.086 171.227 127.788 171.23 128.521V130.041C171.231 130.405 171.161 130.765 171.022 131.101C170.883 131.437 170.679 131.742 170.421 131.999C170.163 132.256 169.858 132.459 169.521 132.596C169.184 132.734 168.824 132.803 168.46 132.801H161.72V133.921C161.716 134.025 161.733 134.129 161.772 134.227C161.81 134.324 161.868 134.412 161.942 134.485C162.017 134.559 162.106 134.616 162.204 134.653C162.301 134.69 162.406 134.706 162.51 134.701H170.73V137.271H161.59C160.855 137.271 160.151 136.979 159.631 136.459C159.112 135.94 158.82 135.235 158.82 134.501L158.79 128.521ZM162.51 128.321C162.405 128.315 162.299 128.331 162.201 128.369C162.103 128.407 162.013 128.465 161.939 128.539C161.864 128.614 161.806 128.703 161.768 128.802C161.731 128.9 161.714 129.005 161.72 129.111V130.321H167.51C167.615 130.326 167.721 130.31 167.819 130.272C167.918 130.235 168.007 130.177 168.082 130.102C168.156 130.028 168.214 129.938 168.252 129.84C168.289 129.741 168.306 129.636 168.3 129.531V129.111C168.306 129.005 168.289 128.9 168.252 128.802C168.214 128.703 168.156 128.614 168.082 128.539C168.007 128.465 167.918 128.407 167.819 128.369C167.721 128.331 167.615 128.315 167.51 128.321H162.51Z"
              fill="#495870"
            />
            <path
              d="M182.59 130.261C183.324 130.263 184.027 130.556 184.546 131.075C185.065 131.594 185.358 132.297 185.36 133.031V134.541C185.358 135.275 185.065 135.977 184.546 136.496C184.027 137.015 183.324 137.308 182.59 137.311H173.49V134.741H181.69C181.793 134.742 181.895 134.723 181.99 134.684C182.086 134.645 182.172 134.588 182.245 134.515C182.318 134.442 182.375 134.356 182.414 134.261C182.452 134.166 182.472 134.063 182.47 133.961V133.591C182.472 133.487 182.452 133.385 182.414 133.289C182.375 133.193 182.318 133.106 182.245 133.033C182.173 132.959 182.086 132.901 181.991 132.861C181.896 132.821 181.794 132.801 181.69 132.801H176.05C175.687 132.802 175.327 132.732 174.991 132.594C174.655 132.455 174.35 132.252 174.092 131.996C173.835 131.739 173.631 131.435 173.491 131.099C173.352 130.764 173.28 130.404 173.28 130.041V128.521C173.283 127.788 173.576 127.086 174.095 126.568C174.614 126.051 175.317 125.761 176.05 125.761H184.67V128.321H176.98C176.875 128.315 176.77 128.331 176.671 128.369C176.573 128.407 176.483 128.465 176.409 128.539C176.334 128.614 176.276 128.703 176.239 128.802C176.201 128.9 176.184 129.005 176.19 129.111V129.451C176.185 129.557 176.201 129.663 176.239 129.762C176.276 129.862 176.334 129.952 176.408 130.029C176.482 130.105 176.571 130.165 176.67 130.205C176.768 130.245 176.874 130.264 176.98 130.261H182.59Z"
              fill="#495870"
            />
            <path
              d="M196.36 130.261C197.094 130.263 197.797 130.556 198.316 131.075C198.835 131.594 199.128 132.297 199.13 133.031V134.541C199.128 135.275 198.835 135.977 198.316 136.496C197.797 137.015 197.094 137.308 196.36 137.311H187.23V134.741H195.43C195.534 134.743 195.637 134.725 195.733 134.687C195.83 134.649 195.918 134.592 195.991 134.519C196.065 134.446 196.123 134.359 196.163 134.263C196.202 134.167 196.222 134.064 196.22 133.961V133.591C196.222 133.486 196.202 133.383 196.163 133.287C196.124 133.19 196.066 133.103 195.992 133.029C195.918 132.955 195.831 132.897 195.734 132.858C195.638 132.819 195.534 132.799 195.43 132.801H189.8C189.437 132.803 189.076 132.734 188.739 132.596C188.403 132.459 188.097 132.255 187.839 131.999C187.582 131.742 187.377 131.437 187.239 131.101C187.1 130.765 187.029 130.404 187.03 130.041V128.521C187.033 127.788 187.326 127.086 187.845 126.568C188.364 126.051 189.067 125.761 189.8 125.761H198.42V128.321H190.73C190.625 128.315 190.52 128.331 190.421 128.369C190.323 128.407 190.233 128.465 190.159 128.539C190.084 128.614 190.026 128.703 189.989 128.802C189.951 128.9 189.935 129.005 189.94 129.111V129.451C189.935 129.557 189.951 129.663 189.989 129.762C190.026 129.862 190.084 129.952 190.158 130.029C190.232 130.105 190.321 130.165 190.42 130.205C190.518 130.245 190.624 130.264 190.73 130.261H196.36Z"
              fill="#495870"
            />
          </g>
          <g className="world">
            <path
              d="M218.59 125.761C219.32 125.769 220.017 126.062 220.533 126.578C221.049 127.094 221.343 127.791 221.35 128.521V134.521C221.348 135.253 221.056 135.954 220.54 136.473C220.023 136.992 219.322 137.286 218.59 137.291H211.59C210.859 137.28 210.16 136.985 209.643 136.468C209.126 135.951 208.831 135.252 208.82 134.521V128.521C208.833 127.791 209.13 127.095 209.646 126.58C210.163 126.065 210.861 125.771 211.59 125.761H218.59ZM218.42 129.151C218.423 129.047 218.405 128.944 218.367 128.847C218.329 128.751 218.271 128.663 218.198 128.59C218.126 128.516 218.039 128.458 217.943 128.418C217.847 128.379 217.744 128.359 217.64 128.361H212.51C212.406 128.358 212.302 128.377 212.205 128.415C212.108 128.454 212.019 128.512 211.946 128.586C211.872 128.66 211.814 128.748 211.775 128.845C211.736 128.942 211.718 129.046 211.72 129.151V133.921C211.719 134.024 211.738 134.127 211.778 134.223C211.817 134.319 211.875 134.406 211.949 134.479C212.023 134.552 212.111 134.609 212.207 134.647C212.304 134.685 212.407 134.703 212.51 134.701H217.59C217.693 134.702 217.795 134.683 217.89 134.644C217.986 134.605 218.072 134.548 218.145 134.475C218.218 134.402 218.275 134.316 218.314 134.221C218.352 134.125 218.372 134.023 218.37 133.921L218.42 129.151Z"
              fill="#495870"
            />
            <path
              d="M233.14 125.76C233.871 125.765 234.571 126.058 235.089 126.574C235.607 127.09 235.902 127.789 235.91 128.52V134.52C235.907 135.254 235.615 135.957 235.096 136.476C234.577 136.995 233.874 137.288 233.14 137.29H226.5V141.09H223.5V125.74L233.14 125.76ZM232.98 129.15C232.986 129.045 232.969 128.94 232.932 128.841C232.894 128.743 232.836 128.653 232.761 128.579C232.687 128.504 232.597 128.446 232.499 128.408C232.401 128.371 232.295 128.354 232.19 128.36H226.5V134.7H232.19C232.294 134.706 232.399 134.69 232.497 134.653C232.594 134.616 232.683 134.558 232.758 134.485C232.832 134.411 232.89 134.323 232.928 134.226C232.967 134.129 232.984 134.025 232.98 133.92V129.15Z"
              fill="#495870"
            />
            <path
              d="M237.96 128.521C237.959 128.157 238.029 127.797 238.168 127.461C238.307 127.124 238.511 126.819 238.769 126.563C239.027 126.306 239.333 126.103 239.669 125.965C240.006 125.828 240.366 125.758 240.73 125.761H247.59C248.323 125.761 249.026 126.051 249.545 126.568C250.064 127.086 250.357 127.788 250.36 128.521V130.041C250.361 130.405 250.291 130.765 250.152 131.101C250.013 131.437 249.809 131.742 249.551 131.999C249.293 132.256 248.987 132.459 248.651 132.596C248.314 132.734 247.954 132.803 247.59 132.801H240.89V133.921C240.886 134.025 240.903 134.129 240.941 134.227C240.98 134.324 241.038 134.412 241.112 134.485C241.187 134.559 241.276 134.616 241.373 134.653C241.471 134.69 241.576 134.706 241.68 134.701H249.9V137.271H240.73C239.995 137.271 239.291 136.979 238.771 136.459C238.252 135.94 237.96 135.235 237.96 134.501V128.521ZM241.68 128.321C241.575 128.315 241.469 128.331 241.371 128.369C241.272 128.407 241.183 128.465 241.109 128.539C241.034 128.614 240.976 128.703 240.938 128.802C240.901 128.9 240.884 129.005 240.89 129.111V130.321H246.69C246.794 130.325 246.899 130.307 246.996 130.269C247.093 130.231 247.181 130.173 247.255 130.098C247.328 130.024 247.385 129.935 247.422 129.837C247.459 129.739 247.476 129.635 247.47 129.531V129.111C247.476 129.006 247.459 128.902 247.422 128.804C247.385 128.707 247.328 128.617 247.255 128.543C247.181 128.469 247.093 128.41 246.996 128.372C246.899 128.334 246.794 128.316 246.69 128.321H241.68Z"
              fill="#495870"
            />
            <path
              d="M261.97 125.761V128.361H256.35C256.245 128.356 256.14 128.374 256.042 128.412C255.945 128.45 255.856 128.508 255.782 128.583C255.707 128.657 255.649 128.746 255.611 128.844C255.573 128.941 255.555 129.046 255.56 129.151V137.311H252.64V128.521C252.645 127.79 252.937 127.091 253.454 126.575C253.97 126.058 254.669 125.766 255.4 125.761H261.97Z"
              fill="#495870"
            />
            <path
              d="M272.26 125.761C272.624 125.758 272.985 125.828 273.321 125.965C273.658 126.103 273.964 126.306 274.221 126.563C274.479 126.819 274.683 127.124 274.822 127.461C274.961 127.797 275.032 128.157 275.03 128.521V137.311H265.59C264.856 137.311 264.151 137.019 263.632 136.499C263.112 135.98 262.82 135.275 262.82 134.541V133.031C262.82 132.296 263.112 131.592 263.632 131.072C264.151 130.553 264.856 130.261 265.59 130.261H272.13V129.111C272.136 129.005 272.12 128.9 272.082 128.802C272.044 128.703 271.986 128.614 271.912 128.539C271.837 128.465 271.748 128.407 271.649 128.369C271.551 128.331 271.446 128.315 271.34 128.321H263.53V125.761H272.26ZM272.1 134.761V132.821H266.51C266.406 132.819 266.303 132.839 266.206 132.878C266.11 132.917 266.022 132.976 265.949 133.049C265.875 133.123 265.817 133.21 265.778 133.307C265.738 133.403 265.719 133.507 265.72 133.611V133.981C265.719 134.084 265.738 134.187 265.778 134.283C265.817 134.379 265.875 134.466 265.949 134.539C266.023 134.612 266.111 134.669 266.207 134.707C266.304 134.745 266.407 134.764 266.51 134.761H272.1Z"
              fill="#495870"
            />
            <path
              d="M288.31 128.36H283.83V137.31H280.9V128.36H276.43V125.76H280.9V123.15H283.83V125.76H288.31V128.36Z"
              fill="#495870"
            />
            <path
              d="M292.65 121.84V124.44H289.72V121.84H292.65ZM292.65 125.76V137.31H289.72V125.76H292.65Z"
              fill="#495870"
            />
            <path
              d="M304.64 125.761C305.372 125.766 306.072 126.058 306.59 126.574C307.108 127.09 307.402 127.789 307.41 128.521V137.311H304.48V129.151C304.486 129.045 304.47 128.94 304.432 128.842C304.394 128.743 304.336 128.654 304.262 128.579C304.187 128.505 304.098 128.447 303.999 128.409C303.901 128.371 303.796 128.355 303.69 128.361H298V137.311H295.07V125.761H304.64Z"
              fill="#495870"
            />
            <path
              d="M311.7 141.11V138.54H318.27C318.374 138.545 318.478 138.527 318.576 138.489C318.673 138.45 318.761 138.392 318.834 138.318C318.908 138.243 318.965 138.154 319.002 138.057C319.039 137.959 319.055 137.855 319.05 137.75V137.31H312.41C311.676 137.307 310.973 137.015 310.454 136.496C309.935 135.977 309.642 135.274 309.64 134.54V128.54C309.645 127.808 309.939 127.108 310.457 126.591C310.976 126.074 311.678 125.783 312.41 125.78H321.98V138.36C321.974 139.091 321.682 139.792 321.166 140.31C320.65 140.828 319.951 141.122 319.22 141.13L311.7 141.11ZM319.05 134.7V128.36H313.36C313.254 128.354 313.149 128.371 313.051 128.408C312.952 128.446 312.863 128.504 312.788 128.579C312.714 128.653 312.656 128.743 312.618 128.841C312.58 128.94 312.564 129.045 312.57 129.15V133.92C312.565 134.025 312.583 134.129 312.621 134.226C312.659 134.323 312.718 134.411 312.792 134.485C312.866 134.558 312.955 134.616 313.053 134.653C313.151 134.69 313.255 134.706 313.36 134.7H319.05Z"
              fill="#495870"
            />
          </g>
          <g className="world">
            <path
              d="M341.12 130.261C341.852 130.266 342.553 130.56 343.069 131.078C343.586 131.597 343.877 132.299 343.88 133.031V134.541C343.877 135.273 343.586 135.974 343.069 136.493C342.553 137.012 341.852 137.305 341.12 137.311H331.99V134.741H340.19C340.293 134.742 340.396 134.723 340.492 134.684C340.587 134.645 340.675 134.588 340.748 134.516C340.822 134.443 340.88 134.357 340.92 134.261C340.96 134.166 340.98 134.064 340.98 133.961V133.591C340.98 133.381 340.897 133.18 340.749 133.032C340.601 132.884 340.4 132.801 340.19 132.801H334.59C334.227 132.802 333.868 132.731 333.532 132.593C333.197 132.455 332.892 132.252 332.636 131.995C332.379 131.739 332.176 131.434 332.037 131.098C331.899 130.763 331.829 130.404 331.83 130.041V128.521C331.833 127.79 332.124 127.089 332.641 126.572C333.158 126.055 333.859 125.763 334.59 125.761H343.22V128.321H335.52C335.415 128.316 335.311 128.334 335.213 128.372C335.115 128.41 335.026 128.469 334.952 128.543C334.878 128.617 334.82 128.706 334.782 128.803C334.743 128.901 334.726 129.006 334.73 129.111V129.451C334.725 129.557 334.741 129.663 334.778 129.762C334.816 129.862 334.874 129.952 334.948 130.029C335.022 130.105 335.111 130.165 335.21 130.205C335.308 130.245 335.414 130.264 335.52 130.261H341.12Z"
              fill="#495870"
            />
            <path
              d="M348.88 137.311C348.148 137.305 347.448 137.012 346.931 136.493C346.414 135.974 346.123 135.273 346.12 134.541V125.761H349.05V133.921C349.046 134.024 349.063 134.128 349.101 134.224C349.139 134.321 349.196 134.408 349.269 134.482C349.342 134.555 349.43 134.612 349.527 134.65C349.623 134.688 349.727 134.705 349.83 134.701H355.53V125.761H358.46V138.341C358.447 139.071 358.151 139.768 357.635 140.285C357.118 140.802 356.421 141.098 355.69 141.111H348.2V138.541H354.74C354.845 138.547 354.951 138.53 355.049 138.492C355.148 138.455 355.237 138.397 355.312 138.322C355.386 138.247 355.444 138.158 355.482 138.06C355.519 137.961 355.536 137.856 355.53 137.751V137.311H348.88Z"
              fill="#495870"
            />
            <path
              d="M370.019 130.261C370.753 130.263 371.456 130.556 371.975 131.075C372.494 131.594 372.787 132.297 372.79 133.031V134.541C372.787 135.275 372.494 135.977 371.975 136.496C371.456 137.015 370.753 137.308 370.019 137.311H360.889V134.741H369.089C369.193 134.743 369.296 134.725 369.393 134.687C369.489 134.649 369.577 134.592 369.651 134.519C369.724 134.446 369.783 134.359 369.822 134.263C369.861 134.167 369.881 134.064 369.879 133.961V133.591C369.881 133.486 369.861 133.383 369.822 133.287C369.783 133.19 369.725 133.103 369.651 133.029C369.577 132.955 369.49 132.897 369.393 132.858C369.297 132.819 369.194 132.799 369.089 132.801H363.459C363.096 132.803 362.735 132.734 362.399 132.596C362.062 132.459 361.756 132.255 361.498 131.999C361.241 131.742 361.037 131.437 360.898 131.101C360.759 130.765 360.688 130.404 360.689 130.041V128.521C360.692 127.788 360.985 127.086 361.504 126.568C362.024 126.051 362.727 125.761 363.459 125.761H372.079V128.321H364.389C364.284 128.315 364.179 128.331 364.08 128.369C363.982 128.407 363.893 128.465 363.818 128.539C363.743 128.614 363.686 128.703 363.648 128.802C363.61 128.9 363.594 129.005 363.6 129.111V129.451C363.594 129.557 363.61 129.663 363.648 129.762C363.685 129.862 363.743 129.952 363.817 130.029C363.891 130.105 363.981 130.165 364.079 130.205C364.178 130.245 364.283 130.264 364.389 130.261H370.019Z"
              fill="#495870"
            />
            <path
              d="M385.89 128.36H381.41V137.31H378.48V128.36H374.01V125.76H378.48V123.15H381.41V125.76H385.89V128.36Z"
              fill="#495870"
            />
            <path
              d="M387.12 128.521C387.119 128.158 387.189 127.798 387.328 127.463C387.466 127.127 387.669 126.823 387.926 126.566C388.182 126.31 388.487 126.106 388.822 125.968C389.158 125.83 389.517 125.759 389.88 125.761H396.79C397.523 125.761 398.226 126.051 398.745 126.568C399.264 127.086 399.557 127.788 399.56 128.521V130.041C399.561 130.404 399.491 130.765 399.352 131.101C399.213 131.437 399.009 131.742 398.751 131.999C398.494 132.255 398.188 132.459 397.851 132.596C397.514 132.734 397.154 132.803 396.79 132.801H390.04V133.921C390.036 134.025 390.053 134.129 390.092 134.227C390.13 134.324 390.188 134.412 390.263 134.485C390.337 134.559 390.426 134.616 390.524 134.653C390.621 134.69 390.726 134.706 390.83 134.701H399.05V137.271H389.88C389.147 137.268 388.445 136.975 387.928 136.456C387.411 135.937 387.12 135.234 387.12 134.501V128.521ZM390.83 128.321C390.725 128.316 390.621 128.334 390.523 128.372C390.425 128.41 390.336 128.469 390.262 128.543C390.188 128.617 390.13 128.706 390.092 128.803C390.053 128.901 390.036 129.006 390.04 129.111V130.321H395.84C395.945 130.326 396.051 130.31 396.149 130.272C396.248 130.235 396.337 130.177 396.412 130.102C396.486 130.028 396.544 129.938 396.582 129.84C396.619 129.741 396.636 129.636 396.63 129.531V129.111C396.636 129.005 396.619 128.9 396.582 128.802C396.544 128.703 396.486 128.614 396.412 128.539C396.337 128.465 396.248 128.407 396.149 128.369C396.051 128.331 395.945 128.315 395.84 128.321H390.83Z"
              fill="#495870"
            />
            <path
              d="M416.88 125.761C417.612 125.771 418.311 126.064 418.831 126.578C419.351 127.093 419.652 127.789 419.67 128.521V137.311H416.74V129.151C416.741 129.047 416.722 128.943 416.683 128.847C416.643 128.75 416.585 128.663 416.512 128.589C416.438 128.515 416.35 128.457 416.254 128.418C416.157 128.379 416.054 128.359 415.95 128.361H412.15V137.311H409.22V128.361H404.72V137.311H401.79V125.761H416.88Z"
              fill="#495870"
            />
          </g>
          <g className="smile">
            <path
              d="M99.2499 66.1704L82.7099 74.4404L66.1699 66.1704L82.7099 57.9004L99.2499 66.1704Z"
              fill="#02EEFD"
            />
            <path
              d="M82.7198 74.4399C79.6451 80.5812 74.9217 85.7456 69.0785 89.3547C63.2352 92.9639 56.5028 94.8755 49.6348 94.8755C42.7668 94.8755 36.0343 92.9639 30.1911 89.3547C24.3479 85.7456 19.6245 80.5812 16.5498 74.4399L33.0898 66.1699C34.625 69.2423 36.9857 71.8263 39.9071 73.6323C42.8285 75.4384 46.1952 76.395 49.6298 76.395C53.0644 76.395 56.4311 75.4384 59.3525 73.6323C62.2739 71.8263 64.6346 69.2423 66.1698 66.1699L82.7198 74.4399Z"
              fill="#08B5FB"
            />
            <path
              d="M33.08 66.1704L16.54 74.4404L0 66.1704L16.54 57.9004L33.08 66.1704Z"
              fill="#02EEFD"
            />
            <path
              d="M99.2595 66.1699C99.2547 66.2098 99.2547 66.2502 99.2595 66.29C97.2763 78.0114 91.2066 88.6516 82.1264 96.3245C73.0463 103.997 61.5424 108.207 49.6545 108.207C37.7666 108.207 26.2627 103.997 17.1826 96.3245C8.10244 88.6516 2.03269 78.0114 0.0495115 66.29C0.0446941 66.2502 0.0446941 66.2098 0.0495115 66.1699L16.5895 74.4399C19.6642 80.5812 24.3876 85.7456 30.2308 89.3547C36.0741 92.9639 42.8065 94.8755 49.6745 94.8755C56.5425 94.8755 63.275 92.9639 69.1182 89.3547C74.9614 85.7456 79.6848 80.5812 82.7595 74.4399L99.2595 66.1699Z"
              fill="#3B82FF"
            />
          </g>
          <g className="right_eye">
            <path
              d="M82.7099 16.5503V49.6304L66.1699 41.3604V8.28027L82.7099 16.5503Z"
              fill="#08B5FB"
            />
            <path
              d="M99.2599 8.27979L82.7099 16.5498L66.1699 8.27979L82.7099 0.00976562L99.2599 8.27979Z"
              fill="#02EEFD"
            />
            <path
              d="M99.26 8.28027V41.3604L82.71 49.6304V16.5503L99.26 8.28027Z"
              fill="#3B82FF"
            />
          </g>
          <g className="left_eye">
            <path
              d="M33.08 8.27979L16.54 16.5498L0 8.27979L16.54 0.00976562L33.08 8.27979Z"
              fill="#02EEFD"
            />
            <path
              d="M16.54 16.5503V49.6304L0 41.3604V8.28027L16.54 16.5503Z"
              fill="#08B5FB"
            />
            <path
              d="M33.08 8.28027V41.3604L16.54 49.6304V16.5503L33.08 8.28027Z"
              fill="#3B82FF"
            />
          </g>
        </svg>
      </div>
    </StyledUbosLoader>
  );
};

export default UbosLoader2;
