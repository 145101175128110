import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "redux-saga/effects";
import { eventChannel, EventChannel } from "redux-saga";
import {
  EvaluationReduxAction,
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "constants/ReduxActionConstants";
import {
  getDataTree,
  getUnevaluatedDataTree,
} from "selectors/dataTreeSelectors";
import WidgetFactory, { WidgetTypeConfigMap } from "../utils/WidgetFactory";
import Worker from "worker-loader!../workers/evaluation.worker";
import {
  EVAL_WORKER_ACTIONS,
  EvalError,
  EvalErrorTypes,
  getDynamicBindings,
} from "../utils/DynamicBindingUtils";
import { ToastType } from "react-toastify";
import { AppToaster } from "../components/editorComponents/ToastComponent";
import log from "loglevel";
import _ from "lodash";
import { WidgetType } from "../constants/WidgetConstants";
import { WidgetProps } from "../widgets/BaseWidget";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "../utils/PerformanceTracker";
import DebugConsole from "../utils/DebugConsole";
import { ENTITY_TYPE } from "../entities/Console";
import { DataTree } from "../entities/DataTree/dataTreeFactory";
import { diff } from "deep-diff";
import unescapeJS from "unescape-js";
import ColumnActionSelectorControl from "components/propertyControls/ColumnActionSelectorControl";

let evaluationWorker: Worker;
let workerChannel: EventChannel<any>;
let widgetTypeConfigMap: WidgetTypeConfigMap;
let cachedDataTree: any;
const cachedDataTree_from_ui: any = {};
const initEvaluationWorkers = () => {
  // If an old worker exists, terminate it
  if (evaluationWorker) {
    evaluationWorker.terminate();
  }
  widgetTypeConfigMap = WidgetFactory.getWidgetTypeConfigMap();
  evaluationWorker = new Worker();
  workerChannel = eventChannel(emitter => {
    evaluationWorker.addEventListener("message", emitter);
    // The subscriber must return an unsubscribe function
    return () => {
      evaluationWorker.removeEventListener("message", emitter);
    };
  });
};

const evalErrorHandler = (errors: EvalError[]) => {
  errors.forEach(error => {
    if (error.type === EvalErrorTypes.DEPENDENCY_ERROR) {
      AppToaster.show({
        message: error.message,
        type: ToastType.ERROR,
      });
      DebugConsole.error({
        text: `${error.message}`,
      });
    }
    log.debug(error);
  });
};

function* postEvalActionDispatcher(actions: ReduxAction<unknown>[]) {
  for (const action of actions) {
    yield put(action);
  }
}

let saveTreeEvaluation: any = "{}";
// let saveTreeEvaluationStart: any = "{}";
const functionTree = [
  "closeModal",
  "download",
  "navigateTo",
  "navigateToPage",
  "pageList",
  "resetWidget",
  "sendSocketMsg",
  "showAlert",
  "showModal",
  "storeGlobalValue",
  "storeValue",
  "updateWidget",
];
const dynamicBindingsWidget: any = {};

function* evaluateTreeSaga(postEvalActions?: ReduxAction<unknown>[]) {
  const unEvalTree = yield select(getUnevaluatedDataTree);

  // const diffTrees = diff(saveTreeEvaluationStart, unEvalTree) || [];
  // saveTreeEvaluationStart = _.cloneDeep(unEvalTree);
  // let reducedTree: any = {};
  // if (diffTrees.length !== 0) {
  //   for (const item of diffTrees) {
  //     if (item.path && unEvalTree[item.path[0]]) {
  //       reducedTree[item.path[0]] = unEvalTree[item.path[0]];
  //     }
  //   }
  //   if (unEvalTree["appsmith"]) {
  //     reducedTree["appsmith"] = unEvalTree["appsmith"];
  //   }

  //   const depend = [];
  //   for (const key in reducedTree) {
  //     depend.push(key);
  //   }
  //   if (depend.length > 0) {
  //     const dep: any = [];
  //     for (const key in unEvalTree) {
  //       if (Object.prototype.hasOwnProperty.call(unEvalTree, key)) {
  //         const element = unEvalTree[key];
  //         for (const keys in element) {
  //           if (Object.prototype.hasOwnProperty.call(element, keys)) {
  //             const item = element[keys];
  //             if (
  //               typeof item == "string" &&
  //               item.search(key) == -1 &&
  //               item.match(/([a-zA-Z_$0-9]*?)\./g)
  //             ) {
  //               depend.map((e: any) => {
  //                 if (item.search(e) !== -1) {
  //                   const arr = item.match(/([a-zA-Z_$0-9]*?)\./g);
  //                   if (arr) {
  //                     for (const el of arr) {
  //                       const elem = el.split(".")[0];
  //                     }
  //                     reducedTree[key] = unEvalTree[key];
  //                     dep.push(key);
  //                   }
  //                 }
  //               });
  //             }
  //           }
  //         }
  //       }
  //     }
  //     for (const iterator of dep) {
  //       if (unEvalTree[iterator] && reducedTree[iterator]) {
  //         for (const key in unEvalTree[iterator]) {
  //           if (
  //             Object.prototype.hasOwnProperty.call(unEvalTree[iterator], key)
  //           ) {
  //             const item = unEvalTree[iterator][key];
  //             if (
  //               typeof item == "string" &&
  //               item.search(iterator) == -1 &&
  //               item.match(/([a-zA-Z_$0-9]*?)\./g)?.length
  //             ) {
  //               const result = item.match(/([a-zA-Z_$0-9]*?)\./g);
  //               for (const el of result ? result : []) {
  //                 const elem = el.split(".")[0];
  //                 if (!reducedTree[elem]) {
  //                   reducedTree[elem] = unEvalTree[elem];
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // } else {
  // reducedTree = unEvalTree;
  // }
  // console.log(reducedTree, "reducedTreereducedTree");
  evaluationWorker.postMessage({
    action: EVAL_WORKER_ACTIONS.EVAL_TREE,
    dataTree: unEvalTree, //filteredunEvalTree
    widgetTypeConfigMap,
  });
  const workerResponse = yield take(workerChannel);
  const { errors, dataTree } = workerResponse.data;

  const parsedDataTree = JSON.parse(dataTree);
  console.log("dynt", errors, parsedDataTree);
  const assignDataTree = _.assign(
    JSON.parse(saveTreeEvaluation),
    parsedDataTree,
  );

  evalErrorHandler(errors);
  yield put({
    type: ReduxActionTypes.SET_EVALUATED_TREE,
    payload: assignDataTree,
  });

  if (postEvalActions && postEvalActions.length) {
    yield call(postEvalActionDispatcher, postEvalActions);
  }
  saveTreeEvaluation = JSON.stringify(assignDataTree);
  const newSaveTreeEvaluation = JSON.parse(saveTreeEvaluation);
  for (const func of functionTree) {
    delete newSaveTreeEvaluation[func];
  }
  saveTreeEvaluation = JSON.stringify(newSaveTreeEvaluation);
}

export function* evaluateSingleValue(binding: string) {
  if (evaluationWorker) {
    const dataTree = yield select(getDataTree);

    evaluationWorker.postMessage({
      action: EVAL_WORKER_ACTIONS.EVAL_SINGLE,
      dataTree,
      binding,
    });
    const workerResponse = yield take(workerChannel);
    const { errors, value } = workerResponse.data;
    console.log("dynt", errors, value);
    evalErrorHandler(errors);
    return value;
  }
}

// let saveTreeEvaluationStartTrigers: any = null;

export function* evaluateDynamicTrigger(
  dynamicTrigger: string,
  callbackData: any,
) {
  if (evaluationWorker) {
    const unEvalTree = yield select(getUnevaluatedDataTree);
    const reducedTree: any = {};

    // for (const key in unEvalTree) {
    //   if (Object.prototype.hasOwnProperty.call(unEvalTree, key)) {
    //     const check = dynamicTrigger.search(key);
    //     if (check !== -1) {
    //       reducedTree[key] = unEvalTree[key];
    //     }
    //   }
    // }
    evaluationWorker.postMessage({
      action: EVAL_WORKER_ACTIONS.EVAL_TRIGGER,
      dataTree: unEvalTree,
      dynamicTrigger,
      callbackData,
    });
    const workerResponse = yield take(workerChannel);
    const { errors, triggers } = workerResponse.data;
    console.log("dynt", errors, triggers);
    evalErrorHandler(errors);
    return triggers;
  }
  return [];
}

export function* clearEvalCache() {
  if (evaluationWorker) {
    // saveTreeEvaluation = "{}";
    // saveTreeEvaluationStart = "{}";
    // saveTreeEvaluationStartTrigers = null;
    evaluationWorker.postMessage({
      action: EVAL_WORKER_ACTIONS.CLEAR_CACHE,
    });
    yield take(workerChannel);
    return true;
  }
}

export function* clearEvalPropertyCache(propertyPath: string) {
  if (evaluationWorker) {
    evaluationWorker.postMessage({
      action: EVAL_WORKER_ACTIONS.CLEAR_PROPERTY_CACHE,
      propertyPath,
    });
    yield take(workerChannel);
  }
}

export function* validateProperty(
  widgetType: WidgetType,
  property: string,
  value: any,
  props: WidgetProps,
) {
  if (evaluationWorker) {
    evaluationWorker.postMessage({
      action: EVAL_WORKER_ACTIONS.VALIDATE_PROPERTY,
      widgetType,
      property,
      value,
      props,
    });
    const response = yield take(workerChannel);
    return response.data;
  }
  return { isValid: true, parsed: value };
}

const EVALUATE_REDUX_ACTIONS = [
  // Actions
  ReduxActionTypes.FETCH_ACTIONS_SUCCESS,
  ReduxActionTypes.FETCH_ACTIONS_VIEW_MODE_SUCCESS,
  ReduxActionErrorTypes.FETCH_ACTIONS_ERROR,
  ReduxActionErrorTypes.FETCH_ACTIONS_VIEW_MODE_ERROR,
  ReduxActionTypes.FETCH_ACTIONS_FOR_PAGE_SUCCESS,
  ReduxActionTypes.SUBMIT_CURL_FORM_SUCCESS,
  ReduxActionTypes.CREATE_ACTION_SUCCESS,
  ReduxActionTypes.UPDATE_ACTION_PROPERTY,
  ReduxActionTypes.DELETE_ACTION_SUCCESS,
  ReduxActionTypes.COPY_ACTION_SUCCESS,
  ReduxActionTypes.MOVE_ACTION_SUCCESS,
  ReduxActionTypes.RUN_ACTION_REQUEST,
  ReduxActionTypes.RUN_ACTION_SUCCESS,
  ReduxActionErrorTypes.RUN_ACTION_ERROR,
  ReduxActionTypes.EXECUTE_API_ACTION_REQUEST,
  ReduxActionTypes.EXECUTE_API_ACTION_REQUEST_ALL,
  ReduxActionTypes.EXECUTE_API_ACTION_SUCCESS,
  ReduxActionTypes.EXECUTE_API_ACTION_SUCCESS_ALL,
  ReduxActionErrorTypes.EXECUTE_ACTION_ERROR,
  // App Data
  ReduxActionTypes.SET_APP_MODE,
  ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
  ReduxActionTypes.SET_URL_DATA,
  ReduxActionTypes.UPDATE_APP_STORE,
  ReduxActionTypes.UPDATE_APP_GLOBAL_STORE,
  ReduxActionTypes.UPDATE_APP_SOCKET_STORE,
  // Widgets
  ReduxActionTypes.UPDATE_LAYOUT,
  ReduxActionTypes.UPDATE_WIDGET_PROPERTY,
  ReduxActionTypes.UPDATE_WIDGET_NAME_SUCCESS,
  // Widget Meta
  ReduxActionTypes.SET_META_PROP,
  ReduxActionTypes.RESET_WIDGET_META,
  // Pages
  ReduxActionTypes.FETCH_PAGE_SUCCESS,
  ReduxActionTypes.FETCH_PUBLISHED_PAGE_SUCCESS,
  // Batches
  ReduxActionTypes.BATCH_UPDATES_SUCCESS,
];

function* evaluationChangeListenerSaga() {
  initEvaluationWorkers();
  yield fork(evaluateTreeSaga);
  while (true) {
    const action: EvaluationReduxAction<unknown | unknown[]> = yield take(
      EVALUATE_REDUX_ACTIONS,
    );
    // When batching success action happens, we need to only evaluate
    // if the batch had any action we need to evaluate properties for
    if (
      action.type === ReduxActionTypes.BATCH_UPDATES_SUCCESS &&
      Array.isArray(action.payload)
    ) {
      const batchedActionTypes = action.payload.map(
        (batchedAction: ReduxAction<unknown>) => batchedAction.type,
      );
      if (
        _.intersection(EVALUATE_REDUX_ACTIONS, batchedActionTypes).length === 0
      ) {
        continue;
      }
    }
    log.debug(`Evaluating`, { action });
    yield fork(evaluateTreeSaga, action.postEvalActions);
  }
  // TODO(hetu) need an action to stop listening and evaluate (exit app)
}

export default function* evaluationSagaListeners() {
  yield all([
    takeLatest(ReduxActionTypes.START_EVALUATION, evaluationChangeListenerSaga),
  ]);
}
