import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { AppState } from "reducers";
import { ToastType } from "react-toastify";
import Api from "api/Api";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { AppToaster } from "components/editorComponents/ToastComponent";
import { WrapperCollapse, Title } from "./SidebarStyled";
import { ReactComponent as OpenFolderIcon } from "../../../assets/images/work_space/open-folder-icon.svg";
import { ReactComponent as CloseFolderIcon } from "../../../assets/images/work_space/close-folder-icon.svg";
import { ReactComponent as CloseArrow } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as OpenArrow } from "../../../assets/images/work_space/open.svg";

interface IFolderCreator {
  isCreatingFolder: boolean;
  setIsCreatingFolder: React.Dispatch<React.SetStateAction<boolean>>;
  parentId?: string;
}

export const FolderCreator = ({
  isCreatingFolder,
  setIsCreatingFolder,
  parentId,
}: IFolderCreator) => {
  const dispatch = useDispatch();

  const organizationId = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );
  const [newFolderName, setNewFolderName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmCreateFolder = () => {
    setIsLoading(true);

    if (newFolderName.length > 0) {
      createFolderRequest();
    } else {
      setIsCreatingFolder(false);
      setNewFolderName("");
    }
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirmCreateFolder();
    }
  };

  const createFolderRequest = async () => {
    try {
      const body = {
        folderName: newFolderName,
        parentId,
        organizationId,
      };

      const response = await Api.post("v2/organizations/createFolder", body);

      if (response.data.error) {
        AppToaster.show({
          message: response.data.message
            ? response.data.message
            : "Something went wrong",
          type: ToastType.WARNING,
        });

        setIsCreatingFolder(false);
        setNewFolderName("");
      } else {
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });

        setIsCreatingFolder(false);
        setNewFolderName("");
      }

      return response.data;
    } catch (error) {
      AppToaster.show({
        message: "Something went wrong",
        type: ToastType.WARNING,
      });

      setIsCreatingFolder(false);
      setNewFolderName("");
      console.error("createFolderRequest error:", error);
    }
  };

  return (
    <>
      {isCreatingFolder && (
        <WrapperCollapse className={"active"} style={{ paddingLeft: "8px" }}>
          <Title>
            <div>
              <CloseArrow />
            </div>
            <div>{isLoading ? "Load" : <CloseFolderIcon />}</div>
            <input
              type="text"
              autoFocus
              disabled={isLoading}
              value={newFolderName}
              onChange={e => setNewFolderName(e.target.value)}
              onBlur={() => handleConfirmCreateFolder()}
              onKeyPress={e => handleEnterKeyPress(e)}
            />
          </Title>
        </WrapperCollapse>
      )}
    </>
  );
};

interface IFolderEdit {
  folderName: string;
  folderId: string;
  isOpenFolder: boolean;
  isEditingFolder: boolean;
  setIsEditingFolder: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FolderEdit = ({
  folderName,
  folderId,
  isOpenFolder,
  isEditingFolder,
  setIsEditingFolder,
}: IFolderEdit) => {
  const dispatch = useDispatch();

  const organizationId = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );
  const [newFolderName, setNewFolderName] = useState(folderName);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmCreateFolder = () => {
    setIsLoading(true);

    if (newFolderName.length > 0 && folderName !== newFolderName) {
      editFolderRequest();
    } else {
      handleResset();
    }
  };

  const handleEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleConfirmCreateFolder();
    }
  };

  const handleResset = () => {
    setIsEditingFolder(false);
    setNewFolderName("");
  };

  const editFolderRequest = async () => {
    try {
      const body = {
        folderId,
        folderName: newFolderName,
        organizationId,
      };

      const response = await Api.put("v2/organizations/editFolder", body);

      if (response.data.error) {
        AppToaster.show({
          message: response.data.message
            ? response.data.message
            : "Something went wrong",
          type: ToastType.WARNING,
        });
      } else {
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      }
      handleResset();
    } catch (error) {
      AppToaster.show({
        message: "Something went wrong",
        type: ToastType.WARNING,
      });
      handleResset();

      console.error("editFolderRequest error:", error);
    }
  };

  return (
    <>
      {isEditingFolder && (
        <Title>
          <div>{isOpenFolder ? <OpenArrow /> : <CloseArrow />}</div>
          <div>
            {isLoading ? (
              "Load"
            ) : isOpenFolder ? (
              <OpenFolderIcon />
            ) : (
              <CloseFolderIcon />
            )}
          </div>

          <input
            type="text"
            autoFocus
            disabled={isLoading}
            value={newFolderName}
            onChange={e => setNewFolderName(e.target.value)}
            onBlur={() => handleConfirmCreateFolder()}
            onKeyPress={e => handleEnterKeyPress(e)}
          />
        </Title>
      )}
    </>
  );
};

export const handleOpenRequest = debounce(
  async (
    isOpen: boolean,
    folderId: string | undefined,
    organizationId: string | undefined,
    dispatch: any,
  ) => {
    const body = {
      folderId,
      organizationId,
      isOpen,
    };

    try {
      const response = await Api.put("v2/organizations/editIsOpenFolder", body);

      if (response.data.error) {
        AppToaster.show({
          message: response.data.message
            ? response.data.message
            : "Something went wrong",
          type: ToastType.WARNING,
        });
      } else {
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
      }
    } catch (error) {
      AppToaster.show({
        message: "Something went wrong",
        type: ToastType.WARNING,
      });
      console.error("handleOpenRequest error: ", error);
    }
  },
  500,
);
