import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as HomeIcon } from "../../assets/images/work_space/SideHome.svg";
import { ReactComponent as AdminIcon } from "../../assets/images/work_space/AdminIcon.svg";
import { ReactComponent as TemplateIcon } from "../../assets/images/work_space/templateIcon.svg";
import { ReactComponent as WorkspaceIcon } from "../../assets/images/work_space/SideIcon.svg";
import { ReactComponent as AdminIcon2 } from "../../assets/images/work_space/info.svg";
import { ReactComponent as UserIcon } from "../../assets/images/work_space/user.svg";
import { ReactComponent as Edit } from "../../assets/images/work_space/Edit.svg";
import { ReactComponent as Info } from "../../assets/images/work_space/user_info.svg";
import { ReactComponent as SideSettings } from "../../assets/images/work_space/SideSettings.svg";
import { ReactComponent as Show } from "../../assets/images/work_space/show.svg";
import { ReactComponent as Hide } from "../../assets/images/work_space/hide.svg";
import { ReactComponent as Monitor } from "../../assets/images/work_space/monitor.svg";
import { ReactComponent as ServiceSetting } from "../../assets/images/work_space/services-svg-ubos.svg";
import { User } from "../../constants/userConstants";
import { AuditOutlined } from "@ant-design/icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  APPLICATIONS_URL,
  CLOSE_PROMOTIONAL_TEMPLATE,
  QUERY_KEY_TEMPLATE_ID,
} from "../../constants/routes";
import { FormDialogComponent } from "../../components/editorComponents/form/FormDialogComponent";
import { HeaderIcons } from "../../icons/HeaderIcons";
import { Colors } from "../../constants/Colors";
import AppInviteUsersForm from "../organization/AppInviteUsersForm";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../reducers";
import { Tooltip } from "@blueprintjs/core";
import { sendUserLogs } from "api/userLogs";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { CustomTitleTabElement } from "pages/Editor/DoclLayoutSettings";
import { getURLForCustomTitleTab } from "pages/Editor/MainContainer";

const SideNavigationWrapper = styled.div`
  height: calc(100% - 32px);
  position: fixed;
  top: 32px;
  left: 0px;
  z-index: 11;
  width: 48px;
  background: #ececec;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #d9d9d9;
  padding-bottom: 0px;

  svg {
    cursor: pointer;
  }
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    .wrapper_icon_nav {
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        svg {
          fill: #3b82ff;
          path {
            fill: #3b82ff;
          }
        }
      }

      svg {
        width: 23px;
        height: 23px;
        fill: #6c767e;
        path {
          fill: #6c767e;
        }
      }
    }

    .wrapper_icon_nav_row::after {
      content: "";
      display: block;
      border-bottom: 1.5px solid #6c767e;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 85%;
      border-radius: 10px;
    }

    .active {
      border-left: 2px solid #3b82ff;
      svg {
        fill: #3b82ff;
        path {
          fill: #3b82ff;
        }
      }
    }
    .active:hover {
      svg {
        width: 27px;
        height: 27px;
        fill: #6c767e;
        path {
          fill: #6c767e;
        }
      }
    }
    .user_setting {
      margin-top: auto;
    }
  }
`;
type PageHeaderProps = {
  openLeftMenu?: () => void;
  openUserPanel?: () => void;
  openTemplateManager?: any;
  openServiceSetting?: () => void;
  openWorkspaceSetting?: () => void;
  active: string;
  permission?: boolean;
  type_permission?: any;
  openAnalitic?: () => void;
  openAdmiPanel?: () => void;
  openUserSettings?: (e: any) => void;
  isOpenLeftMenu?: boolean;
};
export const SideNavigation = (props: PageHeaderProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const activeDockLayoutItem = document.querySelector(".dock-tabpane-active");

  const currentApplication = useSelector((state: AppState) => {
    return state.ui.applications?.currentApplication;
  });
  const currentUser = useSelector((state: AppState) => {
    return state.ui.users.currentUser;
  });

  const [activeMenuId, setActiveMenuId] = useState(null);

  useEffect(() => {
    if (activeDockLayoutItem) {
      const idToJson = JSON.parse(activeDockLayoutItem.id);
      setActiveMenuId(idToJson.id);
    } else {
      activeDockLayoutItem !== activeMenuId &&
        setActiveMenuId(activeDockLayoutItem);
    }
  }, [activeDockLayoutItem]);

  const handleOpenTemplateManager = () => {
    const templateId = searchParams.get(QUERY_KEY_TEMPLATE_ID);
    const closePromotionalTemplate = searchParams.get(
      CLOSE_PROMOTIONAL_TEMPLATE,
    );

    if (templateId) {
      searchParams.delete(QUERY_KEY_TEMPLATE_ID);

      if (closePromotionalTemplate !== null)
        searchParams.delete(CLOSE_PROMOTIONAL_TEMPLATE);

      const newSearch = searchParams.toString();

      history.push({
        search: newSearch,
      });
    }

    props.openTemplateManager();
  };
  const openLoad = () => {
    const newItem = {
      id: JSON.stringify({ id: "Logs", type: "Logs" }),
      component: "UserSettings",
      title: "Logs",
      props: { navigateTo: "Load" },
    };

    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };
  const openUserSetting = () => {
    const newItem = {
      id: JSON.stringify({ id: "User Settings", type: "UserSettings" }),
      component: "UserSettings",
      title: "User Settings",
      props: { navigateTo: "General" },
    };

    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const openTemplateManager = () => {
    const newItem = {
      id: JSON.stringify({
        id: "TemplateManagerV2",
        type: "TemplateManagerV2",
      }),
      component: "TemplateManagerV2",
      props: {
        url: "https://ubos.tech/templates",
        title: "Asset Marketplace",
      },
    };

    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const handleOpenLeaveWorkspaceModal = () => {
    dispatch({
      type: ReduxActionTypes.UPD_USER_MODAL,
      payload: {
        leaveWorkspaceModal: {
          isOpen: true,
        },
      },
    });
  };

  return (
    <>
      {window.location.pathname !== "/workspace" && (
        <SideNavigationWrapper>
          <>
            <div className="nav">
              <div
                className={`wrapper_icon_nav home-tour-step`}
                onClick={handleOpenLeaveWorkspaceModal}
              >
                <Tooltip content={"Home"} usePortal={false}>
                  {/* <Link to={APPLICATIONS_URL}> */}
                  <HomeIcon></HomeIcon>
                  {/* </Link> */}
                </Tooltip>
              </div>
              <div
                style={{ position: "relative" }}
                className={`wrapper_icon_nav wrapper_icon_nav_row services-tour-step`}
                onClick={props.openLeftMenu}
              >
                <Tooltip content={"Services"} usePortal={false}>
                  {props.isOpenLeftMenu ? <Hide></Hide> : <Show></Show>}
                </Tooltip>
              </div>
              {/* <div
                className={`wrapper_icon_nav ${props.active == "EDIT" &&
                  "active"}`}
                onClick={props.openAnalitic}
                >
                  <Tooltip content={"services"}
                    usePortal={false}>
                    <Info></Info>
                  </Tooltip>

                </div> */}

              {currentUser?.user_type == "admin" ? (
                <>
                  <div
                    className={`wrapper_icon_nav ${activeMenuId ===
                      "DeploymenManager" && "active"}`}
                    onClick={props.openUserPanel}
                  >
                    <Tooltip content={"Admin Panel"} usePortal={false}>
                      <AdminIcon></AdminIcon>
                    </Tooltip>
                  </div>

                  <div
                    className={`wrapper_icon_nav ${activeMenuId ===
                      "AdminPanel" && "active"}`}
                    onClick={props.openAdmiPanel}
                  >
                    <Tooltip content={"System Services"} usePortal={false}>
                      <AdminIcon2></AdminIcon2>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <></>
              )}
              {props.type_permission.some(
                el =>
                  el == "manage:workspace" ||
                  el == "manage:organizations" ||
                  el == "manage:services",
              ) ? (
                <>
                  <div
                    className={`wrapper_icon_nav ${activeMenuId ===
                      "TemplateManager" &&
                      "active"} asset-marketplace-tour-step`}
                    onClick={openTemplateManager}
                  >
                    <Tooltip content={"Asset Marketplace"} usePortal={false}>
                      <TemplateIcon></TemplateIcon>
                    </Tooltip>
                  </div>
                </>
              ) : (
                <></>
              )}

              {props.permission ? (
                <div
                  className={`wrapper_icon_nav ${activeMenuId ===
                    "WorkspaceSettings" &&
                    "active"} workspace-settings-tour-step`}
                  onClick={props.openWorkspaceSetting}
                >
                  <Tooltip content={"Workspace Setting"} usePortal={false}>
                    <WorkspaceIcon></WorkspaceIcon>
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}
              {props.permission ? (
                <div
                  className={`wrapper_icon_nav ${activeMenuId === "Logs" &&
                    "active"} logs-tour-step`}
                  onClick={openLoad}
                >
                  <Tooltip content={"Logs"} usePortal={false}>
                    <Monitor></Monitor>
                  </Tooltip>
                </div>
              ) : (
                <></>
              )}

              <div
                className={`wrapper_icon_nav ${(activeMenuId ==
                  "User Settings" ||
                  activeMenuId == "Credits") &&
                  "active"} user_setting user-setting-tour-step`}
                onClick={openUserSetting}
              >
                <Tooltip content={"User Settings"} usePortal={false}>
                  <UserIcon></UserIcon>
                </Tooltip>
              </div>
            </div>
          </>
        </SideNavigationWrapper>
      )}
    </>
  );
};

export default SideNavigation;
