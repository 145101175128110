import styled from "styled-components";
import { Colors } from "constants/Colors";

export const EntityTogglesWrapper = styled.div`
  &&& {
    width: 100%;
    height: 100%;
    font-size: ${props => props.theme.fontSizes[4]}px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #dfdfdf;
    svg,
    svg path {
      // fill: #DFDFDF;
    }

    &:hover {
      background: ${Colors.WHITE};
    }
  }
`;
