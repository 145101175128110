import axios from "axios";

export const BASE = "https://nodedev2.ubos.tech";
const auth = "/auth/login";
const token = "/auth/token";
const flows = "/flows?_=1617029625174";

export function authLogin() {
  return axios.get(BASE + auth);
}

export function getFlows(base: string, token = "") {
  return axios.get(base + flows, getConfigWithPath(token));
}

export function login() {
  return axios.post(BASE + token, {
    client_id: "node-red-editor",
    grant_type: "password",
    scope: "*",
    username: "ubdev",
    password: "kQVcb2WJyv2TBe9e",
  });
}

function getConfigWithPath(token: string) {
  const config = {
    headers: {
      authorization: "Bearer " + token,
    },
  };
  return config;
}
