import React, { useState, useEffect, useRef } from "react";
import { Tour, TourProps } from "antd";

const LeftSidebarTour = (props: ILeftSidebarTour) => {
  const refs = {
    homeIcon: useRef<any>(null),
    servicesIcon: useRef<any>(null),
    assetMarketplaceIcon: useRef<any>(null),
    workspaceSettingsIcon: useRef<any>(null),
    logsIcon: useRef<any>(null),
    userSettingsIcon: useRef<any>(null),
  };

  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    refs.homeIcon.current = document.querySelector(".home-tour-step");
    refs.servicesIcon.current = document.querySelector(".services-tour-step");
    refs.assetMarketplaceIcon.current = document.querySelector(
      ".asset-marketplace-tour-step",
    );
    refs.workspaceSettingsIcon.current = document.querySelector(
      ".workspace-settings-tour-step",
    );
    refs.logsIcon.current = document.querySelector(".logs-tour-step");
    refs.userSettingsIcon.current = document.querySelector(
      ".user-setting-tour-step",
    );
  }, []);

  const handleClickNextStep = () => {
    setCurrentStep(prevState => prevState + 1);
  };

  const handleClickBackStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  const steps: TourProps["steps"] = [
    {
      placement: "rightBottom",
      title: "Home",
      description: `You exit from the current workspace to the page with the selection and creation of a workspace`,
      cover: (
        <img src={props.additionalProps.gifs[currentStep]} alt="Home tour" />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refs.homeIcon.current,
    },
    {
      placement: "rightBottom",
      title: "Services",
      description: `You can hide or show the list of services`,
      cover: (
        <img
          src={props.additionalProps.gifs[currentStep]}
          alt="Services tour"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refs.servicesIcon.current,
    },
    {
      placement: "rightBottom",
      title: "Asset Marketplace",
      description: `Asset Marketplace is a repository of reusable, templates, app
        blueprints, connectors, UI components, tools and business solutions
        to help you swiftly kickstart your product or solution on the UBOS
        platform`,
      cover: (
        <img
          src={props.additionalProps.gifs[currentStep]}
          alt="How to clone template"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refs.assetMarketplaceIcon.current,
    },
    {
      placement: "rightBottom",
      title: "Workspace Settings",
      description: `UBOS offers you a versatile platform where you can create multiple workspaces, each of which corresponds to a separate AWS server. Within these workspaces, all services operate without predefined limitations, allowing you the freedom to configure and optimize them according to your specific requirements. This means that, by default, there are no constraints on the resources or capabilities of the services you deploy within a workspace`,
      cover: (
        <img
          src={props.additionalProps.gifs[currentStep]}
          alt="Workspace Settings tour"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refs.workspaceSettingsIcon.current,
    },
    {
      placement: "right",
      title: "Logs",
      description: `This tab displays the process of creating services`,
      cover: (
        <img src={props.additionalProps.gifs[currentStep]} alt="Logs tour" />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refs.logsIcon.current,
    },
    {
      placement: "rightTop",
      title: "User Settings",
      description: `UBOS offers you a versatile platform where you can create multiple workspaces, each of which corresponds to a separate AWS server. Within these workspaces, all services operate without predefined limitations, allowing you the freedom to configure and optimize them according to your specific requirements. This means that, by default, there are no constraints on the resources or capabilities of the services you deploy within a workspace`,
      cover: (
        <img
          src={props.additionalProps.gifs[currentStep]}
          alt="User Settings tour"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: () => props.handleFinishTour(),
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      target: () => refs.userSettingsIcon.current,
    },
  ];

  return (
    <Tour
      open={true}
      steps={steps}
      current={currentStep}
      onFinish={() => props.handleFinishTour()}
    />
  );
};

interface ILeftSidebarTour {
  handleFinishTour: () => void;
  additionalProps?: any;
}

export default LeftSidebarTour;
