import axios from "axios";
import { Crumbs } from "../FileManagerComponent";

// export const url = "https://test.tadiran-group.co.il/";

const Auth = {
  signInToken: "auth/admin/sign-in-token/",
};
const baseservice = "/api/helm_service";
const baseUI = "/api/UiManager/ui";
const BaseNodeRed = "/nodeRedManager/";
const BaseNodeRedLogs = "/nodeRedLogs/";
// const BaseNodeRed = "https://nodred.ubraine.top/";
// const BaseNodeRed = "https://brua-jewellery-nr.ubos.tech/";
const ApiImage = {
  list: "fm/list/image",
  rename: "fm/rename/image",
  remove: "fm/remove/image",
  upload: "fm/upload/image",
  newFolder: "fm/dir-create/image",
};

export enum legacyFileTypes {
  CSV = ".csv",
  PDF = ".pdf",
  FILE = ".pdf .csv",
  Image = ".png, .jpg, .jpeg",
  JSON = ".JSON",
}

const ApiFile = {
  list: "fm/list/file",
  rename: "fm/rename/file",
  remove: "fm/remove/file",
  upload: "fm/upload/file",
  newFolder: "fm/dir-create/file",
};

export const getToken = () => {
  return axios.post(
    "/login",
    {},
    {
      headers: {
        Authorization: "Basic dWJyYWluZTprUVZjYjJXSnl2MlRCZTll",
        username: "test",
        password: "132465798",
      },
    },
  );
};
export const getRealease = (body: any) => {
  return axios.post(baseUI + "_realise", body);
};
export const getUiTags = () => {
  return axios.get(baseUI + "_uiTags");
};

export const createUiApi = (body: any) => {
  return axios.post(baseUI, body);
};
export const updateUiEnvApi = (body: any) => {
  return axios.put(baseUI + "_env", body);
};
export const updateNodeRedEnvApi = (body: any) => {
  return axios.put("/api/updateEnvNodeRed", body);
};

export const deleteUi = (body: any) => {
  return axios.put(baseUI, body);
};

export const deleteHelmService = (body: any) => {
  return axios.delete(baseservice, { data: body });
};

export const createNodeRed = (body: any, token: string) => {
  return axios.post(BaseNodeRed + "createNodeRed", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getTypeSystemNodeRed = (token: string) => {
  return axios.get(BaseNodeRed + "getNodeRedImages", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const getFlowNodeRed = () => {
  return axios.get(BaseNodeRedLogs + "ubos/api/flows");
};
export const getExecutionsNodeRed = () => {
  return axios.get(BaseNodeRedLogs + "ubos-api/all-executions");
};
export const getTest = (token: string) => {
  return axios.get(BaseNodeRed + "testget", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const createUiTemplate = (token: string, data: any) => {
  return axios.post(BaseNodeRed + "ui_template/create", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const getUiTemplateList = (token: string, data: any) => {
  return axios.post(BaseNodeRed + "ui_template/list", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const deleteUiTemplateList = (token: string, data: any) => {
  return axios.post(BaseNodeRed + "ui_template/delete", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const verifyNodeRed = (token: string) => {
  return axios.get(BaseNodeRed + "settings", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getSettingsNodeRed = (body: any, token: string) => {
  return axios.post(BaseNodeRed + "getServiceListById", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const deleteNodeRedPost = (body: any, token: string) => {
  return axios.post(BaseNodeRed + "deleteService", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const checkNodeRed = (body: any, token: string) => {
  return axios.post(BaseNodeRed + "checkNodeRed", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const restartNodeRedApi = (body: any, token: string) => {
  return axios.post("/api/v1/" + "restartNodeRed", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export const restartUiApi = (body: any, token: string) => {
  return axios.post("/api/v1/" + "restartUi", body, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const importJson = (body: any) => {
  return axios.post("/api/v1/" + "import_json", body);
};

export const getServiceInfo = (token: string) => {
  return axios.post(
    "/noderedmanager/getServiceInfo",
    {},
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    },
  );
};

export const getList = (token: string, type: boolean, path = "", url = "") => {
  return axios.get(
    url + (type ? ApiImage.list : ApiFile.list),
    getConfigWithPath(token, path),
  );
};

export const renameApi = (
  token: string,
  type: boolean,
  path = "",
  data: any,
  url = "",
) => {
  return axios.put(
    url + (type ? ApiImage.rename : ApiFile.rename),
    data,
    getConfigWithPath(token, path),
  );
};

export const deleteApi = (
  token: string,
  type: boolean,
  path = "",
  data: [],
  url = "",
) => {
  return axios.put(
    url + (type ? ApiImage.remove : ApiFile.remove),
    { payload: data },
    getConfigWithPath(token, path),
  );
};

export const uploadApi = (
  token: string,
  type: boolean,
  path = "",
  data: any,
  url = "",
) => {
  return axios.post(
    url + (type ? ApiImage.upload : ApiFile.upload),
    data,
    getConfigWithPath(token, path),
  );
};

export const uploadCustomFileApi = (url = "", data: any, token?: string) => {
  if (token) {
    return axios.post(url, data, getConfig(token));
  } else {
    return axios.post(url, data);
  }
};

export function updateLayoutSettings(id: string, data: any) {
  return axios.put(`v1/applications/${id}/layout_setting`, data);
}

export const newFolder = (
  token: string,
  type: boolean,
  path = "",
  url = "",
) => {
  return axios.put(
    url + (type ? ApiImage.newFolder : ApiFile.newFolder),
    getConfigWithPath(token, path),
  );
};

export const signInAuth = (token: string, api: string) => {
  return axios.get(api, getConfig(token));
};

function getConfigWithPath(token: string, path = "") {
  const config = {
    headers: {
      authorization: token,
    },
    params: {
      path: path,
    },
  };
  return config;
}

function getConfig(token: string, path = "") {
  const config = {
    headers: {
      authorization: token,
    },
  };
  return config;
}

export function getPath(crumbs: Crumbs[]) {
  const a = crumbs.map(item => item.name).join("/");
  return a;
}
