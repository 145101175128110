import React from "react";
import { Help } from "./JSDependencies";
import { Colors } from "../../../constants/Colors";
import { ReactComponent as _OpenServiceIcon } from "../../../assets/icons/control/rocket.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/control/delete.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/control/copy.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/control/info-circle-icon.svg";
import styled from "styled-components";

const OpenServiceIconStyled = styled(_OpenServiceIcon)`
  width: 20px;
`;
const DeleteIconStyled = styled(DeleteIcon)`
  color: red;
  path {
    fill: #ff6464;
  }
`;
const CopyIconStyled = styled(CopyIcon)`
  width: 20px;
`;
const HelpIconStyled = styled.div`
  span {
    width: 20px !important;

    svg {
      &:hover {
        fill: #3573e0 !important;
      }
    }
  }
  span > svg > path {
    fill: #3573e0 !important;
  }
  /* span > svg {
    &:hover {
      fill: #3573e0 !important;
    }
  } */
`;

interface IActionButton {
  onClick?: (item: any) => void;
}

interface ISettingsServiceIcon extends IActionButton {
  iconSize?: number;
}

interface IDeleteServiceIcon extends IActionButton {
  iconSize?: number;
}

export const OpenServiceIcon = ({ onClick }: IActionButton) => {
  return <OpenServiceIconStyled onClick={onClick}></OpenServiceIconStyled>;
};

export const SettingsServiceIcon = ({ onClick }: IActionButton) => {
  return <InfoIcon onClick={onClick}></InfoIcon>;
};
// export const SettingsServiceIcon = ({
//   onClick,
//   iconSize,
// }: ISettingsServiceIcon) => {
//   return (
//     <HelpIconStyled>
//       <Help
//         onClick={onClick}
//         icon="info-sign"
//         iconSize={iconSize}
//         color={Colors.DOVE_GRAY}
//       />
//     </HelpIconStyled>
//   );
// };

export const CopyServiceIcon = () => {
  return <CopyIconStyled></CopyIconStyled>;
};

export const DeleteServiceIcon = ({
  onClick,
  iconSize = 10,
}: IDeleteServiceIcon) => {
  return (
    <DeleteIconStyled
      height={iconSize}
      width={iconSize}
      onClick={onClick}
    ></DeleteIconStyled>
  );
};
