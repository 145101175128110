import { Button, Icon, Overlay } from "@blueprintjs/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";
import {
  createNodeRed,
  getList,
  getTypeSystemNodeRed,
} from "../../../../components/designSystems/appsmith/help/FileManagerApi";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../reducers";
import { ReduxActionTypes } from "../../../../constants/ReduxActionConstants";
import { storeAsDatasourceApi } from "../../../../actions/datasourceActions";
import ClickAwayListener from "react-click-away-listener";
import { CloseIcon, WrapperModal } from "./ModalNodeRed";

export const WrapperItemInput = styled.div`
  margin-top: 20px;
  width: 500px;
  input {
    width: 100%;
  }
`;
export const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IframeModal = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      if (prev) {
        setName("");
        setUrl("");
      }
      return !prev;
    });
  }, [isOpen, setIsOpen]);

  const createIframe = useCallback(() => {
    if (currentApplication?.global_setting.iframe) {
      dispatch({
        type: ReduxActionTypes.UPDATE_GLOBAL_APPLICATION_SETTINGS,
        payload: {
          id: currentApplication?.id,
          name: "iframe",
          value: [
            ...currentApplication?.global_setting.iframe,
            {
              id: "nodeRedId",
              title: name,
              component: "nodeRed",
              props: url,
            },
          ],
        },
      });
    }
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: "nodeRedId",
        title: name,
        component: "nodeRed",
        props: url,
      },
    });
    toggleOverlay();
  }, [name, url]);

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
  }));
  return (
    <div>
      {isOpen ? (
        <WrapperModal>
          <ClickAwayListener onClickAway={toggleOverlay}>
            <div>
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <div style={{ padding: "40px", background: "white" }}>
                  <div className="title">New Iframe</div>
                  <CloseIcon>
                    <Icon
                      onClick={toggleOverlay}
                      iconSize={16}
                      color={"black"}
                      icon="cross"
                    />
                  </CloseIcon>
                  <WrapperItemInput>
                    <span className="title_input">Iframe name</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="Enter Iframe name"
                      />
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <span className="title_input">Iframe Url</span>
                    <div>
                      <input
                        type="text"
                        className="bp3-input"
                        placeholder="Enter Url Iframe"
                        value={url}
                        onChange={e => setUrl(e.target.value)}
                      />
                    </div>
                  </WrapperItemInput>
                  <WrapperItemInput>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className="button"
                        onClick={createIframe}
                        disabled={url.length === 0 && name.length === 0}
                      >
                        Create Iframe
                      </button>
                    </div>
                  </WrapperItemInput>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </WrapperModal>
      ) : (
        ""
      )}
    </div>
  );
});
IframeModal.displayName = "IframeModal";
