"use strict";
import React, { ReactNode, ReactElement, useEffect, useState } from "react";
// import * as React from 'react'
import { Link, NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import StyledHeader from "components/designSystems/appsmith/StyledHeader";
import AppsmithLogo from "assets/images/appsmith_logo_white.png";
import Button from "components/editorComponents/Button";
import { EDIT_APP, FORK_APP, SIGN_IN } from "constants/messages";
import {
  isPermitted,
  PERMISSION_TYPE,
} from "pages/Applications/permissionHelpers";
import {
  ApplicationPayload,
  PageListPayload,
  ReduxActionTypes,
} from "constants/ReduxActionConstants";
import {
  APPLICATIONS_URL,
  AUTH_LOGIN_URL,
  getApplicationViewerPageURL,
  SIGN_UP_URL,
} from "constants/routes";
import { connect } from "react-redux";
import { AppState } from "reducers";
import { getEditorURL } from "selectors/appViewSelectors";
import { getPageList, getCurrentPageName } from "selectors/editorSelectors";
import { FormDialogComponent } from "components/editorComponents/form/FormDialogComponent";
import AppInviteUsersForm from "pages/organization/AppInviteUsersForm";
import { getCurrentOrgId } from "selectors/organizationSelectors";
import { HeaderIcons } from "icons/HeaderIcons";
import { Colors } from "constants/Colors";
import { getCurrentUser } from "selectors/usersSelectors";
import { ANONYMOUS_USERNAME, User } from "constants/userConstants";

import { ShellBar } from "@ui5/webcomponents-react/lib/ShellBar";
import { ShellBarItem } from "@ui5/webcomponents-react/lib/ShellBarItem";
import { Avatar } from "@ui5/webcomponents-react/lib/Avatar";
import { StandardListItem } from "@ui5/webcomponents-react/lib/StandardListItem";
import { ListItemTypes } from "@ui5/webcomponents-react/lib/ListItemTypes";
import { ThemeProvider } from "@ui5/webcomponents-react/lib/ThemeProvider";
import { AvatarBackgroundColor } from "@ui5/webcomponents-react/lib/AvatarBackgroundColor";
import { AvatarFitType } from "@ui5/webcomponents-react/lib/AvatarFitType";
import { AvatarShape } from "@ui5/webcomponents-react/lib/AvatarShape";
import { AvatarSize } from "@ui5/webcomponents-react/lib/AvatarSize";
import "@ui5/webcomponents-icons/dist/Assets.js";
import { Link as LinkSap } from "@ui5/webcomponents-react/lib/Link";
import { getParams } from "../AppPage";
import {
  DropdownOnSelectActions,
  getOnSelectAction,
} from "../../common/CustomizedDropdown/dropdownHelpers";
import MenuItem from "../../../components/ads/MenuItem";

// const HeaderWrapper = styled(StyledHeader)<{ hasPages: boolean }>`
//   background: ${Colors.BALTIC_SEA};
//   height: ${props => (props.hasPages ? "90px" : "48px")};
//   color: white;
//   flex-direction: column;
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
// `;

// const HeaderRow = styled.div<{ justify: string }>`
//   width: 100%;
//   display: flex;
//   flex: 1;
//   flex-direction: row;
//   justify-content: ${props => props.justify};
// `;

// const HeaderSection = styled.div<{ justify: string }>`
//   display: flex;
//   flex: 1;
//   align-items: center;
//   justify-content: ${props => props.justify};
// `;

// const AppsmithLogoImg = styled.img`
//   max-width: 110px;
// `;

// const BackToEditorButton = styled(Button)`
//   max-width: 200px;
//   height: 32px;
//   margin: 5px 10px;
// `;

// const ForkButton = styled(Button)`
//   max-width: 200px;
//   height: 32px;
//   margin: 5px 10px;
//   svg {
//     transform: rotate(-90deg);
//   }
// `;

// const ShareButton = styled(Button)`
//   height: 32px;
//   margin: 5px 10px;
//   color: white !important;
// `;

// const StyledApplicationName = styled.span`
//   font-size: 15px;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 14px;
// `;

// const PageTab = styled(NavLink)`
//   display: flex;
//   height: 30px;
//   max-width: 170px;
//   margin-right: 1px;
//   align-self: flex-end;
//   cursor: pointer;
//   align-items: center;
//   justify-content: center;
//   text-decoration: none;
//   background-color: rgb(49, 48, 51);
//   padding: 0px 10px;
//   && span {
//     font-weight: 500;
//     font-size: 12px;
//     line-height: 20px;
//     letter-spacing: 0.04em;
//     color: #fff;
//     max-width: 150px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
//   &&&:hover {
//     text-decoration: none;
//     background-color: #fff;
//     span {
//       color: #2e3d49;
//     }
//   }
//   &&&.is-active {
//     background-color: white;
//     span {
//       color: #2e3d49;
//     }
//   }
// `;

type AppViewerHeaderProps = {
  url?: string;
  currentApplicationDetails?: ApplicationPayload;
  pages: PageListPayload;
  currentOrgId: string;
  currentUser?: User;
  currentPageName?: string;
  user: any;
};

function openPage(item: any) {
  if (item && item.detail["item"]) {
    const _menu_item = item.detail["item"] as HTMLElement;
    const _menu_item_href = _menu_item.getAttribute("data-key");
    if (_menu_item_href) window.open(_menu_item_href, "_self");
  }
}

export const AppViewerHeader = (props: AppViewerHeaderProps) => {
  const [isApplicationDetails, setIsApplicationDetails] = useState(true);
  const {
    currentApplicationDetails,
    pages,
    currentOrgId,
    currentPageName,
  } = props;
  const isExampleApp = currentApplicationDetails?.appIsExample;
  const userPermissions = currentApplicationDetails?.userPermissions ?? [];
  const permissionRequired = PERMISSION_TYPE.MANAGE_APPLICATION;
  const canEdit = isPermitted(userPermissions, permissionRequired);
  // Mark default page as first page
  const appPages = pages;
  if (appPages.length > 1) {
    appPages.forEach(function(item, i) {
      if (item.isDefault) {
        appPages.splice(i, 1);
        appPages.unshift(item);
      }
    });
  }
  useEffect(() => {
    // tadiran functionality
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // import("../../../custom.css");
  }, []);
  useEffect(() => {
    if (isApplicationDetails) {
      if (
        currentApplicationDetails &&
        currentApplicationDetails?.global_setting?.isMenu
      ) {
        setIsApplicationDetails(false);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        import("../../../addMenu.css");
      }
    }
  }, [currentApplicationDetails, isApplicationDetails]);

  const forkAppUrl = `${window.location.origin}${SIGN_UP_URL}?appId=${currentApplicationDetails?.id}`;
  const loginAppUrl = `${window.location.origin}${AUTH_LOGIN_URL}?appId=${currentApplicationDetails?.id}`;

  // let CTA = null;

  // if (props.url && canEdit) {
  //   CTA = (
  //     <BackToEditorButton
  //       className="t--back-to-editor"
  //       href={props.url}
  //       intent="primary"
  //       icon="arrow-left"
  //       iconAlignment="left"
  //       text={EDIT_APP}
  //       filled
  //     />
  //   );
  // } else if (isExampleApp) {
  //   CTA = (
  //     <ForkButton
  //       className="t--fork-app"
  //       href={forkAppUrl}
  //       intent="primary"
  //       icon="fork"
  //       iconAlignment="left"
  //       text={FORK_APP}
  //       filled
  //     />
  //   );
  // }

  return (
    <ThemeProvider>
      <ShellBar
        logo={
          <img
            alt="SAP Logo"
            src="https://ubraine.com/wp-content/themes/ubraine/assets/img/logo_white.svg"
          />
        }
        // menuItems={appPages.map(page => (
        //   <StandardListItem
        //     selected={page.isDefault}
        //     data-key={getApplicationViewerPageURL(
        //       currentApplicationDetails?.id,
        //       page.pageId,
        //     )}
        //     type={ListItemTypes.Active}
        //   >
        //     {page.pageName}
        //   </StandardListItem>
        // ))}
        notificationCount={"10"}
        // onCoPilotClick={function noRefCheck() {}}
        // onLogoClick={function noRefCheck() {}}
        // onMenuItemClick={item => {
        //   openPage(item);
        // }}
        // onNotificationsClick={function noRefCheck() {}}
        // onProductSwitchClick={function noRefCheck() {}}
        // onProfileClick={function noRefCheck() {}}
        primaryTitle={currentPageName}
        profile={
          <Avatar
            backgroundColor={AvatarBackgroundColor.Accent6}
            image="https://sap.github.io/ui5-webcomponents-react/static/media/DemoImage.4b12bcf0.png"
            imageFitType={AvatarFitType.Contain}
            shape={AvatarShape.Circle}
            size={AvatarSize.S}
          />
        }
        searchField={null}
        //secondaryTitle="Fiori 3 Shell Bar"
        showCoPilot
        showNotifications
        showProductSwitch
        startButton={null}
      >
        {!props.user.user_type && (
          <ShellBarItem
            icon="edit"
            text="ShellBarItem"
            onItemClick={() => {
              window.open(props.url, "_self");
            }}
          />
        )}
        {props.user.user_type === "ENDUSER" && (
          <ShellBarItem
            icon="visits"
            text="ShellBarItem"
            onItemClick={() =>
              getOnSelectAction(DropdownOnSelectActions.DISPATCH, {
                type: ReduxActionTypes.LOGOUT_USER_INIT,
              })
            }
          />
        )}
      </ShellBar>
    </ThemeProvider>
  );
  // return (

  //   <HeaderWrapper hasPages={pages.length > 1}>
  //     <HeaderRow justify={"space-between"}>
  //       <HeaderSection justify={"flex-start"}>
  //         <Link to={APPLICATIONS_URL}>
  //           <AppsmithLogoImg src={AppsmithLogo} alt="Appsmith logo" />
  //         </Link>
  //       </HeaderSection>
  //       <HeaderSection justify={"center"}>
  //         {currentApplicationDetails && (
  //           <StyledApplicationName>
  //             {currentApplicationDetails.name}
  //           </StyledApplicationName>
  //         )}
  //       </HeaderSection>
  //       <HeaderSection justify={"flex-end"}>
  //         {currentApplicationDetails && (
  //           <>
  //             <FormDialogComponent
  //               trigger={
  //                 <ShareButton
  //                   text="Share"
  //                   intent="none"
  //                   outline
  //                   size="small"
  //                   className="t--application-share-btn"
  //                   icon={
  //                     <HeaderIcons.SHARE
  //                       color={Colors.WHITE}
  //                       width={13}
  //                       height={13}
  //                     />
  //                   }
  //                 />
  //               }
  //               Form={AppInviteUsersForm}
  //               orgId={currentOrgId}
  //               applicationId={currentApplicationDetails.id}
  //               title={currentApplicationDetails.name}
  //             />
  //             {CTA}
  //           </>
  //         )}
  //       </HeaderSection>
  //     </HeaderRow>
  //     {appPages.length > 1 && (
  //       <HeaderRow justify={"flex-start"}>
  //         {appPages.map(page => (
  //           <PageTab
  //             key={page.pageId}
  //             to={getApplicationViewerPageURL(
  //               currentApplicationDetails?.id,
  //               page.pageId,
  //             )}
  //             activeClassName="is-active"
  //           >
  //             <span>{page.pageName}</span>
  //           </PageTab>
  //         ))}
  //       </HeaderRow>
  //     )}
  //   </HeaderWrapper>
  // );
};

const mapStateToProps = (state: AppState): AppViewerHeaderProps => ({
  pages: getPageList(state),
  url: getEditorURL(state),
  currentApplicationDetails: state.ui.applications.currentApplication,
  currentOrgId: getCurrentOrgId(state),
  currentUser: getCurrentUser(state),
  currentPageName: getCurrentPageName(state),
  user: state.entities.app.user,
});

export default connect(mapStateToProps)(AppViewerHeader);
