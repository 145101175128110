import _ from "lodash";
import { createReducer } from "utils/AppsmithUtils";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "constants/ReduxActionConstants";

import { DefaultCurrentUserDetails, User } from "constants/userConstants";

const initialState: UsersReduxState = {
  loadingStates: {
    fetchingUsers: false,
    fetchingUser: false,
  },
  list: [],
  users: [],
  error: "",
  current: undefined,
  currentUser: undefined,
};

const usersReducer = createReducer(initialState, {
  [ReduxActionTypes.FETCH_USER_INIT]: (state: UsersReduxState) => ({
    ...state,
    loadingStates: {
      ...state.loadingStates,
      fetchingUser: true,
    },
  }),
  [ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    const users = [...state.users];
    const userIndex = _.findIndex(users, { username: action.payload.username });
    if (userIndex > -1) {
      users[userIndex] = action.payload;
    } else {
      users.push(action.payload);
    }

    const data = action.payload;

    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        fetchingUser: false,
      },
      users,
      currentUser: data,
      allTours: data.tours,
      modals: {
        introductoryModal: {
          isOpen: !data?.introductoryWindow,
        },
        // installFirstTemplateModal: {
        //   isOpen:
        //     !data.tours?.installFirstTemplateModal &&
        //     !data.tours?.discountTemplate,
        // },
        openTemplateModal: {
          isOpen: false,
        },
        workspaceMigrationModal: {
          isOpen: true,
        },
        leaveWorkspaceModal: { isOpen: false },
      },
      workspaceTour: {
        createWorkspace: {
          isOpen: !data.tours?.createWorkspace,
        },
        openWorkspace: {
          isOpen: !data.tours?.openWorkspace,
        },
      },
      userTours: {
        initialTour: {
          isOpen: !data.tours?.initialTour,
        },
        howToOpenServiceOrTemplate: {
          isOpen: false,
        },
        chargeBalance: {
          isOpen: data.tours?.chargeBalance?.completed === false,
          label: "How to charge balance",
        },
        howToCreateService: {
          isOpen: false,
          label: "How to create service",
        },
        openService: {
          isOpen: !data.tours?.openService,
          label: "How to open service",
        },
        howToCloneTemplate: {
          isOpen: false,
          label: "How to clone template",
        },
        leftSidebar: {
          isOpen: false,
          label: "Left sidebar",
        },
      },
    };
  },

  [ReduxActionTypes.UPD_USER_MODAL]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      modals: { ...state.modals, ...action.payload },
    };
  },

  [ReduxActionTypes.UPD_USER_TOUR]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      userTours: { ...state.userTours, ...action.payload },
    };
  },

  [ReduxActionTypes.UPD_USER_TOUR_OPEN_SERVICE]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      allTours: { ...state.allTours, ...action.payload },
    };
  },

  // [ReduxActionTypes.UPD_USER_TOUR]: (
  //   state: UsersReduxState,
  //   action: ReduxAction<User>,
  // ) => {
  //   return {
  //     ...state,
  //     tours: { ...state.tours, ...action.payload },
  //   };
  // },

  [ReduxActionTypes.GET_USER_BALANCE_SUCCESS]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      userBalance: action.payload,
    };
  },
  [ReduxActionTypes.SAVE_ADDITIONAL_INFO_FROM_DATING_MODAL]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        introductoryWindow: true,
      },
    };
  },
  [ReduxActionTypes.FETCH_USER_SUCCESS]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => {
    const users = [...state.list];
    const userIndex = _.findIndex(users, { username: action.payload.username });
    if (userIndex > -1) {
      users[userIndex] = action.payload;
    } else {
      users.push(action.payload);
    }
    return {
      ...state,
      loadingStates: {
        ...state.loadingStates,
        fetchingUser: false,
      },
      list: users,
    };
  },
  [ReduxActionErrorTypes.FETCH_USER_DETAILS_ERROR]: (
    state: UsersReduxState,
    action: ReduxAction<{ error: string }>,
  ) => ({
    ...initialState,
    error: action.payload.error,
  }),
  [ReduxActionErrorTypes.FETCH_USER_ERROR]: (state: UsersReduxState) => ({
    ...state,
    loadingStates: { ...state.loadingStates, fetchingUser: false },
  }),
  [ReduxActionTypes.SET_CURRENT_USER_SUCCESS]: (
    state: UsersReduxState,
    action: ReduxAction<User>,
  ) => ({
    ...state,
    current: action.payload,
  }),
  [ReduxActionTypes.LOGOUT_USER_SUCCESS]: (state: UsersReduxState) => ({
    ...state,
    current: undefined,
    currentUser: DefaultCurrentUserDetails,
    users: [DefaultCurrentUserDetails],
  }),
});

export interface UsersReduxState {
  current?: User;
  list: User[];
  loadingStates: {
    fetchingUser: boolean;
    fetchingUsers: boolean;
  };
  users: User[];
  currentUser?: User;
  error: string;
}

export default usersReducer;
