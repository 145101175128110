import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Skeleton } from "antd";
import {
  getCurrentUser,
  getUserBalance as getCurrentUserSelector,
  getUserTours,
} from "selectors/usersSelectors";
import styled, { keyframes } from "styled-components";
import { ReactComponent as StorageIcon } from "../../assets/images/logo/fullLogo.svg";
import { ReactComponent as DiscordIcon } from "../../assets/images/work_space/icon-discord.svg";
import IconUP from "..//..//icons/iconup.svg";
import StyledHeader from "components/designSystems/appsmith/StyledHeader";
import { AppState } from "reducers";
import { User, ANONYMOUS_USERNAME } from "constants/userConstants";
import {
  APPLICATIONS_URL,
  AUTH_LOGIN_URL,
  BUILDER_PAGE_URL,
} from "constants/routes";
import Button from "components/editorComponents/Button";
import history from "utils/history";
import ProfileDropdown from "./ProfileDropdown";
import { ReactComponent as Arrow } from "../../assets/images/work_space/arrow-header.svg";
import {
  getApplicationList,
  getAppMode,
  getIsSavingAppName,
} from "../../selectors/applicationSelectors";
import {
  ApplicationPayload,
  ReduxActionTypes,
} from "../../constants/ReduxActionConstants";
import {
  getCurrentApplicationId,
  getCurrentPageId,
} from "../../selectors/editorSelectors";
import { updateApplication } from "../../actions/applicationActions";
import ApplicationApi from "../../api/ApplicationApi";
import { importJson } from "../../components/designSystems/appsmith/help/FileManagerApi";
import { promotionalTemplateInfo } from "constants/templates";
import { Timer } from "pages/Editor/MainContainer";
import {
  getCurrentOrg,
  getCurrentOrgServerInfo,
  getCurrentOrgServerStatus,
} from "selectors/organizationSelectors";
import InfoList from "./InfoList";
import { makeData } from "pages/AppViewer/viewer/TableWorkspace";
import {
  createIdTemplateManager,
  createIdUserQuest,
} from "pages/Editor/DoclLayoutSettings";
import { ReactComponent as GameIcon } from "../../assets/icons/control/game-logo.svg";
import Axios from "axios";
import { Link } from "react-router-dom";

import { Badge } from "antd";
import { Modal } from "antd";
import { Card } from "antd";
import { Col, Row } from "antd";
import { Typography } from "antd";
const { Title } = Typography;
const { Meta } = Card;

const StyledPageHeader = styled(StyledHeader)`
  position: fixed;
  top: 0;
  z-index: 12;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 24px;
  padding-left: 9px;
  height: 32px;
  background: #dddddd;

  .deploy_button {
    background: #3b82ff;
    border-radius: 4px;
    padding: 4px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    font-family: "Roboto";
    border: none;
    margin-right: 56px;
    cursor: pointer;
  }
  .deploy_button:hover {
    background: #2f79fc;
  }
`;
const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper_profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
    color: #6c767e;
    font-size: 14px;
    line-height: 22px;
    .user_name {
      margin-left: 10px;
    }
    .user_balance {
      margin-right: 15px;
      span {
        font-weight: 700;
      }
    }
  }

  .wrapper_bell {
    position: relative;
    div {
      position: absolute;
      top: -2px;
      right: -5px;
      height: 16px;
      display: flex;
      border-radius: 10px;
      background: #db385a;
      padding: 0px 3px;
      font-size: 11px;
      line-height: 16px;
      color: #ffffff;
    }
  }
`;
const WrapperSettings = styled.div`
  display: flex;
  justify-content: space-between;
  width: 430px;
  margin-right: 130px;
  button {
    padding: 4px 16px;
    background: #ececec;
    border-radius: 4px;
    font-family: "Roboto";
    color: #495870;
    border: none;
    font-size: 14px;
    line-height: 16px;
    height: 24px;
    cursor: pointer;
  }
`;
const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  .name {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    font-family: "Roboto";
    font-size: 12px;
    line-height: 12px;
    color: #6c767e;

    .workspace_name {
      color: #004cbe;
    }
    .open_nav{
      cursor:pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      }
    }
    .nav {
      position:absolute;
      background:#dddddd;
      display:flex;
      flex-direction:column;
      min-width: 120px;
        a {
          color: #004cbe;
          text-decoration: none;
          height: 25px;
          padding: 5px;
          display:flex;
          align-item:center;
        }
        a:hover{
          background:#f3f3f3;
        }
     }
    svg {
      margin-right: 3px;
      margin-left: 3px;
    }
  }
`;

const StyledDropDownContainer = styled.div``;

const LogoImg = styled.img`
  max-width: 110px;
  height: 1.675rem;
`;

const SocialIconItem = styled.div`
  margin-right: 10px;
  font-size: 13px;
  :last-child {
    margin-right: 0px;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      margin-left: 5px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

type PageHeaderProps = {
  user?: User;
  appMode?: string;
  pageName?: string;
  applicationId?: string;
  publishApplication: (appId: string) => void;
  currentApplication?: ApplicationPayload;
  location?: any;
  isLoadingFetchingApplicationInit?: boolean;
};

export const PageHeader = (props: PageHeaderProps) => {
  const {
    user,
    currentApplication,
    applicationId,
    userBalance,
    isLoadingFetchingApplicationInit,
  } = props;
  const userTours = useSelector((state: any) => getUserTours(state));
  const isWorkspacePage = window.location.pathname === "/workspace";
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState(false);
  const [pages, setPages] = useState([]);
  const [publishVersion, setPublishVersion] = useState("");
  const dispatch = useDispatch();
  const isSavingName = useSelector(getIsSavingAppName);
  const pageId = useSelector(getCurrentPageId);
  const applicationList = useSelector(getApplicationList);
  const organizationServerInfo = useSelector(getCurrentOrgServerInfo);
  const userData = useSelector((state: any) => getCurrentUser(state));
  const organizationServerStatus = useSelector(getCurrentOrgServerStatus);
  const [cpu, setCpu] = useState("");
  const [ram, setRam] = useState("");
  const [userQuest, setUserQuest] = useState() as any;

  const queryParams = new URLSearchParams(location.search);

  const fetchUserQuest = async () => {
    try {
      const response = await fetch(
        `https://${location.host}/api/quest/getQuestsForUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user?.id,
            fromPlatform: true,
          }),
        },
      );
      if (response.ok) {
        const data = await response.json();
        setUserQuest(data);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (user) fetchUserQuest();
  }, [user]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://${location.host}/api/server_resourse`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            nodeLabel: organizationServerInfo.server_info?.nodeSelector,
            cluster_info: organizationServerInfo.cluster_info?.cluster_id,
          }),
        },
      );
      if (response.ok) {
        const data = await response.json();
        setCpu(data.cpu);
        setRam(data.ram);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log(
      organizationServerInfo,
      "organizationServerInfoorganizationServerInfo",
    );
    let intervalId;

    if (organizationServerInfo) {
      fetchData(); // Initial fetch

      // Set up an interval to fetch data every 5 seconds
      intervalId = setInterval(fetchData, 5000);
    }

    return () => {
      // Clear the interval when organizationServerInfo becomes null or undefined

      clearInterval(intervalId);
    };
  }, [organizationServerInfo]);

  const handleClose = useCallback(() => {
    setVersion(false);
  }, []);
  // useEffect(() => {
  //   posthog.init("phc_19DnKoKNvKHlF6zu5tCrY4CxAysRGT30otBOYyTqoLb", {
  //     api_host: "https://posthog.ubos.tech",
  //   });
  //   if (user && user.email) {
  //     posthog.identify(user.name, { email: user.email });
  //   }
  // }, [user]);

  let loginUrl = AUTH_LOGIN_URL;

  const handleDefaultSettings = useCallback(async () => {
    try {
      const a = await ApplicationApi.reSettingsLayout(applicationId || "");
      location.reload();
    } catch (e) {
      location.reload();
    }
  }, [applicationId]);

  const exportApp = useCallback(async () => {
    try {
      window.location.href =
        window.location.origin + "/api/get_json/" + applicationId;
    } catch (e) {
      location.reload();
    }
  }, [applicationId]);

  const importApp = useCallback(() => {
    modalUpload.current.open();
  }, [applicationId]);

  if (queryParams.has("redirectTo")) {
    loginUrl += `?redirectTo=${queryParams.get("redirectTo")}`;
  }
  const handleOpenVersion = useCallback(() => {
    setVersion(true);
  }, []);
  const updateApplicationDispatch = (id: string, data: { name: string }) => {
    dispatch(updateApplication(id, data));
  };
  const handleNav = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const modalUpload = useRef({
    open: () => {
      console.log();
    },
    close: () => {
      console.log();
    },
  });

  const upload = useCallback(
    async file => {
      const form = new FormData();
      form.append("file", file);
      form.append("orgId", currentApplication?.orgId || "");
      // console.log(BUILDER_PAGE_URL("asd", "asd123"));
      try {
        const { data } = (await importJson(form)) as any;
        if (data) {
          window.location.href =
            window.location.origin +
            BUILDER_PAGE_URL(data.data.appId, data.data.pagId);
        }
        modalUpload.current.close();
      } catch (e) {
        modalUpload.current.close();
      }
    },
    [currentApplication, modalUpload],
  );

  const editPage = props?.location?.pathname.indexOf("/edit") !== -1;

  const hoverAnimation = keyframes`
  0% {
    transform: scale(1);
    fill: #3b82ff;
  }
  50% {
    transform: scale(1.2);
    fill: #97b4e7;
  }
  100% {
    transform: scale(1);
    fill: #3b82ff;
  }
`;

  const StyleAddCard = styled.div`
    margin-right: 5px;
    font-size: 13px;
    color: rgb(108, 118, 126);

    &:hover {
      cursor: pointer;

      .ctaq svg {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }
    }

    &:not(:hover) .ctaq svg {
      animation-play-state: running;
      animation-iteration-count: 0;
    }

    .ctaq {
      svg {
        width: 21px;
        height: 100%;
        fill: #3b82ff;
        animation: ${hoverAnimation} 2s ease-in-out;
        animation-play-state: paused;
      }

      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0;
      border-radius: 5px;
      padding: 5px 15px;
    }
  `;

  const StyleCard = styled.div`
    margin-right: 10px;
    font-size: 13px;
    .cta {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0;
      background-image: linear-gradient(3deg, #65cbe2 9%, #2563eb 100%);
      color: white;
      border-radius: 5px;
      padding: 3px 15px;

      &:hover {
        cursor: pointer;
      }

      svg {
        user-select: none;
        width: 1em;
        height: 1em;
        flex-shrink: 0;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        font-size: 1.28571rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        transform-origin: center;
      }
    }

    &:hover .cta path {
      animation: colorChange 2s infinite;
    }

    @keyframes colorChange {
      0% {
        fill: white;
        /* transform: scale(1); */
        /* color: white; */
      }
      50% {
        fill: #062761;
        /* transform: scale(1.2); */
        /* color:#062761; */
      }
      100% {
        fill: white;
        /* transform: scale(1); */
        /* color: white; */
      }
    }
  `;
  const ProgressBarContainer = styled.div`
    display: flex;
    align-items: center;
    &:hover {
      /* Додаткові стилі для попапу */
      position: relative;
      &::after {
        content: "Workspace is not running";
        position: absolute;
        z-index: 1000;
        width: 100%;
        text-align: center;
        top: 30px;
        left: 100%;
        transform: translateX(-100%);
        background-color: #fff;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
        visibility: ${organizationServerStatus == "stopped"
          ? "visible"
          : "hidden"}; // Показати попап лише при зупинці
      }
    }
    /* Additional styles for the progress bars container */
  `;

  const ProgressBarWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
  `;

  const ProgressBar = styled.progress`
    transform: rotate(270deg);
    width: 20px; /* Set width as needed */
    height: 7px; /* Set height as needed */
    border-radius: 50px;
    overflow: hidden;
    border: 0;
    appearance: none;
    &::-webkit-progress-value {
      background: ${props => props.color};
    }
    &::-webkit-progress-bar {
      background-color: #f3f3f3 !important;
    }
  `;
  const getColor = (value: number) => {
    if (value < 50) {
      return "#2dff0c"; // Red for values less than 30%
    } else if (value < 75) {
      return "yellow"; // Yellow for values between 30% and 70%
    } else {
      return "red"; // Green for values above 70%
    }
  };
  const checkDiscountTemplate = () => {
    if (user && userTours && !userTours.discountTemplate) {
      const createdAt = new Date(user.createdAt);
      const now = new Date();
      const timeDifference = now - createdAt;
      const threeHoursInMillis = 3 * 60 * 60 * 1000;

      if (timeDifference >= threeHoursInMillis) {
        // Пройшло більше 3 годин
        return false;
      } else {
        return true;
        // Не пройшло 3 годин
      }
    }
  };
  const openRam = () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    const newItem = {
      id: JSON.stringify({ id: "Resources", type: "Resources" }),
      component: "UserSettings",
      title: "Resources",
      props: { navigateTo: "Services" },
    };
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const openCreditsPage = () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
    const newItem = {
      id: JSON.stringify({ id: "Credits", type: "Credits" }),
      component: "UserSettings",
      title: "Credits",
      props: { navigateTo: "Credits" },
    };
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const handleOpenTemplateManager = () => {
    if (checkDiscountTemplate()) {
      const newItem = {
        id: createIdTemplateManager(),
        component: "TemplateManager",
        props: { templateId: promotionalTemplateInfo.id },
      };
      dispatch({
        type: ReduxActionTypes.SET_LAYOUT_ITEM,
        payload: newItem,
      });
    }
  };

  const handleOpenUserQuest = () => {
    dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });

    const newItem = {
      id: JSON.stringify({ id: "Quests", type: "UserQuest" }),
      component: "UserQuest",
      title: "Quests",
    };
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: newItem,
    });
  };

  const StyledTimer = styled.div`
    > p {
      font-size: 14px;
      margin: 0;
      margin-right: 5px;
      color: #6c767e;
    }
  `;

  const handleOpenLeaveWorkspaceModal = () => {
    dispatch({
      type: ReduxActionTypes.UPD_USER_MODAL,
      payload: {
        leaveWorkspaceModal: {
          isOpen: true,
        },
      },
    });
  };

  const userOrgs = useSelector(
    (state: AppState) => state.ui.applications.userOrgs,
  );

  const userOrgsModifiedData = React.useMemo(() => makeData(userOrgs), [
    userOrgs,
  ]);

  const [isTrigerOpenCreditsPage, setIsTrigerOpenCreditsPage] = useState(false);
  const [isTrigerOpenUserQuestPage, setIsTrigerOpenUserQuestPage] = useState(
    false,
  );

  const handleUserQuestClick = () => {
    if (isWorkspacePage) {
      if (userOrgsModifiedData && userOrgsModifiedData.length > 0) {
        const lastItem = userOrgsModifiedData.sort((a, b) => {
          const dateA = new Date(a.update_date);
          const dateB = new Date(b.update_date);
          return dateB - dateA;
        })[0].buttons;

        dispatch({
          type: ReduxActionTypes.CUR_APP,
          payload: lastItem.appId,
        });
        history.push(lastItem.hrefEdit);
        setIsTrigerOpenUserQuestPage(true);
      }
    } else {
      handleOpenUserQuest();
    }
  };

  const handleBalanceClick = () => {
    if (isWorkspacePage) {
      if (userOrgsModifiedData && userOrgsModifiedData.length > 0) {
        const lastItem = userOrgsModifiedData.sort((a, b) => {
          const dateA = new Date(a.update_date);
          const dateB = new Date(b.update_date);
          return dateB - dateA;
        })[0].buttons;

        dispatch({
          type: ReduxActionTypes.CUR_APP,
          payload: lastItem.appId,
        });
        history.push(lastItem.hrefEdit);
        setIsTrigerOpenCreditsPage(true);
        // openCreditsPage();
      }
    } else {
      openCreditsPage();
    }
  };

  const dockLayoutIsLoading = document.querySelector(".dock-layout") !== null;

  useEffect(() => {
    if (!isWorkspacePage && isTrigerOpenCreditsPage && dockLayoutIsLoading) {
      openCreditsPage();
      setIsTrigerOpenCreditsPage(false);
    }

    if (!isWorkspacePage && isTrigerOpenUserQuestPage && dockLayoutIsLoading) {
      handleOpenUserQuest();
      setIsTrigerOpenUserQuestPage(false);
    }
  }, [dockLayoutIsLoading]);
  useEffect(() => {
    if (user) {
      const aiBotV3 = document.cookie.replace(
        /(?:(?:^|.*;\s*)aiBotV3\s*\=\s*([^;]*).*$)|^.*$/,
        "$1",
      );
      if (aiBotV3 && aiBotV3 == "true") {
        document.cookie = `aiBotV3=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        window.location.href = `https://chatbot.ubos.tech/Login?access_token=${user?.keycloakAccessToken}`;
      } else {
        const FirstLogin = document.cookie.replace(
          /(?:(?:^|.*;\s*)FirstLogin\s*\=\s*([^;]*).*$)|^.*$/,
          "$1",
        );
        const templateId = document.cookie.replace(
          /(?:(?:^|.*;\s*)templateId\s*\=\s*([^;]*).*$)|^.*$/,
          "$1",
        );
        if (FirstLogin && !templateId) {
          setFirstLogin(true);
          dispatch({
            type: ReduxActionTypes.UPD_USER_MODAL,
            payload: {
              firstLogin: {
                isOpen: true,
              },
            },
          });
        }
        document.cookie = `FirstLogin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
  }, [user]);
  const [firstLogin, setFirstLogin] = useState(false);
  return (
    <>
      <Modal className="template-service-modal" open={firstLogin}>
        <div>
          <Title
            className="template-service-modal-title1"
            style={{ fontWeight: 900 }}
          >
            Boost your business intelligence with UBOS
          </Title>
          <Title
            level={4}
            className="template-service-modal-title1"
            style={{ marginBottom: "60px" }}
          >
            Explore different products for various use-cases
          </Title>

          <Row gutter={[16, 16]} justify="center">
            <Col style={{ textAlign: "start" }}>
              <Badge.Ribbon text="Generative AI">
                <Card
                  className="template-service-modal-card"
                  onClick={() => {
                    window.location.href = `https://chatbot.ubos.tech/Login?access_token=${user?.keycloakAccessToken}`;
                    // setFirstLogin(false)
                    // dispatch({
                    //   type: ReduxActionTypes.UPD_USER_MODAL,
                    //   payload: {
                    //     firstLogin: {
                    //       isOpen: false,
                    //     },
                    //   },
                    // });
                  }}
                  hoverable
                  style={{ width: 350 }}
                  cover={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "24px",
                        marginTop: "6px",
                      }}
                    >
                      <img
                        alt="example"
                        style={{
                          height: "80px",
                          width: "80px",
                          padding: "3px",
                        }}
                        src="https://ubos.tech/wp-content/uploads/2024/07/iconAIBot.png"
                      />
                      <h4 style={{ fontSize: "20px", marginLeft: "6px" }}>
                        Chatbot
                      </h4>
                    </div>
                  }
                >
                  <Meta
                    title="AI Chatbot for business"
                    description="Open Chatbot ➟"
                  />
                </Card>
              </Badge.Ribbon>
            </Col>
            <Col style={{ textAlign: "start" }}>
              <Card
                className="template-service-modal-card"
                onClick={() => {
                  setFirstLogin(false);
                  dispatch({
                    type: ReduxActionTypes.UPD_USER_MODAL,
                    payload: {
                      firstLogin: {
                        isOpen: false,
                      },
                    },
                  });
                }}
                hoverable
                style={{ width: 350 }}
                cover={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "24px",
                      marginTop: "6px",
                    }}
                  >
                    <img
                      alt="example"
                      style={{ height: "80px", width: "80px" }}
                      src="https://ubos.tech/wp-content/uploads/2024/07/UBOS-Logo-Small.png"
                    />
                    <h4 style={{ fontSize: "20px", marginLeft: "6px" }}>
                      Platform
                    </h4>
                  </div>
                }
              >
                <Meta
                  title="Build production ready AI Apps"
                  description="Open Development Platform ➟"
                />
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
      <StyledPageHeader>
        <HeaderSection>
          {/* <div
          onClick={handleOpenLeaveWorkspaceModal}
          style={{ cursor: "pointer" }}
        >
          <StorageIcon style={{ width: "25px", height: "25px" }} />
        </div> */}
          <Link
            onClick={event => {
              event.preventDefault(); // Зупиняє стандартну поведінку лінка
              handleOpenLeaveWorkspaceModal(); // Викликає вашу функцію
            }}
            to={APPLICATIONS_URL}
          >
            <StorageIcon style={{ width: "25px", height: "25px" }} />
          </Link>
          {editPage && (
            <div className="name">
              <span>HOME</span>
              <Arrow></Arrow>
              <span className="workspace_name">
                {isLoadingFetchingApplicationInit ? (
                  <Skeleton
                    style={{ width: "150px" }}
                    active
                    title={false}
                    paragraph={{
                      width: "100%",
                      rows: 1,
                    }}
                  />
                ) : (
                  currentApplication?.orgname
                )}
              </span>
              {/* <ClickAwayListener
              onClickAway={() => {
                setOpen(false);
              }}
            >
              <span>
                <div className="workspace_name open_nav" onClick={handleNav}>
                  {" "}
                  ({currentApplication?.name}
                  <ArrowCollapse></ArrowCollapse>)
                </div>
                <div className="nav">
                  <div>
                    {open &&
                      currentApplication?.appnames?.map(
                        (item: any, index: number) => {
                          return (
                            <a
                              key={index}
                              href={`/workspace/${item.id}/pages/${item.page_default}/edit`}
                            >
                              {item.name}
                            </a>
                          );
                        },
                      )}
                  </div>
                </div>
              </span>
            </ClickAwayListener> */}
            </div>
          )}
        </HeaderSection>

        {/* {editPage && (
        <WrapperSettings>
          <button onClick={importApp}>Import app</button>
          <UploadModal
            ref={modalUpload}
            uploaded={upload}
            type={"JSON"}
            importApp={true}
          />
          <button onClick={exportApp}>Export app</button>
          <button onClick={handleDefaultSettings}>Default settings</button>
          <div>
            <button onClick={handleOpenVersion}>Version</button>
            <Modal
              open={version}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <VersionModal
                pageId={pageId}
                currentApplication={currentApplication}
                publishVersion={publishVersion}
                setPublishVersion={setPublishVersion}
                closeModal={handleClose}
              />
            </Modal>
          </div>
        </WrapperSettings>
      )} */}

        {/* {editPage && (
        <button
          className="deploy_button"
          onClick={() => {
            props.publishApplication(applicationId || "");
          }}
        >
          Deploy
        </button>
      )} */}
        {user && user.user_type == "free" && (
          <div>
            <StyleCard
              onClick={async () => {
                await Axios.post(
                  `https://us.${location.host}/user-settings/subscribeToPro`,
                  {
                    id: user.id,
                  },
                )
                  .then(data => {
                    window.open(data.data, "_blank");
                  })
                  .catch(e => console.log(e));
              }}
            >
              <p className="cta">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.47 10.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 12.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M11.47 4.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 6.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z"
                    clipRulee="evenodd"
                  />
                </svg>
                <span>Upgrade</span>
              </p>
            </StyleCard>
          </div>
        )}

        {user && (
          <StyledDropDownContainer>
            {user.username === ANONYMOUS_USERNAME ? (
              <Button
                filled
                text="Sign In"
                intent={"primary"}
                size="small"
                onClick={() => history.push(loginUrl)}
              />
            ) : (
              <Profile>
                {/* {checkDiscountTemplate() && (
                <StyledTimer>
                  <Timer createdAt={user?.createdAt} />
                </StyledTimer> 
              )} */}
                {userQuest && (
                  <StyleAddCard onClick={handleUserQuestClick}>
                    <p className="ctaq">
                      <GameIcon />
                      <span>
                        Quests: {userQuest.totalCompletedQuests} /{" "}
                        {userQuest.totalQuests}
                      </span>
                    </p>
                  </StyleAddCard>
                )}

                <div className="wrapper_profile">
                  {!isWorkspacePage && organizationServerInfo && (
                    <ProgressBarContainer
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={openRam}
                    >
                      <ProgressBarWrapper>
                        <span>CPU: </span>
                        <ProgressBar
                          value={cpu}
                          max={100}
                          color={getColor(Number(cpu))}
                        />
                        {cpu && <span> {cpu}%</span>}
                      </ProgressBarWrapper>
                      <ProgressBarWrapper>
                        <span>RAM: </span>
                        <ProgressBar
                          value={ram}
                          max={100}
                          color={getColor(Number(ram))}
                        />
                        {ram && <span> {ram}%</span>}
                      </ProgressBarWrapper>
                    </ProgressBarContainer>
                  )}
                  {userBalance && (
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={handleBalanceClick}
                      className="user_balance user-balance-tour-step"
                    >
                      Current balance: <span>{userBalance}</span>
                    </div>
                  )}
                  <SocialIconItem>
                    <a
                      href={"https://discord.com/invite/dt59QaptH2"}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={"Discord"}
                    >
                      <DiscordIcon />
                    </a>
                  </SocialIconItem>
                  {!isWorkspacePage && (
                    <InfoList
                      userInfo={{
                        userId: user.id,
                        userEmail: user.email,
                      }}
                    />
                  )}

                  <ProfileDropdown userName={user.username} />
                  {/* <div className="user_name">{user.username}</div> */}
                </div>
              </Profile>
            )}
          </StyledDropDownContainer>
        )}
      </StyledPageHeader>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  appMode: getAppMode(state),
  user: getCurrentUser(state),
  userBalance: getCurrentUserSelector(state),
  pageName: state.ui.editor.currentPageName,
  applicationId: getCurrentApplicationId(state),
  currentApplication: state.ui.applications.currentApplication,
  isLoadingFetchingApplicationInit:
    state.ui.applications.isLoadingFetchingApplicationInit,
});

const mapDispatchToProps = (dispatch: any) => ({
  publishApplication: (applicationId: string) => {
    dispatch({
      type: ReduxActionTypes.PUBLISH_APPLICATION_INIT,
      payload: {
        applicationId,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
