import React, { forwardRef, useImperativeHandle, useState } from "react";
import styled from "styled-components";
import { Collapse, Icon, IconName, Tooltip } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Colors } from "constants/Colors";
import { BindingText } from "pages/Editor/APIEditor/Form";
import { extraLibraries } from "utils/DynamicBindingUtils";
import { ReactComponent as Close } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as Open } from "../../../assets/images/work_space/open.svg";
import { NodeRedCollapse } from "./NodeRedCollapse";

const Wrapper = styled.div`
  font-size: 13px;
  padding-right: 0px;
  .bp3-collapse-body {
    border-left: 1px solid #afb4b8;
    margin-left: 8px;
  }
`;
export const ListItem = styled.li`
  list-style: none;
  color: ${Colors.ALTO};
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px 0 0;
  margin-left: 12px;
  &:hover {
    background: #e8e8e8;
    .info_collapse {
      display: flex;
    }
  }
  .good {
  }

  .open-context-menu {
    border: 1px solid #3b82ff;
  }

  .good::after {
    position: absolute;
    content: "";
    right: 11px;
    height: 8px;
    width: 8px;
    background-color: #2aff09fc;
    border-radius: 50%;
  }
  .inprocess {
    opacity: 0.5;
    pointer-events: none;
  }
  .inprocess::after {
    position: absolute;
    content: "";
    right: 11px;
    height: 8px;
    width: 8px;
    background-color: #ffb509fd;
    border-radius: 50%;
    animation: animate-inprocess 1.25s ease-in-out infinite;
  }
  @keyframes animate-inprocess {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.75;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.25;
    }
    100% {
      opacity: 0;
    }
  }
  .error {
    opacity: 0.5;
    color: red;
    pointer-events: none;
  }
  .error::after {
    position: absolute;
    content: "";
    right: 11px;
    height: 8px;
    width: 8px;
    background-color: red;
    border-radius: 50%;
  }
  .dots {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    // border-left: 1px solid #3b82ff;
    font-family: "Roboto";
    font-size: 12px;
    line-height: 12px;
    color: #6c767e;
    padding-left: 3px;
  }
`;

export const Name = styled.span`
  width: calc(100% - 85px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Roboto";
  font-size: 12px;
  line-height: 12px;
  color: #6c767e;
`;
export const Version = styled.span`
  color: #495870;
`;
const Title = styled.div`
  height: 22px;
  cursor: pointer;
  display: flex;
  padding: 3px 2px 2px 3px;
  justify-content: space-between;
  font-size: 10px;
  line-height: 12px;
  font-family: "Roboto";
  color: #495870;
  .icon_open_close {
    width: 10px;
  }
  &:hover {
    background: #e8e8e8;
  }
  .padding {
    padding-left: 4px;
    font-size: 12px;
    text-transform: uppercase;
  }
`;
const List = styled.ul`
  padding: 0px;
  margin: 0 0 0 0px;
`;
export const Help = styled(Icon)`
  &:hover svg {
    fill: ${Colors.WHITE};
  }
`;
export const JSDependencies = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDocs = (name: string, url: string) => () => window.open(url, name);

  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
  }));

  const dependencyList = extraLibraries.map(lib => {
    return (
      <ListItem
        key={lib.displayName}
        onClick={openDocs(lib.displayName, lib.docsURL)}
      >
        <Name className="dep_name">
          <span className="dots">{lib.displayName}</span>
        </Name>
        <Version>{lib.version}</Version>
      </ListItem>
    );
  });
  const icon = isOpen ? <Open></Open> : <Close></Close>;
  const toggleDependencies = () => setIsOpen(!isOpen);
  const showDocs = (e: any) => {
    window.open(
      "https://docs.appsmith.com/core-concepts/connecting-ui-and-logic/working-with-js-libraries",
      "appsmith-docs:working-with-js-libraries",
    );
    e.stopPropagation();
    e.preventDefault();
  };

  const TooltipContent = (
    <div>
      <span>Access these JS libraries to transform data within </span>
      <BindingText>{`{{ }}`}</BindingText>
      <span>. Try </span>
      <BindingText>{`{{ _.add(1,1) }}`}</BindingText>
    </div>
  );
  return (
    <Wrapper>
      <Title onClick={toggleDependencies} className={`${isOpen && "active"}`}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="icon_open_close">{icon}</div>
          <span className="padding">Dependencies</span>
        </div>
        {/*<Tooltip content={TooltipContent} position="top" boundary="viewport">*/}
        {/*  <Help*/}
        {/*    icon="help"*/}
        {/*    iconSize={12}*/}
        {/*    color={Colors.DOVE_GRAY}*/}
        {/*    onClick={showDocs}*/}
        {/*  />*/}
        {/*</Tooltip>*/}
      </Title>
      <Collapse isOpen={isOpen}>
        <List>{dependencyList}</List>
      </Collapse>
    </Wrapper>
  );
});
JSDependencies.displayName = "JSDependencies";
export default JSDependencies;
