import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "reducers";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { finishUserTourHandler } from "pages/organization/helpers";
import { getCurrentUser } from "selectors/usersSelectors";
import { getDataSources } from "selectors/editorSelectors";
import { Datasource, ServiceType } from "api/DatasourcesApi";
import ChargeBalanceTour from "./ChargeBalanceTour";
import InitialTour from "./InitialTour";
import OpenServiceTour from "./OpenServiceTour";
import HowToCreateServiceTour from "./HowToCreateServiceTour";
import HowToCloneTemplateTour from "./HowToCloneTemplateTour";
import LeftSidebarTour from "./LeftSidebarTour";
import HowToOpenServiceOrTemplate from "./HowToOpenServiceOrTemplate";
import InitialTourTemplate from "./InitialTourTemplate";

const ToursWrapper = (props: any) => {
  const dispatch = useDispatch();
  const datasources: Datasource[] = useSelector(getDataSources);
  const userTours = useSelector((state: AppState) => {
    return state.ui.users.userTours;
  });
  const allUserTours = useSelector((state: AppState) => {
    return state.ui.users.allTours;
  });
  const user = useSelector((state: any) => getCurrentUser(state));

  const [activeTour, setActiveTour] = useState() as any;
  const [isHideChat, setIsHideChat] = useState(false);
  const [gifs, setGifs] = useState([]) as any;
  const [firstlogin, setFirstLogin] = useState(true);
  const userModals = useSelector((state: AppState) => {
    return state.ui.users.modals;
  });
  useEffect(() => {
    const modals = Object.keys(userModals).map(key => ({
      name: key,
      isOpen: userModals[key].isOpen,
    }));

    const firstLogin = modals.find(
      modal => modal.name === "firstLogin" && modal.isOpen,
    );
    if (firstLogin) {
      setFirstLogin(true);
    } else {
      setFirstLogin(false);
    }
  }, [userModals]);

  const updTourState = (tourName: string) => {
    const { automatically, ...tourInfo } = userTours[activeTour.name];
    finishUserTourHandler({ userId: user?.id, tourName, tourInfo }, dispatch);
    if (tourName === "openService") {
      dispatch({
        type: ReduxActionTypes.UPD_USER_TOUR_OPEN_SERVICE,
        payload: {
          ["openService"]: {
            completed: true,
          },
        },
      });
    }
  };

  useEffect(() => {
    const tours = Object.keys(userTours).map(key => ({
      name: key,
      isOpen: userTours[key].isOpen,
      automatically: userTours[key]?.automatically,
    }));

    const firstTour = tours.find(modal => modal.isOpen);
    setActiveTour(firstTour);
  }, [userTours]);
  useEffect(() => {
    if (activeTour) setIsHideChat(true);
    else setIsHideChat(false);
  }, [activeTour]);

  useEffect(() => {
    const intercomContainer = document.querySelector(
      "body .intercom-lightweight-app",
    );

    if (intercomContainer) {
      if (isHideChat) {
        intercomContainer.style.display = "none";
      } else {
        intercomContainer.style.display = "block";
      }
    }
  }, [isHideChat]);

  function getAllDatasources(data) {
    const datasources = [];

    // Iterate over each object in the data array
    for (const obj of data) {
      // Check if the object has a "datasources" property
      if (obj.hasOwnProperty("datasources")) {
        // If it does, concatenate its "datasources" array to the results
        datasources.push(...obj.datasources);
      }

      // Recursively call the function on children if it's a folder
      if (obj.isFolder && obj.children) {
        datasources.push(...getAllDatasources(obj.children));
      }
    }

    return datasources;
  }

  const dasourceList = getAllDatasources(datasources);
  const findFirsServiceInDS = (): ServiceType | undefined => {
    const firstServiceType = dasourceList.filter(obj =>
      obj.serviceActions.includes("OPEN_LAYOUT_TAB"),
    )[0]?.type;
    console.log(
      firstServiceType,
      dasourceList,
      "firstServiceTypefirstServiceType",
    );
    return firstServiceType;
  };

  useEffect(() => {
    if (!allUserTours?.openService && dasourceList.length > 0) {
      const firstServiceType = findFirsServiceInDS();

      if (firstServiceType) {
        dispatch({
          type: ReduxActionTypes.UPD_USER_TOUR,
          payload: {
            ["openService"]: {
              isOpen: true,
              label: "How to open service",
            },
          },
        });
      }
    } else {
      dispatch({
        type: ReduxActionTypes.UPD_USER_TOUR,
        payload: {
          ["openService"]: {
            isOpen: false,
            label: "How to open service",
          },
        },
      });
    }
  }, [datasources]);

  const handleFinishTour = () => {
    if (activeTour.automatically) {
      const { automatically, ...tourInfo } = userTours[activeTour.name];

      dispatch({
        type: ReduxActionTypes.UPD_USER_TOUR,
        payload: {
          [activeTour.name]: {
            ...tourInfo,
            isOpen: false,
          },
        },
      });
    } else {
      updTourState(activeTour.name);
    }
  };

  const handleStarTemplateOrServiceTour = () => {
    dispatch({
      type: ReduxActionTypes.UPD_USER_TOUR,
      payload: {
        howToOpenServiceOrTemplate: {
          isOpen: true,
        },
      },
    });
  };

  const tours = [
    {
      name: "initialTour",
      component: InitialTour,
      props: {
        handleOpenDemoTemplate: props.handleOpenDemoTemplate,
        handleLeftMenu: props.handleLeftMenu,
        handleStarTemplateOrServiceTour,
        tourtemplate: props.templatedemo,
      },
    },
    {
      name: "howToOpenServiceOrTemplate",
      component: HowToOpenServiceOrTemplate,
      props: {
        openLeftMenu: props.openLeftMenu,
      },
    },
    { name: "openService", component: OpenServiceTour },
    { name: "chargeBalance", component: ChargeBalanceTour },
    { name: "howToCreateService", component: HowToCreateServiceTour },
    { name: "howToCloneTemplate", component: HowToCloneTemplateTour },
    {
      name: "leftSidebar",
      component: LeftSidebarTour,
      props: {
        gifs,
      },
    },
  ];

  useEffect(() => {
    const gifUrls = [
      "https://ubos.tech/wp-content/uploads/2024/01/home1.gif",
      "https://ubos.tech/wp-content/uploads/2024/01/show-hide.gif",
      "https://ubos.tech/wp-content/uploads/2024/04/template-gif.gif",
      "https://ubos.tech/wp-content/uploads/2024/01/workspace-setting-1.gif",
      "https://ubos.tech/wp-content/uploads/2024/01/logs1.gif",
      "https://ubos.tech/wp-content/uploads/2024/01/user-setting.gif",
    ];

    async function loadGifs() {
      try {
        const gifPromises = gifUrls.map(async url => {
          const response = await fetch(url);
          const data = await response.blob();
          return URL.createObjectURL(data);
        });

        const gifUrlsArray = await Promise.all(gifPromises);

        setGifs(gifUrlsArray);
      } catch (error) {
        console.error("Error loading gifs:", error);
      }
    }
    loadGifs();
  }, []);

  return (
    <>
      {console.log(activeTour, "activeTouractiveTouractiveTour")}
      {!firstlogin && activeTour && activeTour.name && (
        <>
          {tours.map(tour => {
            if (activeTour.name === tour.name) {
              const TourComponent = tour.component as any;
              return (
                <TourComponent
                  key={tour.name}
                  handleFinishTour={handleFinishTour}
                  additionalProps={tour?.props}
                />
              );
            }
            return null;
          })}
        </>
      )}
    </>
  );
};

export default ToursWrapper;
