import React, { MutableRefObject, useCallback, useRef } from "react";
import Dropdown from "components/ads/Dropdown";
import TextInput from "components/ads/TextInput";
import styled from "styled-components";
import Icon, { IconSize } from "components/ads/Icon";
import { useDispatch } from "react-redux";

import { clearLogs } from "actions/debuggerActions";
import { Classes } from "components/ads/common";
import { DropdownOption } from "../../../widgets/DropdownWidget";

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
  padding: 5px 0;
  & > div {
    width: 160px;
    margin: 0 16px;
  }

  .debugger-search {
    height: 28px;
    width: 160px;
    padding-right: 25px;
    border: 1px solid rgb(240, 240, 240);
    padding: 0px 14px;
    height: 38px;
    background-color: rgb(240, 240, 240);
    color: rgb(48, 45, 45);
  }
  select {
    border: 1px solid rgb(240, 240, 240);
    background-color: rgb(240, 240, 240);
    margin-right: 10px;
    color: rgb(48, 45, 45);
    width: 150px;
  }
  .debugger-filter {
    background: transparent;
    border: none;
    box-shadow: none;
    background-color: white;
    width: 100px;
  }

  .input-container {
    position: relative;
    .${Classes.ICON} {
      position: absolute;
      right: 3px;
      top: 15px;
    }
  }
`;

type FilterHeaderProps = {
  options: any;
  selected: any;
  onChange: (value: string) => void;
  onSelect: (value?: string) => void;
  defaultValue: string;
  searchQuery: string;
};

function FilterHeader(props: FilterHeaderProps) {
  const dispatch = useDispatch();
  const searchRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

  const filter = useCallback(e => {
    props.onSelect(e.target.value);
  }, []);
  return (
    <Wrapper>
      <Icon
        onClick={() => dispatch(clearLogs())}
        size={IconSize.XL}
        name={"cancel"}
      />
      <div className="input-container">
        <TextInput
          className="debugger-search"
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          placeholder="Filter"
          ref={searchRef}
        />
        {props.searchQuery && (
          <Icon
            name="close"
            onClick={() => {
              if (searchRef.current) {
                props.onChange("");
                searchRef.current.value = "";
              }
            }}
          />
        )}
      </div>
      <select name="" value={props.selected.value} onChange={filter} id="">
        {props.options.map((item: any, index: number) => {
          return (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
    </Wrapper>
  );
}

export default FilterHeader;
