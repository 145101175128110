import { fetchOrg } from "actions/orgActions";
import { Button, Modal } from "antd";
import { Datasource } from "api/DatasourcesApi";
import { sendUserLogs } from "api/userLogs";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "reducers";
import { getDataSources } from "selectors/editorSelectors";
import { getCurrentUser } from "selectors/usersSelectors";
import styled from "styled-components";

const StyledWorkspaceMigrationModal = styled(Modal)`
  .ant-modal-title {
    font-size: 24px;
    color: #4b5563;
  }

  .infoAboutMigration {
    background: #ffc23a50;
    padding: 10px;
    border: 1px solid #e1821d;
  }
`;

const ServerList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  list-style: none;
  gap: 15px;

  .selectedServerPlan-active {
    border: 2px solid #1677ff;
    border-radius: 3px;
  }
`;

const ServerItem = styled.li`
  width: 200px;
  padding: 8px;
  color: #4b5563;
  border: 2px solid #f1f3f4;
  border-radius: 3px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.03), 0 2px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.03);
  cursor: pointer;

  &:hover {
    background-color: rgb(249 250 251/1);
  }

  .serverName {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
  }

  .serverSize {
    font-size: 13px;
  }

  .serverPrice {
    font-weight: 400;
    font-size: 13px;
    padding: 2px 8px 4px 8px;
    border: 1px solid;
    border-color: #e5e7eb;
    border-radius: 25px;
    color: #6b7280;
    background: #f3f4f6;
    width: fit-content;
    margin: 0;
  }
`;

interface IUserSettings {
  handleMigrateWorkspace: any;
  updModalState: (data: any) => void;
}

const WorkspaceMigrationModal = (props: IUserSettings) => {
  const baseURL = "https://platform.ubos.tech";
  // const baseURL = location.host;

  const dispatch = useDispatch();

  const user = useSelector((state: any) => getCurrentUser(state));
  const datasources: Datasource[] = useSelector(getDataSources);
  const currentOrg = useSelector((state: AppState) => {
    return state.ui.orgs.currentOrg;
  });
  const isFetchingOrg = useSelector((state: AppState) => {
    return state.ui.orgs.loadingStates.isFetchingOrg;
  });

  const [isLoadingMigrateBtn, setIsLoadingMigrateBtn] = useState(false);
  const [checkoutSessionURL, setCheckoutSessionURL] = useState("");
  const [userBalanceFromStripe, setUserBalanceFromStripe] = useState() as any;

  const [serverPlans, setServerPlans] = useState([]);
  const [
    isShowMoveToNewWorkspaceModal,
    setShowMoveToNewWorkspaceModal,
  ] = useState(false);
  const [selectedServerPlan, setSelectedServerPlan] = useState("");

  // useEffect(() => {
  //   if (currentOrg.id) dispatch(fetchOrg(currentOrg.id));
  // }, [currentOrg.id]);

  // useEffect(() => {
  //   if (currentOrg.id) dispatch(fetchOrg(currentOrg.id));
  // }, [datasources]);

  useEffect(() => {
    try {
      Axios.get(`${baseURL}/api/subscription_plan/Server`).then(({ data }) => {
        if (data.length > 0) {
          let initData = data.sort(
            (a: any, b: any) => a.quantity * 730 - b.quantity * 730,
          );

          if (
            user?.server_free === undefined ||
            user?.server_free === true ||
            datasources.length > 3
          ) {
            initData = data.filter(
              (el: any) => el._id !== "65095119f74e030011361ae3",
            );
          }
          setServerPlans(initData);
        }
      });

      try {
        Axios.post(`${baseURL}/api/getBalanceFromStripe`, {
          id: user?.id,
        }).then(({ data }) => {
          setUserBalanceFromStripe(data.balance);
        });
      } catch (error) {
        sendUserLogs({
          userId: user.id,
          userEmail: user.email,
          type: "errorGetBalanceFromStripe",
          additionalData: error,
        });
      }
    } catch (error) {
      sendUserLogs({
        userId: user.id,
        userEmail: user.email,
        type: "errorGetSubscriptionPlan",
        additionalData: error,
      });
    }
  }, []);

  useEffect(() => {
    if (
      !isFetchingOrg &&
      datasources.length > 0 &&
      currentOrg._id &&
      !currentOrg.plan_id &&
      serverPlans.length > 0
    ) {
      const getOrgIdDS = datasources[0].organizationId;

      if (getOrgIdDS === currentOrg._id) setShowMoveToNewWorkspaceModal(true);
    }
  }, [currentOrg, serverPlans, datasources, isFetchingOrg]);

  useEffect(() => {
    if (checkoutSessionURL.length > 0)
      window.location.href = checkoutSessionURL;
  }, [checkoutSessionURL]);

  function handleSelectServerPlan(e: any, id: any) {
    e.preventDefault();

    if (selectedServerPlan !== id) setSelectedServerPlan(id);
    return;
  }

  function planWithoutPayment() {
    const body = {
      orgId: currentOrg.id,
      organizationId: currentOrg.id,
      new_plan: selectedServerPlan,
      current_plan: "false",
      id: user?.id,
      email: user?.email,
    };

    try {
      Axios.post(`${baseURL}/api/updateServerFromMigrationModal`, body).then(
        data => {
          dispatch(fetchOrg(currentOrg.id));
          setIsLoadingMigrateBtn(false);
          setShowMoveToNewWorkspaceModal(false);

          props.updModalState({
            workspaceMigrationModal: {
              isOpen: false,
            },
          });

          props.handleMigrateWorkspace(); // редірект на лоадер
        },
      );
    } catch (error) {
      sendUserLogs({
        userId: user.id,
        userEmail: user.email,
        type: "errorPlanWithoutPaymentFn",
        additionalData: error,
      });
    }

    return;
  }

  function updatePlan() {
    const body = {
      orgId: currentOrg.id,
      new_plan: selectedServerPlan,
      current_plan: "false",
      id: user?.id,
      email: user?.email,
    };

    try {
      Axios.post(`${baseURL}/api/monthlyPay`, body).then(({ data }) => {
        setCheckoutSessionURL(data);
        setIsLoadingMigrateBtn(false);
      });
    } catch (error) {
      sendUserLogs({
        userId: user.id,
        userEmail: user.email,
        type: "errorUpdatePlanFn",
        additionalData: error,
      });
    }

    return;
  }

  function handleMigrate() {
    setIsLoadingMigrateBtn(true);

    if (userBalanceFromStripe < -200) {
      planWithoutPayment();
    } else {
      updatePlan();
    }

    return;
  }

  function handleCloseModal() {
    setShowMoveToNewWorkspaceModal(false);

    props.updModalState({
      workspaceMigrationModal: {
        isOpen: false,
      },
    });

    if (user) {
      sendUserLogs({
        userId: user.id,
        userEmail: user.email,
        type: "closeWorkspaceMigrationModal",
      });
    }

    return;
  }

  return (
    <StyledWorkspaceMigrationModal
      width={500}
      title={"Workspace Migration"}
      open={isShowMoveToNewWorkspaceModal}
      onCancel={handleCloseModal}
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={selectedServerPlan === ""}
          onClick={handleMigrate}
          loading={isLoadingMigrateBtn}
        >
          Migrate
        </Button>,
      ]}
    >
      <p className="infoAboutMigration">
        Your previous subscription will be <b>canceled</b>, and all services
        within this workspace will be <b>restarted</b>. For more information,
        please continue{" "}
        <b>
          <a
            href="https://documentation.ubos.tech/docs/platform/server#how-to-upgrade-an-existing-workspace-to-a-server"
            target="_blank"
            rel="noreferrer"
          >
            reading
          </a>
        </b>
        .
      </p>
      <p>Choose a hardware for your Workspace</p>
      <ServerList>
        {serverPlans.length > 0 &&
          serverPlans.map((el: any) => {
            return (
              <ServerItem
                onClick={e => handleSelectServerPlan(e, el._id)}
                key={el._id}
                className={
                  el._id === selectedServerPlan
                    ? "selectedServerPlan-active"
                    : ""
                }
              >
                <p className="serverName">{el.description_name}</p>
                <span className="serverSize">
                  {el.cpu} vCPU • {el.ram} GB RAM{" "}
                </span>
                <p className="serverPrice">
                  {el._id === "65095119f74e030011361ae3"
                    ? "free"
                    : `$${el.quantity * 730}/ per month`}
                </p>
              </ServerItem>
            );
          })}
      </ServerList>
    </StyledWorkspaceMigrationModal>
  );
};

export default WorkspaceMigrationModal;
