import { AxiosPromise } from "axios";
import Api from "./Api";
import { ApiResponse } from "./ApiResponses";

class TemplateUiApi extends Api {
  static url = "v1/ui_template";
  static installPromotionTemplateFromPlatformURL =
    "installPromotionTemplateFromPlatform";

  static fetchTemplate() {
    return Api.get(TemplateUiApi.url + "/list");
  }

  static createTemplate(name: string, value: any) {
    return Api.post(TemplateUiApi.url + `/create`, { name, value });
  }
  static deleteTemplate(id: string) {
    return Api.delete(TemplateUiApi.url + `/${id}`);
  }

  static installPromotionTemplateFromPlatform(
    request: any,
  ): AxiosPromise<ApiResponse> {
    return Api.post(
      TemplateUiApi.installPromotionTemplateFromPlatformURL,
      request,
    );
  }
}

export default TemplateUiApi;
