import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Tooltip } from "@blueprintjs/core";
import {
  StyledSkeleton,
  Wrapper,
  WrapperCollapse,
  Title,
  ActionWrapp,
  EmptyProjectWrapper,
} from "./SidebarStyled";
import Folder from "./Folder";
import { FolderCreator } from "./FolderCreator";
import { DatabaseShowModal } from "../Explorer/PluginGroup/DatabaseShowModal";
import { AppState } from "../../../reducers";
import {
  openCreateServicePage,
  openTemplateManager,
} from "../../../utils/openLayoutItem";

import { ReactComponent as CloseArrow } from "../../../assets/images/work_space/close.svg";
import { ReactComponent as OpenArrow } from "../../../assets/images/work_space/open.svg";
import { ReactComponent as FolderCreateIcon } from "../../../assets/images/work_space/folder-create-icon.svg";
import { ReactComponent as FileCreateIcon } from "../../../assets/images/work_space/file-create-icon.svg";
import { getCurrentUser } from "selectors/usersSelectors";

const ServicesSidebar = (props: any) => {
  const user = useSelector((state: any) => getCurrentUser(state));
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );

  const { userPermissions } = props;

  const dispatch = useDispatch();

  const datasourcesList: any = useSelector(
    (state: AppState) => state.entities.datasources.list,
  );
  const isLoading: any = useSelector(
    (state: AppState) =>
      state.entities.datasources.isLoadingFetchingDatasourceInit,
  );

  const [datasources, setDatasources] = useState([]) as any;
  const [isOpenFolder, setIsOpenFolder] = useState(true);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [dbItem, setDbItem] = useState({});

  const showModalRef = useRef<{ openModal: any; setValue: any }>();

  useEffect(() => {
    setDatasources(datasourcesList);
  }, [datasourcesList]);

  const hasManagePermissions = useMemo(
    () =>
      userPermissions.some(
        (el: string) =>
          el === "manage:workspace" ||
          el === "manage:organizations" ||
          el === "manage:services",
      ),
    [userPermissions],
  );

  const toggleCollapse = useCallback(() => setIsOpenFolder(prev => !prev), []);

  return (
    <Wrapper>
      <WrapperCollapse style={{ background: "#dddddd" }}>
        <Title onClick={toggleCollapse}>
          <div>{isOpenFolder ? <OpenArrow /> : <CloseArrow />}</div>
          <span>EXPLORER</span>
        </Title>

        {hasManagePermissions && (
          <ActionWrapp className="service-actions-wrapper">
            <Tooltip content={"New Service"} usePortal={false}>
              <div>
                <FileCreateIcon
                  onClick={() =>
                    openCreateServicePage(dispatch, {
                      queryParams: {
                        org: currentOrg,
                        orgId: currentOrg,
                        app: currentApplication?.id,
                        folderId: undefined,
                        userId: user?.id,
                        userEmail: user?.email,
                      },
                    })
                  }
                />
              </div>
            </Tooltip>
            <Tooltip content={"New Folder"} usePortal={false}>
              <div style={{ padding: "1px" }}>
                <FolderCreateIcon onClick={() => setIsCreatingFolder(true)} />
              </div>
            </Tooltip>
          </ActionWrapp>
        )}
      </WrapperCollapse>

      <>
        {isLoading ? (
          <StyledSkeleton
            active
            title={false}
            paragraph={{
              width: "100%",
              rows: 3,
            }}
          />
        ) : (
          <>
            {datasources.length === 0 && !isCreatingFolder && (
              <EmptyProjectWrapper>
                {hasManagePermissions ? (
                  <>
                    <p>You don&apos;t have services yet</p>
                    <button
                      onClick={() =>
                        openCreateServicePage(dispatch, {
                          queryParams: {
                            org: currentOrg,
                            orgId: currentOrg,
                            app: currentApplication?.id,
                            folderId: undefined,
                            userId: user?.id,
                            userEmail: user?.email,
                          },
                        })
                      }
                    >
                      Create service
                    </button>
                    <p>You can clone a template</p>
                    <button onClick={() => openTemplateManager(dispatch)}>
                      Clone template
                    </button>
                    <p>
                      To learn more about the template, check out our{" "}
                      <a
                        href="https://ubos.tech/templates/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title={"Asset Marketplace"}
                      >
                        Asset Marketplace
                      </a>
                    </p>
                  </>
                ) : (
                  <>
                    <p>You do not have rights to create services</p>
                    <p>Contact the workspace administrator</p>
                  </>
                )}
              </EmptyProjectWrapper>
            )}
            {datasources.length > 0 && (
              <Collapse isOpen={isOpenFolder}>
                {datasources
                  .filter((el: any) => !el.parentId)
                  .map((item: any) => {
                    return (
                      <Folder
                        key={item.folderId || item._id}
                        folder={item}
                        setDbItem={setDbItem}
                        showModalRef={showModalRef}
                      />
                    );
                  })}
              </Collapse>
            )}
          </>
        )}
      </>

      {isCreatingFolder && (
        <FolderCreator
          setIsCreatingFolder={setIsCreatingFolder}
          isCreatingFolder={isCreatingFolder}
        />
      )}
      <DatabaseShowModal ref={showModalRef} item={dbItem} />
    </Wrapper>
  );
};

export default ServicesSidebar;
