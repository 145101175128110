import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { APPLICATIONS_URL } from "constants/routes";
import { Link } from "react-router-dom";
import { generateReactKey } from "utils/generators";
import { AppState } from "reducers";

const StyledModal = styled(Modal)`
  .leave-workspace-modal-title {
    font-size: 24px;
    margin: 0 5px;

    span {
      font-weight: 700;
    }
  }
`;

interface ILeaveWorkspaceModal {
  updModalState: (data: any) => void;
}

const LeaveWorkspaceModal = (props: ILeaveWorkspaceModal) => {
  const currentOrg = useSelector((state: AppState) => {
    return state.ui.orgs.currentOrg;
  });

  const handleCloseModal = () => {
    props.updModalState({
      leaveWorkspaceModal: {
        isOpen: false,
      },
    });
  };
  return (
    <StyledModal
      title={
        <p className="leave-workspace-modal-title">
          Are you sure you want to leave <span>{currentOrg.name}</span>{" "}
          workspace?
        </p>
      }
      open={true}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      footer={[
        <Link to={APPLICATIONS_URL} key={generateReactKey()}>
          <Button
            key={generateReactKey()}
            type="primary"
            onClick={handleCloseModal}
          >
            Leave
          </Button>
        </Link>,
      ]}
    ></StyledModal>
  );
};

export default LeaveWorkspaceModal;
