import React, { ReactNode } from "react";
import styled from "styled-components";
import { Collapse } from "@blueprintjs/core";
import { Colors } from "constants/Colors";

const TRACK_WIDTH = 1;
const steps: any = {
  0: "0.5",
  1: "0.3",
  2: "0.2",
};
const CollapsedContainer = styled.div<{ step: number; active?: boolean }>`
  overflow: hidden;
  &:before {
    content: "";
    width: ${TRACK_WIDTH}px;
    background: #6c767e;
    bottom: ${props => props.theme.spaces[2]}px;
    left: ${props =>
      (props.step + 0.6) * props.theme.spaces[2] + TRACK_WIDTH}px;
    top: ${props => -props.theme.spaces[2]}px;
    opacity: ${props => (steps[props.step] ? steps[props.step] : "0.2")};
    position: absolute;
  }
`;
export const EntityCollapse = (props: {
  children: ReactNode;
  isOpen: boolean;
  step: number;
  active?: boolean;
}) => {
  if (!props.children) return null;
  return (
    <Collapse isOpen={props.isOpen}>
      <CollapsedContainer step={props.step} active={props.active}>
        {props.children}
      </CollapsedContainer>
    </Collapse>
  );
};

export default EntityCollapse;
