import React, { Component } from "react";
import { connect } from "react-redux";
import { ReactComponent as CloseIcon } from "assets/icons/control/close.svg";
import { IconWrapper } from "../../constants/IconConstants";
import TransformEditor from "./TransformEditor";
import TransformEditorJS from "./TransformEditorJS";
import { ReactComponent as EditIcon } from "../../assets/icons/ads/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/ads/delete.svg";
import { ReactComponent as FullScreen } from "../../assets/icons/ads/full-screen.svg";
import { ReactComponent as ScreenExit } from "../../assets/icons/ads/full-screen-exit.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/control/more-vertical.svg";
import { ReactComponent as FunctionIcon } from "../../assets/icons/ads/function.svg";
import { ReactComponent as OnLoadIcon } from "../../assets/icons/ads/loading.svg";
import { updateWidgetName } from "../../actions/propertyPaneActions";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import { Popover, Typography } from "@material-ui/core";
import { updateWidgetPropertyRequest } from "../../actions/controlActions";
import { RenderModes } from "../../constants/WidgetConstants";

type State = {
  isOpen: boolean;
  isRename: boolean;
  openPopover: HTMLButtonElement | null;
  fullScreen: any;
};

class ItemTransformersMenu extends Component<any, any, State> {
  state = {
    isRename: false,
    openPopover: null,
    fullScreen: false,
    height: "218px",
    heightWrapper: "250px",
  };

  render() {
    return (
      <div
        className={"custom_item_transformer"}
        style={{
          borderBottom: "1px solid white",
          cursor: "pointer",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {!this.state.isRename ? (
            <div
              style={{
                padding: "10px",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                this.props.openItem(this.props.item);
              }}
            >
              <IconWrapper
                style={{ marginLeft: "7px", marginRight: "7px" }}
                onClick={() => {
                  this.setState({ isRename: true });
                }}
                width={15}
                height={15}
                background={"white"}
              >
                {this.props.item.typeFunction ? (
                  <FunctionIcon />
                ) : (
                  <OnLoadIcon />
                )}
              </IconWrapper>
              {this.props.item.widgetName}
            </div>
          ) : (
            <input
              style={{
                width: "100%",
                height: "38px",
                background: "#262626",
                color: "white",
                border: "none",
              }}
              ref={(component: any) => {
                component?.focus();
              }}
              onBlur={() => {
                this.setState({ isRename: false });
              }}
              onKeyPress={(event: any) => {
                if (event.charCode === 13) {
                  this.props.updateLayoutItem({
                    ...this.props.item,
                    widgetName: event.target.value,
                  });
                  this.props.updateWidgetName(
                    this.props.item.widgetId,
                    event.target.value,
                  );
                  this.setState({ isRename: false });
                }
              }}
              defaultValue={this.props.item.widgetName}
              type="text"
            />
          )}
          <IconWrapper
            style={{ marginLeft: "7px", marginRight: "7px" }}
            onClick={() => {
              this.setState({ isRename: true });
            }}
            width={23}
            height={23}
          >
            <EditIcon />
          </IconWrapper>
          <IconWrapper
            style={{ marginLeft: "7px", marginRight: "7px" }}
            onClick={() => {
              this.props.removeLayoutItem(this.props.item);
              this.props.deleteWidget(
                this.props.item.widgetId,
                this.props.item.parentId,
              );
            }}
            width={18}
            height={18}
          >
            <DeleteIcon />
          </IconWrapper>
          <IconWrapper
            onClick={(event: any) => {
              this.setState({ openPopover: event.currentTarget });
            }}
            style={{ marginLeft: "7px", marginRight: "7px" }}
            width={18}
            height={18}
          >
            <MoreIcon />
          </IconWrapper>
          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            anchorEl={this.state.openPopover}
            onClose={() => {
              this.setState({ openPopover: null });
            }}
            open={Boolean(this.state.openPopover)}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ background: "black" }}>
              <div
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  background: this.props.item.typeFunction
                    ? "#404040"
                    : "#262626",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  this.props.updateWidgetProperty(
                    this.props.item.widgetId,
                    "typeFunction",
                    true,
                  );
                  this.setState({ openPopover: null });
                }}
              >
                Function/Map
              </div>
              <div
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  background: this.props.item.typeFunction
                    ? "#262626"
                    : "#404040",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  this.props.updateWidgetProperty(
                    this.props.item.widgetId,
                    "typeFunction",
                    false,
                  );
                  this.setState({ openPopover: null });
                }}
              >
                OnLoad
              </div>
            </div>
          </Popover>
        </div>

        {this.props.open == this.props.item.widgetId && (
          <div
            style={{
              position: "fixed",
              bottom: "0px",
              right: "0px",
              width: "calc(100% - 320px)",
              height: this.props.item.typeFunction
                ? this.state.heightWrapper
                : "250px",
            }}
          >
            <IconWrapper
              style={{
                position: "absolute",
                right: "12px",
                top: "2px",
                width: "20px",
                zIndex: 5,
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.props.closeItem();
              }}
              color={"black"}
            >
              <CloseIcon />
            </IconWrapper>
            {this.props.item.typeFunction && (
              <IconWrapper
                style={{
                  position: "absolute",
                  right: "35px",
                  top: "7px",
                  width: "20px",
                  zIndex: 5,
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState((prev: any) => {
                    return {
                      fullScreen: !prev.fullScreen,
                      height: !prev.fullScreen ? "618px" : "218px",
                      heightWrapper: !prev.fullScreen ? "650px" : "250px",
                    };
                  });
                }}
                color={"black"}
              >
                {!this.state.fullScreen ? (
                  <FullScreen style={{ width: "19px", height: "19px" }} />
                ) : (
                  <ScreenExit style={{ width: "19px", height: "19px" }} />
                )}
              </IconWrapper>
            )}
            {!this.props.item.typeFunction ? (
              <>
                <div style={{ height: "65px" }}>
                  <TransformEditor
                    property={"expectation"}
                    height={"35px"}
                    title={"Dependent fields"}
                    value={this.props.item.expectation}
                    items={this.props.item}
                  />
                </div>
                <div style={{ height: "165px" }}>
                  <TransformEditor
                    property={"text"}
                    height={"153px"}
                    title={"Functions"}
                    value={this.props.item.text}
                    items={this.props.item}
                  />
                </div>
              </>
            ) : (
              <TransformEditorJS
                property={"text"}
                height={this.state.height}
                title={"Transformers"}
                value={this.props.item.text}
                items={this.props.item}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  updateWidgetName: (widgetId: string, newName: string) =>
    dispatch(updateWidgetName(widgetId, newName)),
  deleteWidget: (widgetId: string, parentId: string) =>
    dispatch({
      type: ReduxActionTypes.WIDGET_DELETE,
      payload: {
        widgetId,
        parentId,
      },
    }),
  updateWidgetProperty: (
    widgetId: string,
    propertyName: string,
    propertyValue: any,
  ) =>
    dispatch(
      updateWidgetPropertyRequest(
        widgetId,
        propertyName,
        propertyValue,
        RenderModes.CANVAS,
      ),
    ),
  updateLayoutItem: (item: any) => {
    return dispatch({
      type: ReduxActionTypes.UPDATE_LAYOUT_ITEM,
      payload: {
        id: item.widgetId,
        title: item.widgetName,
        component: "transformer",
        props: item,
      },
    });
  },
  removeLayoutItem: (item: any) => {
    return dispatch({
      type: ReduxActionTypes.REMOVE_LAYOUT_ITEM,
      payload: {
        id: item.widgetId,
        title: item.widgetName,
        component: "transformer",
        props: item,
      },
    });
  },
});

export default connect(null, mapDispatchToProps)(ItemTransformersMenu);
