import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, MenuProps } from "antd";
import { ToastType } from "react-toastify";
import Api from "api/Api";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { AppToaster } from "components/editorComponents/ToastComponent";
import ServiceActions from "./ServiceActions";
import { ListItem, Name } from "../Explorer/JSDependencies";
import { AppState } from "../../../reducers";

const Service = ({ items, showModalRef, setDbItem }: any) => {
  const dispatch = useDispatch();

  const datasourcesList: any = useSelector(
    (state: AppState) => state.entities.datasources.list,
  );

  const [currentElement, setCurrentElement] = useState(null) as any;
  const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);

  const moveToRequest = async (folderId: string) => {
    if (currentElement?.id && folderId) {
      try {
        const response = await Api.put("v2/datasources/moveToFolder", {
          datasourceId: currentElement.id,
          folderId,
        });

        if (response.data.error) {
          AppToaster.show({
            message: response.data.message
              ? response.data.message
              : "Something went wrong",
            type: ToastType.WARNING,
          });
        } else {
          dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
        }
      } catch (error) {
        AppToaster.show({
          message: "Something went wrong. Try again",
          type: ToastType.WARNING,
        });
        console.error("moveToRequest error:", error);
      }
    }
  };

  const openLayoutItemActions = {
    OPEN_LAYOUT_TAB: (item: any) => {
      dispatch({
        type: ReduxActionTypes.SET_LAYOUT_ITEM,
        payload: { ...item.layoutData, icon: item?.icon },
      });
    },
    OPEN_MODAL_INFO: (item: any) => {
      setDbItem(item);
      showModalRef?.current?.openModal();
    },
  } as any;

  const handleOpenLayoutItem = useCallback(
    item => {
      const actionType = item.serviceActions.find(
        (actionType: any) => openLayoutItemActions[actionType],
      );
      if (actionType) {
        openLayoutItemActions[actionType](item);
      }
    },
    [dispatch],
  );

  const folders = datasourcesList
    .filter((el: any) => el.folderName)
    .map((el: any) => {
      return {
        key: el.folderId,
        label: el.folderName,
        className: "sidebar-service-item",
        onClick: () => moveToRequest(el.folderId),
      };
    })
    .concat({
      key: "ROOT",
      label: "Root",
      className: "sidebar-service-item",
      onClick: () => moveToRequest("ROOT"),
    });

  const contextMenuConfig = [
    {
      label: "Move to",
      children: folders,
    },
  ];

  const contextMenu: MenuProps["items"] = contextMenuConfig.map((el, idx) => ({
    ...el,
    key: idx,
    disabled: currentElement?.actionPermission === "read:services",
    className: "sidebar-service-item",
    title:
      currentElement?.actionPermission === "read:services"
        ? "No permission"
        : el.label,
  }));

  return (
    <>
      {items.map((item: any) => {
        const contextMenuClassName =
          item.id === currentElement?.id && isOpenContextMenu
            ? "open-context-menu"
            : "";

        return (
          <>
            <ListItem
              tabIndex={0}
              style={
                contextMenuClassName ? { border: "1px solid #3b82ff" } : {}
              }
            >
              <Dropdown
                menu={{ items: contextMenu }}
                trigger={["contextMenu"]}
                key={item._id}
                onOpenChange={e => {
                  setIsOpenContextMenu(e);
                  setCurrentElement(item);
                }}
                placement="bottomLeft"
              >
                <Name
                  // className={`${item.status} ${contextMenuClassName}`}
                  // onContextMenu={e => handleContextMenu(e, item)}
                  onClick={() =>
                    item?.status === "good" && handleOpenLayoutItem(item)
                  }
                  style={{ flexGrow: 1 }}
                >
                  <img
                    width={12}
                    src={
                      item.icon ||
                      "https://ubos.tech/wp-content/uploads/2024/03/service-default-logo.svg"
                    }
                    alt={item.name}
                  />
                  <span className="dots" title={item.name}>
                    {item.name}
                  </span>
                </Name>
              </Dropdown>
              <span
                title={item.statusLabel && item.statusLabel}
                className={item.status}
                style={{
                  display: "flex",
                  alignItems: "center",
                  pointerEvents: "auto",
                }}
              ></span>

              <ServiceActions currentElement={item} />
            </ListItem>
          </>
        );
      })}
    </>
  );
};

export default Service;
