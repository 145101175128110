import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, MenuProps } from "antd";
import styled from "styled-components";
import { generateReactKey } from "utils/generators";
import { sendUserLogs } from "api/userLogs";
import { Datasource } from "api/DatasourcesApi";
import { getDataSources } from "selectors/editorSelectors";
import { getUserTours } from "selectors/usersSelectors";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { ReactComponent as DiscordIcon } from "../../assets/images/work_space/icon-discord.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/images/work_space/icon-youtube.svg";
import { ReactComponent as CommunityIcon } from "../../assets/images/work_space/teamwork-icon.svg";
import { ReactComponent as Help } from "../../assets/images/work_space/help-circle.svg";

const StyledHelpIcon = styled(Help)`
  margin-right: 10px;
  cursor: pointer;
`;

const SocialIconItem = styled.div`
  margin-right: 10px;

  :last-child {
    margin-right: 0px;
  }
  a {
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      margin-left: 5px;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

const socialIcons = [
  {
    Icon: DiscordIcon,
    href: "https://discord.com/invite/dt59QaptH2",
    title: "Discord",
  },
  {
    Icon: YouTubeIcon,
    href: "https://www.youtube.com/@ubos_tech",
    title: "YouTube",
  },
  {
    Icon: CommunityIcon,
    href: "https://community.ubos.tech/",
    title: "Community",
  },
];

const InfoList = ({ userInfo }: IInfoList) => {
  const dispatch = useDispatch();
  const userTours = useSelector(getUserTours);
  const datasources: Datasource[] = useSelector(getDataSources);

  const onStartTour = (tourName: string) => {
    dispatch({
      type: ReduxActionTypes.UPD_USER_TOUR,
      payload: {
        [tourName]: {
          ...userTours[tourName],
          isOpen: true,
          automatically: true,
        },
      },
    });
  };

  const tours = Object.keys(userTours).filter(
    el => el !== "initialTour" && el !== "howToOpenServiceOrTemplate",
  );
  const items: MenuProps["items"] = [
    {
      key: "1",
      className: "info-list-item",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://documentation.ubos.tech/"
        >
          Documentation
        </a>
      ),
    },
    {
      key: "2",
      label: "Tours",
      className: "info-list-item",
      children: tours.map(tourName => {
        return {
          key: generateReactKey(),
          label: `${userTours[tourName].label}`,
          onClick: () => onStartTour(tourName),
          disabled: tourName === "openService" && datasources.length === 0,
        };
      }),
    },
    {
      key: "3",
      label: "Social networks",
      className: "info-list-item",
      children: socialIcons.map((item, index) => {
        return {
          key: generateReactKey(),
          label: (
            <SocialIconItem
              key={index}
              onClick={() =>
                sendUserLogs({
                  type: `open_${item.title}LinkInHeader`,
                  userId: userInfo.userId,
                  userEmail: userInfo.userEmail,
                })
              }
            >
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                title={item.title}
              >
                <item.Icon /> <span>{item.title}</span>
              </a>
            </SocialIconItem>
          ),
        };
      }),
    },
  ];
  return (
    <Dropdown
      menu={{ items }}
      placement="bottomLeft"
      arrow={{ pointAtCenter: true }}
      trigger={["click"]}
    >
      <StyledHelpIcon />
    </Dropdown>
  );
};

interface IInfoList {
  userInfo: {
    userId: string;
    userEmail: string;
  };
}

export default InfoList;
