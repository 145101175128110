import React from "react";

import Generators from "../../components/editorComponents/Generators";
import TransformersMenu from "../../components/editorComponents/transformersMenu";
import PropertyPane from "./PropertyPane";
import styled from "styled-components";
import { Route, Switch } from "react-router";
import { BUILDER_URL } from "../../constants/routes";
import WidgetsEditor from "./WidgetsEditor";
import EditorsRouter from "./routes";
import * as Sentry from "@sentry/react";
import { LayoutUi } from "./LayoutUi";

const SentryRoute = Sentry.withSentryRouting(Route);

const EditorContainer = styled.div`
  position: relative;
`;

const WrapperStyleSettingsWidget = styled.div`
  background: #fafbfc;
  pposition: relative;
  z-index: 2;
  height: 100%;
  padding: 1px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid slategrey;
    border: 1px solid white;
  }

  .preloader {
    position: absolute;
    z-index: 0;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 41px;
    width: 100%;
    text-align: center;
    color: #6c767e;
  }
  .relative {
    position: relative;
    z-index: 1;
  }
`;

export const UiEditor = () => {
  return (
    <div>
      <LayoutUi></LayoutUi>
    </div>
  );
};

export const GeneratorsMenu = () => {
  return (
    <div
      style={{
        background: "#262626",
        height: "100%",
        width: "100%",
        color: "white",
        position: "relative",
        zIndex: 2,
      }}
    >
      <Generators />
      <TransformersMenu />
    </div>
  );
};

export const WrapperSettingsWidget = () => {
  return (
    <WrapperStyleSettingsWidget>
      <div className="preloader">widget not selected</div>
      <div className="relative">
        <PropertyPane />
      </div>
    </WrapperStyleSettingsWidget>
  );
};

export const EditorPage = () => {
  return (
    <EditorContainer>
      <Switch>
        <SentryRoute exact path={BUILDER_URL} component={WidgetsEditor} />
        <SentryRoute component={EditorsRouter} />
      </Switch>
    </EditorContainer>
  );
};
