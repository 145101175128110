import React, { useState, useEffect, useRef } from "react";
import { Tour, TourProps } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getDataSources } from "selectors/editorSelectors";
import { ReduxActionTypes } from "constants/ReduxActionConstants";
import { Datasource, ServiceType } from "api/DatasourcesApi";
import {
  CopyServiceIcon,
  DeleteServiceIcon,
  OpenServiceIcon,
  SettingsServiceIcon,
} from "../Explorer/ActionButtons";

const ServiceDetailsList = styled.ul`
  padding: 0;
  list-style: none;

  li {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      margin: 0;
    }
  }
`;

const OpenServiceTour = (props: ICreateServiceTour) => {
  const dispatch = useDispatch();
  const datasources: Datasource[] = useSelector(getDataSources);

  const refSidabar = useRef<any>(null);
  const refCollapse = useRef<any>(null);
  const refCollapseElement = useRef<any>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isOpenTour, setIsOpenTour] = useState<boolean>(false);
  function getAllDatasources(data) {
    const datasources = [];

    // Iterate over each object in the data array
    for (const obj of data) {
      // Check if the object has a "datasources" property
      if (obj.hasOwnProperty("datasources")) {
        // If it does, concatenate its "datasources" array to the results
        datasources.push(...obj.datasources);
      }

      // Recursively call the function on children if it's a folder
      if (obj.isFolder && obj.children) {
        datasources.push(...getAllDatasources(obj.children));
      }
    }

    return datasources;
  }
  const dasourceList = getAllDatasources(datasources);
  const findFirsServiceInDS = (): ServiceType | undefined => {
    const firstServiceType = dasourceList.filter(obj =>
      obj.serviceActions.includes("OPEN_LAYOUT_TAB"),
    )[0]?.type;

    return firstServiceType;
  };

  useEffect(() => {
    const sidebarDOM = document.querySelector(".sidebar-tour-step");

    refSidabar.current = sidebarDOM;
  }, []);

  useEffect(() => {
    if (dasourceList.length > 0) {
      const firstServiceType = findFirsServiceInDS();

      if (firstServiceType) {
        const collapseToClassMap = {
          api: {
            box: ".nr-collapse-tour-step",
            item: ".nr-collapse > :nth-child(2)",
          },
          ui: {
            box: ".ui-collapse-tour-step",
            item: ".ui-collapse > :nth-child(2)",
          },
          db: {
            box: ".db-collapse-tour-step",
            item: ".db-collapse > :nth-child(2)",
          },
        };

        const {
          box: selectedCollapseBoxClass,
          item: selectedCollapseItemClass,
        } = collapseToClassMap[firstServiceType] || {};

        const assignRef = (
          ref: React.MutableRefObject<any>,
          selectedClass: string,
        ) => {
          if (selectedClass) {
            const selectedDOM = document.querySelector(selectedClass);
            if (selectedDOM) ref.current = selectedDOM;
          }
        };

        assignRef(refCollapse, selectedCollapseBoxClass);
        assignRef(refCollapseElement, selectedCollapseItemClass);

        setIsOpenTour(true);
      }
    } else {
      // props.handleFinishTour();
      dispatch({
        type: ReduxActionTypes.UPD_USER_TOUR,
        payload: {
          ["openService"]: {
            isOpen: false,
            label: "How to open service",
          },
        },
      });
    }
  }, [datasources]);

  const handleClickNextStep = () => {
    setCurrentStep(prevState => prevState + 1);
  };

  const handleClickBackStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  // const handleFinishTour = () => {
  //   if (props.tourInfo.automatically) {
  //     const { automatically, ...tourInfo } = props.tourInfo;

  //     dispatch({
  //       type: ReduxActionTypes.UPD_USER_TOUR,
  //       payload: {
  //         [props.tourInfo.name]: {
  //           ...tourInfo,
  //           isOpen: false,
  //         },
  //       },
  //     });
  //   } else {
  //     props.updTourState(props.tourInfo.name);
  //   }
  // };

  const serviceDetails = [
    { icon: <OpenServiceIcon />, text: "Open service" },
    {
      icon: <SettingsServiceIcon iconSize={20} />,
      text: "View information about the service",
    },
    { icon: <CopyServiceIcon />, text: "Copy the link to the service" },
    { icon: <DeleteServiceIcon iconSize={20} />, text: "Delete the service" },
  ];

  const steps: TourProps["steps"] = [
    {
      placement: "right",
      title: "A list of your services is displayed here",
      target: () => refSidabar?.current,
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      prevButtonProps: {
        style: { display: "none" },
      },
      onClose: handleClickNextStep,
    },
    {
      placement: "rightBottom",
      title:
        "If you move the mouse cursor over the service, you will see icons.",
      target: () => refCollapse?.current,
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      onClose: handleClickNextStep,
      cover: (
        <ServiceDetailsList>
          {serviceDetails.map((item, index) => (
            <li key={index}>
              {item.icon}
              {item.text}
            </li>
          ))}
        </ServiceDetailsList>
      ),
      className: "tour-step-service-action-buttons",
    },
    {
      placement: "right",
      title: "To open the created service, you need to click on it",
      target: () => refCollapseElement?.current,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      onClose: () => props.handleFinishTour(),
    },
  ];

  return (
    <Tour
      open={isOpenTour}
      steps={steps}
      current={currentStep}
      onFinish={() => props.handleFinishTour()}
    />
  );
};

interface ICreateServiceTour {
  handleFinishTour: () => void;
}

export default OpenServiceTour;
