import { Button, Icon, Overlay, Tooltip } from "@blueprintjs/core";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  createNodeRed,
  getList,
  getTypeSystemNodeRed,
} from "../../../../components/designSystems/appsmith/help/FileManagerApi";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../reducers";
import { ReduxActionTypes } from "../../../../constants/ReduxActionConstants";
import { storeAsDatasourceApi } from "../../../../actions/datasourceActions";
import ClickAwayListener from "react-click-away-listener";
import { FormIcons } from "../../../../icons/FormIcons";
import { theme } from "../../../../constants/DefaultTheme";
import AnalyticsUtil from "../../../../utils/AnalyticsUtil";
import { AppToaster } from "../../../../components/editorComponents/ToastComponent";
import { ToastType } from "react-toastify";
import { ModalInform } from "../../../../components/designSystems/component/ModalInform";
import { ModalDelete } from "../../../../components/designSystems/component/ModalDelete";
import {
  createIdEditorPage,
  createIdNodeRed,
  NodeRed,
  savedSettings,
} from "pages/Editor/DoclLayoutSettings";
import { CloseCircleOutlined } from "@ant-design/icons";

export const WrapperItemInput = styled.div`
  margin-top: 20px;
  width: 500px;
  input {
    width: 100%;
  }
  .validation {
    color: red;
  }
  .wrapper_env {
    position: relative;
    display: flex;
    justify-content: space-between;
    .delete_env {
      position: absolute;
      right: -3px;
      top: -2px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      align-items: center;
      text-align: center;
    }
    .wrapper_item {
      width: 49%;
    }
  }
`;
export const WrapperSelect = styled.div`
  select {
    width: 100%;
    height: 30px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const WrapperModal = styled.div`
  position: fixed;
  z-index: 55555;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  color: black;
  .bp3-input:focus {
    box-shadow: none;
  }
  .title_input {
    font-size: 15px;
    line-height: 23px;
    color: #1e272e;
    margin-bottom: 5px;
    font-weight: 600;
    font-family: "Roboto";
  }
  .title {
    font-size: 30px;
    line-height: 23px;
    color: #1e272e;
    margin-bottom: 15px;
    font-weight: 600;
    font-family: "Roboto";
  }
  .button {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
    background: #3b82ff;
    border-radius: 4px;
    font-family: "Roboto";
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 156px;
    line-height: 22px;
    color: #ffffff;
  }
  .button:disabled {
    border: 1px solid #dddddd;
    border-radius: 2px;
    height: 32px;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
    background: #dddddd;
    border-radius: 4px;
    font-family: "Roboto";
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    width: 156px;
    line-height: 22px;
    color: #ffffff;
  }
  input {
    height: 34px;
    background: #e9efff;
    border-radius: 4px;
    padding: 8px 16px;
    border: none;
    width: 100%;
    color: #1e272e;
    font-family: "Roboto";
    font-size: 14px;
    line-height: 18px;
    box-shadow: none;
  }
  select {
    height: 34px;
    background: #e9efff;
    border-radius: 4px;
    padding: 8px 16px;
    font-family: "Roboto";
    border: none;
    color: #1e272e;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
  }
`;
export const CloseIcon = styled.div`
  position: absolute;
  right: 40px;
  top: 32px;
  cursor: pointer;
`;
const DeleteIcon = FormIcons.DELETE_ICON;

export const ModalNodeRed = forwardRef((props: any, ref) => {
  const [arrayEnv, setArrayEnv] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [systemType, setSystemType] = useState("");
  const [systemTypes, setSystemTypes] = useState([]);
  const [gitUrl, setGitUrl] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [gitName, setGitName] = useState("");
  const [gitPassword, setGitPassword] = useState("");
  const [resNodeRed, setResNodeRed] = useState<any>(null);
  const [preloader, setPreloader] = useState(false);
  const [inform, setInform] = useState(false);
  const refModalInform = useRef<{ openModal: any }>();

  const dispatch = useDispatch();
  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );
  const currentApplication = useSelector(
    (state: AppState) => state.ui.applications.currentApplication,
  );
  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );

  const dockLayoutItem = useSelector((state: AppState) => {
    return state.layout.dockLayoutItem;
  });

  const openModalInform = useCallback(() => {
    refModalInform?.current?.openModal();
  }, []);

  const toggleOverlay = useCallback(() => {
    setIsOpen((prev: boolean) => {
      if (prev) {
        setGitUrl("");
        setGitName("");
        setGitPassword("");
        setProjectName("");
        setDisabled(false);
        setArrayEnv([]);
      }
      return !prev;
    });
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    (async () => {
      if (keycloakAccessToken) {
        try {
          const res = await getTypeSystemNodeRed(keycloakAccessToken);
          setSystemTypes(res.data.results);
          setSystemType(res.data.results[0].name);
        } catch (e) {}
      }
    })();
  }, [keycloakAccessToken]);
  const getTokenBase = async () => {
    setPreloader(true);
    const body = createData(
      currentOrg,
      gitName,
      projectName,
      gitUrl,
      gitPassword,
      gitName,
      currentApplication?.id,
      systemType,
      arrayEnv,
    );
    if (keycloakAccessToken) {
      const { data } = await createNodeRed(body, keycloakAccessToken);
      if (data.data.error) {
        setInform(data.data.message);
        openModalInform();
        AppToaster.show({
          message: `${data.data.message}`,
          type: ToastType.ERROR,
        });
        setPreloader(false);
        // toggleOverlay();
      } else {
        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
        setPreloader(false);
        toggleOverlay();
        const newItem = {
          id: data.data.name,
          component: "Loading",
          title: data.data.name,
          props: "https://" + data.data.url,
          subtype: "nodered",
        };
        dispatch({
          type: ReduxActionTypes.SET_LAYOUT_ITEM,
          payload: newItem,
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    openModal() {
      toggleOverlay();
    },
    setValue(item: any) {
      setGitUrl(item.gitUrl);
      setGitName(item.gitName);
      setGitPassword("*******");
      setGitPassword(item.systemType);
      setProjectName(item.projectName);
      setDisabled(true);
      setArrayEnv(item.env);
      toggleOverlay();
    },
  }));

  const validation = useMemo(() => {
    let validEnv = true;
    for (const item of arrayEnv) {
      if (item.name.length === 0 || item.value.length === 0) {
        validEnv = false;
        break;
      }
    }
    return projectName.length > 0 && validEnv;
  }, [projectName, arrayEnv]);
  const validationName = useMemo(() => {
    const pattern = /^[a-zA-Z0-9]*$/;
    const patternNumber = /^[0-9]*$/;
    const patternText = /^[a-zA-Z]*$/;

    if (projectName.length > 0) {
      if (!patternText.test(projectName[0])) {
        return false;
      }
    }

    return (
      projectName.length > 0 &&
      pattern.test(projectName) &&
      projectName.length < 14 &&
      !patternNumber.test(projectName)
    );
  }, [projectName]);

  const addItemToEnv = useCallback(() => {
    const newArray = [...arrayEnv, { name: "", value: "" }];
    setArrayEnv(newArray);
  }, [arrayEnv]);
  const changeEnvValue = useCallback(
    (index: number, e: any) => {
      const newArray = [...arrayEnv];
      newArray[index].value = e.target.value;
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  const changeEnvKey = useCallback(
    (index: number, e: any) => {
      const newArray = [...arrayEnv];
      newArray[index].name = e.target.value;
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );
  const deleteEnv = useCallback(
    (index: number) => {
      const newArray = [...arrayEnv];
      newArray.splice(index, 1);
      setArrayEnv(newArray);
    },
    [arrayEnv],
  );

  return (
    <div>
      {isOpen ? (
        <WrapperModal>
          <div>
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <div
                style={{
                  padding: "40px 40px 30px 40px",
                  background: "white",
                  position: "relative",
                  borderRadius: "10px",
                }}
              >
                <div className="title">New Flow Builder</div>
                <CloseIcon>
                  <Icon
                    onClick={toggleOverlay}
                    iconSize={16}
                    color={"black"}
                    icon="cross"
                  />
                </CloseIcon>
                <WrapperItemInput>
                  <span className="title_input">Project name</span>
                  <div>
                    <input
                      type="text"
                      className="bp3-input"
                      value={projectName}
                      onChange={e => setProjectName(e.target.value)}
                      placeholder="Enter Project name"
                      disabled={disabled}
                    />
                  </div>
                  {projectName.length != 0 && !validationName && (
                    <div className="validation">
                      the name must be without spaces and not exceed 14
                      characters and first symbol must be a letter
                    </div>
                  )}
                </WrapperItemInput>
                <WrapperItemInput>
                  <span className="title_input">System type</span>
                  <WrapperSelect>
                    <select
                      placeholder="Enter System Type"
                      value={systemType}
                      onChange={e => setSystemType(e.target.value)}
                      disabled={disabled}
                    >
                      {systemTypes?.map((item: any) => {
                        return (
                          <option key={item.name} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </WrapperSelect>
                </WrapperItemInput>
                {arrayEnv.map((item: any, index: number) => {
                  return (
                    <WrapperItemInput key={index}>
                      <div className="wrapper_env">
                        <div className="wrapper_item">
                          {!disabled && (
                            <span
                              className="delete_env"
                              onClick={() => {
                                deleteEnv(index);
                              }}
                            >
                              <CloseCircleOutlined />
                            </span>
                          )}
                          <div>
                            <span className="title_input">Key</span>
                            <input
                              className="bp3-input"
                              value={arrayEnv[index].name}
                              onChange={e => {
                                changeEnvKey(index, e);
                              }}
                              placeholder="Key"
                              disabled={disabled}
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="wrapper_item">
                          <span className="title_input">Value</span>
                          <div>
                            <input
                              className="bp3-input"
                              value={arrayEnv[index].value}
                              placeholder="Value"
                              onChange={e => {
                                changeEnvValue(index, e);
                              }}
                              disabled={disabled}
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                    </WrapperItemInput>
                  );
                })}
                <WrapperItemInput>
                  <button className="button" onClick={addItemToEnv}>
                    Create New Env
                  </button>
                </WrapperItemInput>
                <WrapperItemInput>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      className="button"
                      onClick={getTokenBase}
                      disabled={!validation || !validationName || disabled}
                    >
                      Create NodeRed
                    </button>
                  </div>
                </WrapperItemInput>
              </div>
            </div>
            {preloader && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "fixed",
                  zIndex: 5,
                }}
              >
                <div className="lds-spinner" id="lds">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
            <ModalInform ref={refModalInform} inform={inform}></ModalInform>
          </div>
        </WrapperModal>
      ) : (
        ""
      )}
    </div>
  );
});
ModalNodeRed.displayName = "ModalNodeRed";

function createData(
  currentOrg: any,
  userName: string,
  projectName: string,
  gitUrl: string,
  gitPassword: string,
  gitUserName: string,
  applicationId = "",
  systemType = "",
  arrayEnv: any,
) {
  const data = {
    orgId: currentOrg,
    application_id: applicationId,
    project_name: projectName,
    nodered_image_tag: systemType,
    env: arrayEnv,
  };
  return data;
}
