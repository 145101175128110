import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Tour, TourProps, Button } from "antd";
import styled from "styled-components";
import Axios from "axios";
import { getCurrentUser } from "selectors/usersSelectors";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const InitialTour = (props: IInitialTour) => {
  const [isShowTour, setIsShowTour] = useState(true);
  const refAssetMarketplaceMenuItem = useRef<any>(null);
  const refCreateServiceButton = useRef<any>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const user = useSelector((state: any) => getCurrentUser(state));

  const {
    handleOpenDemoTemplate,
    handleLeftMenu,
    handleStarTemplateOrServiceTour,
    tourtemplate,
  } = props.additionalProps;

  useEffect(() => {
    const assetMarketplaceDOM = document.querySelector(
      ".asset-marketplace-tour-step",
    );

    const createServiceBtnDOM = document.querySelector(
      ".create-service-btn-tour-step",
    );

    refAssetMarketplaceMenuItem.current = assetMarketplaceDOM;
    refCreateServiceButton.current = createServiceBtnDOM;
    const templateIdFromCookie = document.cookie.replace(
      /(?:(?:^|.*;\s*)templateId\s*\=\s*([^;]*).*$)|^.*$/,
      "$1",
    );

    if (templateIdFromCookie) {
      handleLeftMenu();
      handleStarTemplateOrServiceTour();
      handleFinishTour();

      addUserToSegment(13);

      const showOrHideLeftMenuIconDOM = document.querySelector(
        ".services-tour-step",
      );
      showOrHideLeftMenuIconDOM?.classList.add("pulsate");
    }
  }, []);

  const addUserToSegment = (segment: number) => {
    try {
      Axios.post("https://platform.ubos.tech/api/mautic/addUserToSegment", {
        segment,
        userEmail: user?.email,
      });
    } catch (error) {
      console.log("ERROR | Send to mautic", error);
    }
  };

  const handleClickOpenTemplate = () => {
    handleOpenDemoTemplate();
    handleLeftMenu();
    handleStarTemplateOrServiceTour();
    handleFinishTour();

    addUserToSegment(13);

    const showOrHideLeftMenuIconDOM = document.querySelector(
      ".services-tour-step",
    );
    showOrHideLeftMenuIconDOM?.classList.add("pulsate");
  };

  const handleClickNextStep = () => {
    addUserToSegment(14);
    setCurrentStep(prevState => prevState + 1);
  };

  const handleClickBackStep = () => {
    setCurrentStep(prevState => prevState - 1);
  };

  const handleFinishTour = () => {
    setIsShowTour(false);
    props.handleFinishTour();
  };

  const stepsCreateService: TourProps["steps"] = [
    {
      placement: "center",
      title: "",
      description: "",
      cover: (
        <div>
          <iframe
            style={{ borderRadius: "8px" }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/_KpigjTKChg?si=jND5JHKDRcbZUh-5"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <h2>Discover how UBOS Boosts Your Work and Team</h2>
          <p>
            Uncover UBOS benefits for your work and team. Explore the core
            principles of the remarkable UBOS Platform on an exciting journey!
          </p>
          <ButtonWrapper>
            {/* {tourtemplate ? (
              <></>
            ) : (
              <Button
                style={{
                  width: "160px",
                }}
                type="primary"
                size="middle"
                onClick={handleClickOpenTemplate}
              >
                Try AI Bot
              </Button>
            )} */}
            <Button
              size="middle"
              onClick={handleClickNextStep}
              style={{
                width: "160px",
              }}
            >
              Explore the platform
            </Button>
            <Button
              onClick={handleClickNextStep}
              style={{
                height: "inherit",
                border: "none",
                top: "-1.5%",
                left: "92%",
                position: "absolute",
                color: "white",
                fontSize: "32px",
                fontWeight: 600,
                background: "transparent",
                cursor: "pointer",
              }}
            >
              ×
            </Button>
          </ButtonWrapper>
        </div>
      ),
      style: {
        width: "560px",
      },
      className: "tour-create-service",
      nextButtonProps: {
        style: { display: "none" },
      },
    },
    {
      placement: "rightBottom",
      title: "Asset Marketplace",
      description: `Asset Marketplace is a repository of reusable, templates, app
      blueprints, connectors, UI components, tools and business solutions
      to help you swiftly kickstart your product or solution on the UBOS
      platform`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/04/template-gif.gif"}
          alt="How to clone template"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: handleClickNextStep,
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      nextButtonProps: {
        onClick: handleClickNextStep,
      },
      target: () => refAssetMarketplaceMenuItem.current,
    },
    {
      placement: "rightBottom",
      title: "Create service",
      description: `The "Services" in UBOS allows you to manage various services, including the Flow Builder, UI editor, and Database. This module provides a centralized location to configure and work with these services efficiently.`,
      cover: (
        <img
          src={"https://ubos.tech/wp-content/uploads/2024/01/service.gif"}
          alt="How to create service"
        />
      ),
      style: {
        width: "600px",
      },
      onClose: () => handleFinishTour(),
      prevButtonProps: {
        onClick: handleClickBackStep,
      },
      target: () => refCreateServiceButton.current,
    },
  ];

  return (
    <Tour
      open={isShowTour}
      steps={stepsCreateService}
      current={currentStep}
      onFinish={() => handleFinishTour()}
    />
  );
};

interface IInitialTour {
  handleFinishTour: () => void;
  additionalProps?: any;
}

export default InitialTour;
