import React, { useState } from "react";
import { useDrag, DragPreviewImage } from "react-dnd";
import blankImage from "assets/images/blank.png";
import { WidgetCardProps } from "widgets/BaseWidget";
import styled from "styled-components";
import { WidgetIcons } from "icons/WidgetIcons";
import { ReactComponent as DeleteIcon } from "assets/icons/ads/delete.svg";

import {
  useWidgetDragResize,
  useShowPropertyPane,
} from "utils/hooks/dragResizeHooks";
import AnalyticsUtil from "utils/AnalyticsUtil";
import { generateReactKey } from "utils/generators";
import { Colors } from "constants/Colors";

type CardProps = {
  details: WidgetCardProps & { _id?: string };
  deleteIcon?: any;
};
export const WrapperDeleteIcon = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  &:hover {
    cursor: pointer;
    background: #6d6d6d;
  }
`;
export const Wrapper = styled.div`
  padding: 10px 5px 10px 5px;
  border-radius: 0px;
  border: none;
  position: relative;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  .icon_label:hover {
    color: #3b82ff;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  svg {
    path {
      fill: #d9d9d9;
    }
    rect {
      stroke: #d9d9d9;
    }
  }
  &:hover {
    background: #f3f3f3;
    cursor: grab;
    svg {
      path {
        fill: #3b82ff;
      }
      rect {
        stroke: #3b82ff;
      }
    }
  }
  & i {
    font-family: ${props => props.theme.fonts.text};
    font-size: ${props => props.theme.fontSizes[7]}px;
  }
`;

export const IconLabel = styled.h5`
  text-align: center;
  margin: 0;
  font-weight: normal;
  flex-shrink: 1;
  font-size: 10px;
  line-height: 12px;
  color: #6c767e;
  &::selection {
    background: none;
  }
  &:hover {
    color: #3b82ff;
  }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
const WidgetCard = (props: CardProps) => {
  const { setIsDragging } = useWidgetDragResize();
  // Generate a new widgetId which can be used in the future for this widget.
  const [widgetId, setWidgetId] = useState(generateReactKey());
  const showPropertyPane = useShowPropertyPane();
  const [, drag, preview] = useDrag({
    item: { ...props.details, widgetId },
    begin: () => {
      AnalyticsUtil.logEvent("WIDGET_CARD_DRAG", {
        widgetType: props.details.type,
        widgetName: props.details.widgetCardName,
      });
      showPropertyPane && showPropertyPane(undefined);
      setIsDragging && setIsDragging(true);
    },
    end: (widget, monitor) => {
      AnalyticsUtil.logEvent("WIDGET_CARD_DROP", {
        widgetType: props.details.type,
        widgetName: props.details.widgetCardName,
        didDrop: monitor.didDrop(),
      });
      // We've finished dragging, generate a new widgetId to be used for next drag.
      setWidgetId(generateReactKey());
      setIsDragging && setIsDragging(false);
    },
  });

  const iconType: string = props.details.type;
  const Icon = WidgetIcons[iconType];
  const className = `t--widget-card-draggable-${props.details.type
    .split("_")
    .join("")
    .toLowerCase()}`;
  return (
    <React.Fragment>
      <DragPreviewImage connect={preview} src={blankImage} />
      <Wrapper ref={drag} className={className}>
        {props.deleteIcon && (
          <WrapperDeleteIcon
            onClick={() => {
              props.deleteIcon(props.details._id);
            }}
          >
            <DeleteIcon
              style={{ position: "absolute", right: "5px", top: "5px" }}
            ></DeleteIcon>
          </WrapperDeleteIcon>
        )}
        <div>
          <Icon />
          <IconLabel className="icon_label">
            {props.details.widgetCardName}
          </IconLabel>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default WidgetCard;
