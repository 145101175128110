import { AppState } from "reducers";
import { User } from "constants/userConstants";

export const getCurrentUser = (state: AppState): User | undefined => {
  return state.ui.users.currentUser;
};

export const getUserTours = (state: AppState): any => {
  return state.ui.users.userTours;
};
export const getWorkspaceTour = (state: AppState): any => {
  return state.ui.users.workspaceTour;
};

export const getUserBalance = (state: AppState): any => {
  return state.ui.users.userBalance;
};

export const getUserAuthError = (state: AppState): string =>
  state.ui.users.error;
export const getUsers = (state: AppState): User[] => state.ui.users.users;
