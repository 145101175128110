import React, { useEffect, ReactNode } from "react";
import {
  Switch,
  withRouter,
  RouteComponentProps,
  Route,
} from "react-router-dom";
import ApiEditor from "./APIEditor";
import QueryEditor from "./QueryEditor";
import DataSourceEditor from "./DataSourceEditor";

import CurlImportForm from "./APIEditor/CurlImportForm";
import ProviderTemplates from "./APIEditor/ProviderTemplates";
import { ReactComponent as Arrow } from "../../assets/images/work_space/arrow-header.svg";
import {
  API_EDITOR_ID_URL,
  API_EDITOR_URL,
  QUERIES_EDITOR_URL,
  QUERIES_EDITOR_ID_URL,
  DATA_SOURCES_EDITOR_URL,
  DATA_SOURCES_EDITOR_ID_URL,
  BUILDER_PAGE_URL,
  BuilderRouteParams,
  APIEditorRouteParams,
  getCurlImportPageURL,
  API_EDITOR_URL_WITH_SELECTED_PAGE_ID,
  getProviderTemplatesURL,
} from "constants/routes";
import styled from "styled-components";
import {
  useShowPropertyPane,
  useWidgetSelection,
} from "utils/hooks/dragResizeHooks";
import { closeAllModals } from "actions/widgetActions";
import { useDispatch } from "react-redux";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";

import * as Sentry from "@sentry/react";

const SentryRoute = Sentry.withSentryRouting(Route);

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => (!props.isVisible ? "0px" : "100%")};
  height: calc(100vh - ${props => props.theme.headerHeight});
  background-color: ${props =>
    props.isVisible ? "rgba(0, 0, 0, 0.26)" : "transparent"};
  z-index: ${props => (props.isVisible ? 2 : -1)};
`;

const DrawerWrapper = styled.div<{
  isVisible: boolean;
}>`
  background-color: white;
  width: ${props => (!props.isVisible ? "0px" : "100%")};
  height: 100%;
`;
const CloseDrawer = styled.div`
  background-color: white;
  margin-left: 19px;
  margin-top: 0px;
  padding-top: 10px;
  font-weight: 600;
  font-size: 14px;
  font-family: "Roboto";
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: rotate(180deg);
    margin-right: 4px;
    width: 20px;
    height: 14px;
  }
`;

interface RouterState {
  isVisible: boolean;
}

class EditorsRouter extends React.Component<
  RouteComponentProps<BuilderRouteParams>,
  RouterState
> {
  constructor(props: RouteComponentProps<APIEditorRouteParams>) {
    super(props);
    const { applicationId, pageId } = this.props.match.params;
    this.state = {
      isVisible:
        this.props.location.pathname !==
        BUILDER_PAGE_URL(applicationId, pageId),
    };
  }

  componentDidUpdate(prevProps: Readonly<RouteComponentProps>): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const { applicationId, pageId } = this.props.match.params;
      this.setState({
        isVisible:
          this.props.location.pathname !==
          BUILDER_PAGE_URL(applicationId, pageId),
      });
    }
  }

  handleClose = (e: React.MouseEvent) => {
    PerformanceTracker.startTracking(
      PerformanceTransactionName.CLOSE_SIDE_PANE,
      { path: this.props.location.pathname },
    );
    e.stopPropagation();
    const { applicationId, pageId } = this.props.match.params;
    this.setState({
      isVisible: false,
    });
    this.props.history.replace(BUILDER_PAGE_URL(applicationId, pageId));
  };

  preventClose = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  render(): React.ReactNode {
    return (
      <Wrapper isVisible={this.state.isVisible} onClick={this.handleClose}>
        <PaneDrawer
          isVisible={this.state.isVisible}
          onClick={this.preventClose}
          close={this.handleClose}
        >
          <Switch>
            <SentryRoute exact path={API_EDITOR_URL()} component={ApiEditor} />
            <SentryRoute
              exact
              path={API_EDITOR_ID_URL()}
              component={ApiEditor}
            />
            <SentryRoute
              exact
              path={API_EDITOR_URL_WITH_SELECTED_PAGE_ID()}
              component={ApiEditor}
            />
            <SentryRoute
              exact
              path={QUERIES_EDITOR_URL()}
              component={QueryEditor}
            />
            <SentryRoute
              exact
              path={QUERIES_EDITOR_ID_URL()}
              component={QueryEditor}
            />

            <SentryRoute
              exact
              path={getCurlImportPageURL()}
              component={CurlImportForm}
            />
            <SentryRoute
              exact
              path={DATA_SOURCES_EDITOR_URL()}
              component={DataSourceEditor}
            />
            <SentryRoute
              exact
              path={DATA_SOURCES_EDITOR_ID_URL()}
              component={DataSourceEditor}
            />
            <SentryRoute
              exact
              path={getProviderTemplatesURL()}
              component={ProviderTemplates}
            />
          </Switch>
        </PaneDrawer>
      </Wrapper>
    );
  }
}

type PaneDrawerProps = {
  isVisible: boolean;
  onClick: (e: React.MouseEvent) => void;
  children: ReactNode;
  close: (e: React.MouseEvent) => void;
};
const PaneDrawer = (props: PaneDrawerProps) => {
  const showPropertyPane = useShowPropertyPane();
  const { selectWidget, focusWidget } = useWidgetSelection();
  const dispatch = useDispatch();
  useEffect(() => {
    // This pane drawer is only open when NOT on canvas.
    // De-select all widgets
    // Un-focus all widgets
    // Hide property pane
    // Close all modals
    if (props.isVisible) {
      showPropertyPane();
      selectWidget(undefined);
      focusWidget(undefined);
      dispatch(closeAllModals());
    }
  }, [dispatch, props.isVisible, selectWidget, showPropertyPane, focusWidget]);
  return (
    <DrawerWrapper {...props}>
      <CloseDrawer onClick={props.close}>
        <Arrow></Arrow> Back
      </CloseDrawer>
      <div>{props.children}</div>
    </DrawerWrapper>
  );
};

PaneDrawer.displayName = "PaneDrawer";

export default withRouter(EditorsRouter);
