import Axios from "axios";
const baseURL = "https://platform.ubos.tech/api";

interface IUserEvents {
  type: string;
  userId: any;
  userEmail: any;
  additionalData?: any;
}

export const sendUserLogs = (config: IUserEvents) => {
  try {
    Axios.post(`${baseURL}/sendUserEvent`, config);
  } catch (error) {
    console.log("ERROR SEND LOGS", error);
  }
};

interface IMailAfterDownloadPromoTemplate {
  userEmail: string;
}

export const sendMailAfterDownloadPromoTemplate = (
  config: IMailAfterDownloadPromoTemplate,
) => {
  try {
    Axios.post(`${baseURL}/sendMailAfterDownloadPromoTemplate`, config);
  } catch (error) {
    console.log("ERROR sendMailAfterDownloadPromoTemplate", error);
  }
};
