import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import styled from "styled-components";
import Button from "components/editorComponents/Button";
import PageUnavailableImage from "assets/images/404-image.png";
import { APPLICATIONS_URL } from "constants/routes";
import { ReactComponent as WrapperImage } from "../../assets/images/pageNote.svg";
const Wrapper = styled.div`
  text-align: center;
  margin-top: 5%;
  .wrapper_text {
    position: relative;
    bottom: 97px;
    padding-left: 39px;
  }
  .bold-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #6c767e;
  }
  .page-unavailable-img {
    width: 35%;
  }
  .button-position {
    margin: auto;
    font-family: "Roboto";
  }
`;

class PageNotFound extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <>
        <Wrapper>
          <WrapperImage className="page-unavailable-img" />
          <div className="wrapper_text">
            <p className="bold-text">Page not found</p>
            <Button
              filled
              text="Go back to homepage"
              intent="primary"
              icon="arrow-right"
              iconAlignment="right"
              size="small"
              className="button-position"
              onClick={() => this.props.history.push(APPLICATIONS_URL)}
            />
          </div>
        </Wrapper>
      </>
    );
  }
}

export default withRouter(PageNotFound);
