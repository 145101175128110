import React, { memo, useEffect } from "react";
import styled from "styled-components";
import ExplorerSidebar from "pages/Editor/Explorer";
import { PanelStack, Classes } from "@blueprintjs/core";
import { Colors } from "constants/Colors";
import * as Sentry from "@sentry/react";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";

const SidebarWrapper = styled.div`
  background-color: #fbfbfb;
  padding: 0px 0 0 6px;
  width: 100%;
  z-index: 3;
  height: 100%;
  position: absolute;

  color: ${props => props.theme.colors.textOnDarkBG};
  overflow-y: auto;
  & .${Classes.PANEL_STACK} {
    height: 100%;
    .${Classes.PANEL_STACK_VIEW} {
      background: none;
    }
  }
`;

const initialPanel = { component: ExplorerSidebar };

export const Sidebar = memo((props: any) => {
  PerformanceTracker.startTracking(PerformanceTransactionName.SIDE_BAR_MOUNT);
  useEffect(() => {
    PerformanceTracker.stopTracking();
  });
  return (
    <SidebarWrapper className="t--sidebar">
      <PanelStack
        initialPanel={{ component: ExplorerSidebar, props: { ...props } }}
        showPanelHeader={false}
      />
    </SidebarWrapper>
  );
});

Sidebar.displayName = "Sidebar";

export default Sentry.withProfiler(Sidebar);
