import React, { Component } from "react";
import { WidgetAddChild } from "../../actions/pageActions";
import {
  MAIN_CONTAINER_WIDGET_ID,
  WidgetTypes,
} from "../../constants/WidgetConstants";
import { ReduxActionTypes } from "../../constants/ReduxActionConstants";
import { generateReactKey } from "../../utils/generators";
import { connect } from "react-redux";
import { AppState } from "../../reducers";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/duotone-dark.css";
import "codemirror/theme/duotone-light.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/mode/multiplex";
import "codemirror/addon/tern/tern.css";
import ItemTransformersMenu from "./ItemTransformersMenu";
import { BaseButton } from "../designSystems/blueprint/ButtonComponent";
import {
  currentPageId,
  getActions,
  getExistingActionNames,
  getExistingPageNames,
  getExistingWidgetNames,
  getWidgets,
} from "../../sagas/selectors";
import _ from "lodash";
import { ActionData } from "../../reducers/entityReducers/actionsReducer";

type Props = {
  layout?: any;
};
type ReduxProps = {
  listTransformers?: any;
  create?: any;
  createTransforms?: any;
  existingWidgetNames?: any;
  existingActionNames?: any;
  existingPageNames?: any;
};

class TransformersMenu extends Component<Props & ReduxProps, any> {
  state = {
    isOpen: "",
  };
  openItemFunction: any;
  closeItemFunction: any;

  constructor(props: any) {
    super(props);
    this.openItemFunction = this.openItem.bind(this);
    this.closeItemFunction = this.closeItem.bind(this);
  }

  openItem(item: any) {
    this.props.createTransforms(item);
  }

  closeItem() {
    this.setState({ isOpen: "" });
  }

  render() {
    return (
      <div style={{ paddingRight: "10px", paddingLeft: "10px" }}>
        <BaseButton
          icon={"add"}
          text="add Transformers"
          accent={"primary"}
          onClick={() => {
            this.props.create(
              this.props.listTransformers.length,
              this.props.existingWidgetNames,
            );
          }}
        />
        {this.props.listTransformers?.map((i: any) => {
          return (
            <ItemTransformersMenu
              key={i.widgetId}
              open={this.state.isOpen}
              openItem={this.openItemFunction}
              closeItem={this.closeItemFunction}
              existingWidgetNames={this.props.existingWidgetNames}
              item={i}
            />
          );
        })}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any): ReduxProps => ({
  create: (length: any, names: [string]) => {
    dispatch({
      type: ReduxActionTypes.WIDGET_ADD_CHILD,
      payload: createTransform(length, names),
    });
  },
  createTransforms: (item: any) => {
    dispatch({
      type: ReduxActionTypes.SET_LAYOUT_ITEM,
      payload: {
        id: item.widgetId,
        title: item.widgetName,
        component: "transformer",
        props: item,
      },
    });
  },
});
const mapStateToProps = (state: AppState): ReduxProps => {
  return {
    listTransformers: getTransformer(state),
    existingWidgetNames: getExistingWidgetNames(state)
      .concat(getExistingActionNames(state))
      .concat(getExistingPageNames(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransformersMenu);

function createName(names: [string]) {
  let name = "transformer";
  for (let i = 0; i < 1000; i++) {
    const newName = "transformer" + i;
    if (names.indexOf(newName) === -1) {
      name = newName;
      break;
    }
  }
  return name;
}

function createTransform(length: any, names: [string]) {
  const transformWidgetId = generateReactKey();
  const props: WidgetAddChild = {
    widgetId: MAIN_CONTAINER_WIDGET_ID,
    widgetName: createName(names),
    type: WidgetTypes.TRANSFORM_WIDGET,
    newWidgetId: transformWidgetId,
    parentRowSpace: 1,
    parentColumnSpace: 1,
    leftColumn: 0,
    topRow: 0,
    columns: 0,
    rows: 0,
    tabId: "",
  };
  return props;
}

export function getTransformer(state: AppState) {
  const arr = [];
  for (const key in state.entities.canvasWidgets) {
    const item = state.entities.canvasWidgets[key];
    if (item.type == "TRANSFORM_WIDGET") {
      arr.push(item);
    }
  }
  return arr;
}
