import {
  ApplicationPayload,
  Page,
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "constants/ReduxActionConstants";
import {
  EventType,
  ExecuteActionPayload,
  ExecuteActionPayloadEvent,
  PageAction,
} from "constants/ActionConstants";
import * as log from "loglevel";
import {
  all,
  call,
  put,
  putResolve,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import { getDynamicBindings, isDynamicValue } from "utils/DynamicBindingUtils";
import {
  ActionDescription,
  RunActionPayload,
} from "entities/DataTree/dataTreeFactory";
import {
  AppToaster,
  ToastTypeOptions,
} from "components/editorComponents/ToastComponent";
import { executeAction, executeActionError } from "actions/widgetActions";
import {
  getCurrentApplicationId,
  getCurrentPageId,
  getCurrentGlobalStore,
  getPageList,
} from "selectors/editorSelectors";
import _ from "lodash";
import AnalyticsUtil from "utils/AnalyticsUtil";
import history from "utils/history";
import {
  BUILDER_PAGE_URL,
  getApplicationViewerPageURL,
  getClientURL,
} from "constants/routes";
import {
  executeApiActionRequest,
  executeApiActionRequestALL,
  executeApiActionSuccess,
  executeApiActionSuccessAll,
  showRunActionConfirmModal,
  updateAction,
} from "actions/actionActions";
import { Action, RestAction } from "entities/Action";
import ActionAPI, {
  ActionApiResponse,
  ActionResponse,
  ExecuteActionRequest,
  PaginationField,
  Property,
} from "api/ActionAPI";
import {
  getAction,
  getCurrentPageNameByActionId,
  getPageNameByPageId,
  isActionDirty,
  isActionSaving,
} from "selectors/entitiesSelector";
import { AppState } from "reducers";
import { mapToPropList } from "utils/AppsmithUtils";
import { validateResponse } from "sagas/ErrorSagas";
import { ToastType, TypeOptions } from "react-toastify";
import { PLUGIN_TYPE_API } from "constants/ApiEditorConstants";
import { DEFAULT_EXECUTE_ACTION_TIMEOUT_MS } from "constants/ApiConstants";
import {
  updateAndSaveLayout,
  updateAppGlobalStore,
  updateAppStore,
} from "actions/pageActions";
import { getAppStoreName } from "constants/AppConstants";
import downloadjs from "downloadjs";
import { getType, Types } from "utils/TypeHelpers";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "utils/PerformanceTracker";
import { APP_MODE } from "reducers/entityReducers/appReducer";
import {
  getAppMode,
  getCurrentApplication,
} from "selectors/applicationSelectors";
import { evaluateDynamicTrigger, evaluateSingleValue } from "./evaluationsSaga";
import DebugConsole from "../utils/DebugConsole";
import { ENTITY_TYPE } from "../entities/Console";
import LOG_TYPE from "../entities/Console/logtype";
import { IS_DEPLOY } from "../index";
import {
  resetWidgetMetaProperty,
  updateWidgetMetaProperty,
} from "../actions/metaActions";
import { FlattenedWidgetProps } from "../reducers/entityReducers/canvasWidgetsReducer";
import { getEditorConfigs, getWidgets, getWidgetsTree } from "./selectors";
import { getLayoutSavePayload } from "./PageSagas";
import { normalize } from "normalizr";
import { widgetSchema } from "../normalizers/CanvasWidgetsNormalizer";

function* navigateActionSaga(
  action: { pageNameOrUrl: string; params: Record<string, string> },
  event: ExecuteActionPayloadEvent,
) {
  const pageList = yield select(getPageList);
  const applicationId = yield select(getCurrentApplicationId);
  const page = _.find(
    pageList,
    (page: Page) => page.pageName === action.pageNameOrUrl,
  );
  if (page) {
    AnalyticsUtil.logEvent("NAVIGATE", {
      pageName: action.pageNameOrUrl,
      pageParams: action.params,
    });
    const isDeploy = IS_DEPLOY;
    // TODO need to make this check via RENDER_MODE;
    const path =
      history.location.pathname.indexOf("/edit") !== -1
        ? BUILDER_PAGE_URL(applicationId, page.pageId, action.params)
        : !isDeploy
        ? getApplicationViewerPageURL(applicationId, page.pageId, action.params)
        : getClientURL(applicationId, page.pageId, action.params);
    DebugConsole.info({
      text: `navigateTo('${page.pageName}') was triggered`,
      state: {
        params: action.params,
      },
    });
    history.push(path);
    if (event.callback) event.callback({ success: true });
  } else {
    AnalyticsUtil.logEvent("NAVIGATE", {
      navUrl: action.pageNameOrUrl,
    });
    // Add a default protocol if it doesn't exist.
    let url = action.pageNameOrUrl;
    if (url.indexOf("://") === -1) {
      url = "https://" + url;
    }
    window.location.assign(url);
  }
}

function* navigatePageActionSaga(
  action: { pageNameOrUrl: string; params: Record<string, string> },
  event: ExecuteActionPayloadEvent,
) {
  const pageList = yield select(getPageList);
  const applicationId = yield select(getCurrentApplicationId);
  const page = _.find(
    pageList,
    (page: Page) => page.pageName === action.pageNameOrUrl,
  );
  if (page) {
    AnalyticsUtil.logEvent("NAVIGATE", {
      pageName: action.pageNameOrUrl,
      pageParams: action.params,
    });
    // TODO need to make this check via RENDER_MODE;

    const path =
      history.location.pathname.indexOf("/edit") !== -1
        ? BUILDER_PAGE_URL(applicationId, page.pageId, action.params)
        : !IS_DEPLOY
        ? getApplicationViewerPageURL(applicationId, page.pageId, action.params)
        : getClientURL(applicationId, page.pageId, action.params);
    window.location.replace(window.location.origin + path);
    if (event.callback) event.callback({ success: true });
  } else {
    AnalyticsUtil.logEvent("NAVIGATE", {
      navUrl: action.pageNameOrUrl,
    });
    // Add a default protocol if it doesn't exist.
    let url = action.pageNameOrUrl;
    if (url.indexOf("://") === -1) {
      url = "https://" + url;
    }
    window.location.assign(url);
  }
}

function* storeValueLocally(
  action: { key: string; value: string },
  event: ExecuteActionPayloadEvent,
) {
  try {
    const appId = yield select(getCurrentApplicationId);
    const appStoreName = getAppStoreName(appId);
    const existingStore = yield localStorage.getItem(appStoreName) || "{}";
    const storeObj = JSON.parse(existingStore);
    storeObj[action.key] = action.value;
    const storeString = JSON.stringify(storeObj);
    yield localStorage.setItem(appStoreName, storeString);
    yield put(updateAppStore(storeObj));
    DebugConsole.info({
      text: `store('${action.key}', '${action.value}', true)`,
    });
    if (event.callback) event.callback({ success: true });
  } catch (err) {
    if (event.callback) event.callback({ success: false });
  }
}

function* storeGlobalValue(
  action: { key: string; value: string },
  event: ExecuteActionPayloadEvent,
) {
  try {
    const store = yield select(getCurrentGlobalStore);
    const newStore = { ...store, [action.key]: action.value };
    yield put(updateAppGlobalStore(newStore));
    DebugConsole.info({
      text: `storeGlobal('${action.key}', '${action.value}', false)`,
    });
    if (event.callback) event.callback({ success: true });
  } catch (err) {
    if (event.callback) event.callback({ success: false });
  }
}

function* closeMenu(action: { widget: any }, event: ExecuteActionPayloadEvent) {
  try {
    if (history.location.pathname.indexOf("/edit") === -1) {
      let widgetMenu = null;
      if (action.widget && action.widget.widgetId) {
        widgetMenu = action.widget;
        if (action.widget.isOpen) {
          yield put(
            updateWidgetMetaProperty(action.widget.widgetId, "isOpen", false),
          );
          yield put(
            updateWidgetMetaProperty(
              action.widget.widgetId,
              "widthCustom",
              action.widget.rightColumn - action.widget.leftColumn,
            ),
          );
        } else {
          yield put(
            updateWidgetMetaProperty(action.widget.widgetId, "isOpen", true),
          );
        }
      } else {
        if (typeof action.widget === "string") {
          const widgets: [any] = yield select(getWidgetsTree);

          const widget: FlattenedWidgetProps | undefined = Object.values(
            widgets,
          ).find(
            (widget: FlattenedWidgetProps) =>
              widget.widgetName === action.widget,
          );
          if (widget && widget.widgetId) {
            widgetMenu = widget;
            if (widget.isOpen) {
              yield put(
                updateWidgetMetaProperty(widget.widgetId, "isOpen", false),
              );
              yield put(
                updateWidgetMetaProperty(
                  widget.widgetId,
                  "widthCustom",
                  widget.rightColumn - widget.leftColumn,
                ),
              );
            } else {
              yield put(
                updateWidgetMetaProperty(widget.widgetId, "isOpen", true),
              );
            }
          }
        }
      }

      const widgets = yield select(getWidgets);
      const editorConfigs = yield select(getEditorConfigs) as any;
      const savePageRequest = getLayoutSavePayload(widgets, editorConfigs);
      const newDsl = changeDsl(savePageRequest.dsl, widgetMenu);
      const old_dsl = normalize(newDsl, widgetSchema);
      yield put(
        updateAndSaveLayout(
          old_dsl.entities.canvasWidgets ? old_dsl.entities.canvasWidgets : {},
        ),
      );
    }

    if (event.callback) event.callback({ success: true });
  } catch (err) {
    if (event.callback) event.callback({ success: false });
  }
}

export function changeDsl(dsl: any, widgetMenu: any) {
  const width = widgetMenu.rightColumn - widgetMenu.leftColumn;

  const newDsl = _.cloneDeep(dsl);
  if (widgetMenu.isRtl) {
    if (widgetMenu.isOpen) {
      for (const widget of newDsl.children) {
        if (widget.widgetId == widgetMenu.widgetId) {
          widget.leftColumn = widget.rightColumn - 1;
        }
        if (widget.type == "CONTAINER_WIDGET") {
          widget.rightColumn = widget.rightColumn + width - 1;
        }
      }
    } else {
      for (const widget of newDsl.children) {
        if (widget.widgetId == widgetMenu.widgetId) {
          widget.leftColumn = widget.rightColumn - widgetMenu.widthCustom;
        }
        if (widget.type == "CONTAINER_WIDGET") {
          widget.rightColumn = widget.rightColumn - widgetMenu.widthCustom + 1;
        }
      }
    }
  } else {
    if (widgetMenu.isOpen) {
      for (const widget of newDsl.children) {
        if (widget.widgetId == widgetMenu.widgetId) {
          widget.rightColumn = widget.leftColumn + 1;
        }
        if (widget.type == "CONTAINER_WIDGET") {
          widget.leftColumn = widget.leftColumn - width + 1;
        }
      }
    } else {
      for (const widget of newDsl.children) {
        if (widget.widgetId == widgetMenu.widgetId) {
          widget.rightColumn = widget.leftColumn + widgetMenu.widthCustom;
        }
        if (widget.type == "CONTAINER_WIDGET") {
          widget.leftColumn = widget.leftColumn + widgetMenu.widthCustom - 1;
        }
      }
    }
  }

  return newDsl;
}

function* sendSocketMsg(
  action: { key: string; value: string },
  event: ExecuteActionPayloadEvent,
) {
  try {
    yield put({
      type: "MSG",
      payload: action,
    });
    DebugConsole.info({
      text: `sendSocket('${action.key}', '${action.value}', false)`,
    });
    if (event.callback) event.callback({ success: true });
  } catch (err) {
    if (event.callback) event.callback({ success: false });
  }
}

async function downloadSaga(
  action: { data: any; name: string; type: string },
  event: ExecuteActionPayloadEvent,
) {
  try {
    const { data, name, type } = action;
    if (!name) {
      AppToaster.show({
        message: "Download failed. File name was not provided",
        type: "error",
      });

      if (event.callback) event.callback({ success: false });
      return;
    }
    const dataType = getType(data);
    if (dataType === Types.ARRAY || dataType === Types.OBJECT) {
      const jsonString = JSON.stringify(data, null, 2);
      downloadjs(jsonString, name, type);
      DebugConsole.info({
        text: `download('${jsonString}', '${name}', '${type}') was triggered`,
      });
    } else {
      downloadjs(data, name, type);
      DebugConsole.info({
        text: `download('${data}', '${name}', '${type}') was triggered`,
      });
    }
    if (event.callback) event.callback({ success: true });
  } catch (err) {
    AppToaster.show({
      message: `Download failed. ${err}`,
      type: "error",
    });
    if (event.callback) event.callback({ success: false });
  }
}

function* showAlertSaga(
  payload: { message: string; style?: TypeOptions },
  event: ExecuteActionPayloadEvent,
) {
  if (typeof payload.message !== "string") {
    console.error("Toast message needs to be a string");
    if (event.callback) event.callback({ success: false });
    return;
  }
  if (payload.style && !ToastTypeOptions.includes(payload.style)) {
    console.error(
      "Toast type needs to be a one of " + ToastTypeOptions.join(", "),
    );
    if (event.callback) event.callback({ success: false });
    return;
  }
  AppToaster.show({
    message: payload.message,
    type: payload.style,
  });
  DebugConsole.info({
    text: payload.style
      ? `showAlert('${payload.message}', '${payload.style}') was triggered`
      : `showAlert('${payload.message}') was triggered`,
  });
  if (event.callback) event.callback({ success: true });
}

export const getActionTimeout = (
  state: AppState,
  actionId: string,
): number | undefined => {
  const action = _.find(state.entities.actions, a => a.config.id === actionId);
  if (action) {
    const timeout = _.get(
      action,
      "config.actionConfiguration.timeoutInMillisecond",
      DEFAULT_EXECUTE_ACTION_TIMEOUT_MS,
    );
    if (timeout) {
      // Extra timeout padding to account for network calls
      return timeout + 5000;
    }
    return undefined;
  }
  return undefined;
};
const createActionExecutionResponse = (
  response: ActionApiResponse,
): ActionResponse => ({
  ...response.data,
  ...response.clientMeta,
});
const isErrorResponse = (response: ActionApiResponse) => {
  return !response.data.isExecutionSuccess;
};

export function* evaluateDynamicBoundValueSaga(path: string): any {
  return yield call(evaluateSingleValue, `{{${path}}}`);
}

const EXECUTION_PARAM_PATH = "this.params";
const getExecutionParamPath = (key: string) => `${EXECUTION_PARAM_PATH}.${key}`;

export function* getActionParams(
  bindings: string[] | undefined,
  executionParams?: Record<string, any>,
) {
  if (_.isNil(bindings)) return [];
  let dataTreeBindings = bindings;
  // console.log("start action getActionParams 1", bindings, executionParams);
  if (executionParams && Object.keys(executionParams).length) {
    // List of params in the path format
    const executionParamsPathList = Object.keys(executionParams).map(
      getExecutionParamPath,
    );
    const paramSearchRegex = new RegExp(executionParamsPathList.join("|"), "g");
    // Bindings with references to execution params
    const executionBindings = bindings.filter(binding =>
      paramSearchRegex.test(binding),
    );

    // Replace references with values
    const replacedBindings = executionBindings.map(binding => {
      let replaced = binding;
      const matches = binding.match(paramSearchRegex);
      if (matches && matches.length) {
        matches.forEach(match => {
          // we add one for substring index to account for '.'
          const paramKey = match.substring(EXECUTION_PARAM_PATH.length + 1);
          let paramValue = executionParams[paramKey];
          if (paramValue) {
            if (typeof paramValue === "object") {
              paramValue = JSON.stringify(paramValue);
            }
            replaced = replaced.replace(match, paramValue);
          }
        });
      }
      return replaced;
    });
    // Replace binding with replaced bindings for evaluation
    dataTreeBindings = dataTreeBindings.map(key => {
      if (executionBindings.includes(key)) {
        return replacedBindings[executionBindings.indexOf(key)];
      }
      return key;
    });
  }
  // console.log(
  //   "start action getActionParams 1.1",
  //   bindings,
  //   executionParams,
  //   dataTreeBindings,
  // );
  // Evaluate all values
  const values: any = yield all(
    dataTreeBindings.map((binding: string) => {
      return call(evaluateDynamicBoundValueSaga, binding);
    }),
  );
  // console.log(
  //   "start action getActionParams 1.2",
  //   bindings,
  //   executionParams,
  //   values,
  // );
  // convert to object and transform non string values
  const actionParams: Record<string, string> = {};
  bindings.forEach((key, i) => {
    let value = values[i];
    if (typeof value === "object") value = JSON.stringify(value);
    actionParams[key] = value;
  });
  return mapToPropList(actionParams);
}

export function extractBindingsFromAction(action: Action) {
  const bindings: string[] = [];
  action.dynamicBindingPathList.forEach(a => {
    const value = _.get(action, a.key);
    if (isDynamicValue(value)) {
      const { jsSnippets } = getDynamicBindings(value);
      bindings.push(...jsSnippets.filter(jsSnippet => !!jsSnippet));
    }
  });
  return bindings;
}

export function* executeActionSaga(
  apiAction: RunActionPayload,
  event: ExecuteActionPayloadEvent,
) {
  const { actionId, onSuccess, onError, params } = apiAction;
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXECUTE_ACTION,
    {
      actionId: actionId,
    },
    actionId,
  );
  try {
    const api: RestAction = yield select(getAction, actionId);
    const currentApp: ApplicationPayload = yield select(getCurrentApplication);
    AnalyticsUtil.logEvent("EXECUTE_ACTION", {
      type: api.pluginType,
      name: api.name,
      pageId: api.pageId,
      appId: currentApp.id,
      appName: currentApp.name,
      isExampleApp: currentApp.appIsExample,
    });
    if (api.confirmBeforeExecute) {
      const confirmed = yield call(confirmRunActionSaga);
      if (!confirmed) {
        if (event.callback) {
          event.callback({ success: false });
        }
        return;
      }
    }

    yield put(executeApiActionRequest({ id: apiAction.actionId }));
    const actionParams: Property[] = yield call(
      getActionParams,
      api.jsonPathKeys,
      params,
    );
    const pagination =
      event.type === EventType.ON_NEXT_PAGE
        ? "NEXT"
        : event.type === EventType.ON_PREV_PAGE
        ? "PREV"
        : undefined;
    const appMode = yield select(getAppMode);

    const executeActionRequest: ExecuteActionRequest = {
      actionId: actionId,
      params: actionParams,
      paginationField: pagination,
      viewMode: appMode === APP_MODE.PUBLISHED,
    };
    DebugConsole.info({
      text: "Execution started from widget request",
      source: {
        type: ENTITY_TYPE.ACTION,
        name: api.name,
        id: actionId,
      },
      state: api.actionConfiguration,
    });
    const timeout = yield select(getActionTimeout, actionId);
    const response: ActionApiResponse = yield ActionAPI.executeAction(
      executeActionRequest,
      timeout,
    );
    if (response.data.auth_module && response.data.auth_module.code == 401) {
      if (IS_DEPLOY) {
      } else {
        const newUrl =
          window.location.origin +
          getApplicationViewerPageURL(
            response.data.auth_module.app_id,
            response.data.auth_module.login_id,
          );
        window.location.href = newUrl;
      }
    }

    const payload = createActionExecutionResponse(response);
    yield put(
      executeApiActionSuccess({
        id: actionId,
        response: payload,
      }),
    );
    if (isErrorResponse(response)) {
      DebugConsole.error({
        logType: LOG_TYPE.ACTION_EXECUTION_ERROR,
        text: `Execution failed with status ${response.data.statusCode}`,
        source: {
          type: ENTITY_TYPE.ACTION,
          name: api.name,
          id: actionId,
        },
        state: response.data?.request ?? null,
        message: (payload.body as unknown) as string,
      });
      PerformanceTracker.stopAsyncTracking(
        PerformanceTransactionName.EXECUTE_ACTION,
        { failed: true },
        actionId,
      );
      if (onError) {
        yield put(
          executeAction({
            dynamicString: onError,
            event: {
              ...event,
              type: EventType.ON_ERROR,
            },
            responseData: payload,
          }),
        );
      } else {
        if (event.callback) {
          event.callback({ success: false });
        }
      }
      AppToaster.show({
        message:
          api.name + " failed to execute. Please check it's configuration",
        type: "error",
      });
    } else {
      PerformanceTracker.stopAsyncTracking(
        PerformanceTransactionName.EXECUTE_ACTION,
        undefined,
        actionId,
      );
      DebugConsole.info({
        logType: LOG_TYPE.ACTION_EXECUTION_SUCCESS,
        text: "Executed successfully from widget request",
        timeTaken: response.clientMeta.duration,
        source: {
          type: ENTITY_TYPE.ACTION,
          name: api.name,
          id: actionId,
        },
        state: {
          response: payload.body,
          request: response.data.request,
        },
      });
      if (onSuccess) {
        yield put(
          executeAction({
            dynamicString: onSuccess,
            event: {
              ...event,
              type: EventType.ON_SUCCESS,
            },
            responseData: payload,
          }),
        );
      } else {
        if (event.callback) {
          event.callback({ success: true });
        }
      }
    }
    return response;
  } catch (error) {
    yield put(
      executeActionError({
        actionId: actionId,
        error,
      }),
    );
    AppToaster.show({
      message: "Action execution failed",
      type: "error",
    });
    if (onError) {
      yield put(
        executeAction({
          dynamicString: `{{${onError}}}`,
          event: {
            ...event,
            type: EventType.ON_ERROR,
          },
          responseData: {},
        }),
      );
    } else {
      if (event.callback) {
        event.callback({ success: false });
      }
    }
  }
}

function* executeActionTriggers(
  trigger: ActionDescription<any>,
  event: ExecuteActionPayloadEvent,
) {
  try {
    switch (trigger.type) {
      case "RUN_ACTION":
        // console.log("CASEE RUN_ACTION");
        yield call(executeActionSaga, trigger.payload, event);
        break;
      case "NAVIGATE_TO":
        yield call(navigateActionSaga, trigger.payload, event);
        break;
      case "NAVIGATE_TO_PAGE":
        yield call(navigatePageActionSaga, trigger.payload, event);
        break;
      case "SHOW_ALERT":
        yield call(showAlertSaga, trigger.payload, event);
        break;
      case "SHOW_MODAL_BY_NAME":
        yield put(trigger);
        if (event.callback) event.callback({ success: true });
        break;
      case "RESET_WIDGET":
        yield put(trigger);
        if (event.callback) event.callback({ success: true });
        break;
      case "CLOSE_MODAL":
        DebugConsole.info({
          text: `closeModal(${trigger.payload.modalName}) was triggered`,
        });
        yield put(trigger);
        if (event.callback) event.callback({ success: true });
        break;
      case "WIDGET_UPDATE":
        yield put(trigger);
        if (event.callback) event.callback({ success: true });
        break;
      case "STORE_VALUE":
        yield call(storeValueLocally, trigger.payload, event);
        break;
      case "STORE_GLOBAL_VALUE":
        yield call(storeGlobalValue, trigger.payload, event);
        break;
      case "SEND_SOCKET_MSG":
        yield call(sendSocketMsg, trigger.payload, event);
        break;
      case "CLOSE_MENU":
        yield call(closeMenu, trigger.payload, event);
        break;
      case "DOWNLOAD":
        yield call(downloadSaga, trigger.payload, event);
        break;
      default:
        yield put(
          executeActionError({
            error: "Trigger type unknown",
            actionId: "",
          }),
        );
    }
  } catch (e) {
    yield put(
      executeActionError({
        error: "Failed to execute action",
        actionId: "",
      }),
    );
    if (event.callback) event.callback({ success: false });
  }
}

function* executeAppAction(action: ReduxAction<ExecuteActionPayload>) {
  const { dynamicString, event, responseData } = action.payload;
  log.debug({ dynamicString, responseData });

  const triggers = yield call(
    evaluateDynamicTrigger,
    dynamicString,
    responseData,
  );

  log.debug({ triggers });
  if (triggers && triggers.length) {
    yield all(
      triggers.map((trigger: ActionDescription<any>) =>
        call(executeActionTriggers, trigger, event),
      ),
    );
  } else {
    if (event.callback) event.callback({ success: true });
  }
}

function* runActionInitSaga(
  reduxAction: ReduxAction<{
    id: string;
    paginationField: PaginationField;
  }>,
) {
  const action = yield select(getAction, reduxAction.payload.id);

  if (action.confirmBeforeExecute) {
    const confirmed = yield call(confirmRunActionSaga);
    if (!confirmed) return;
  }

  yield put({
    type: ReduxActionTypes.RUN_ACTION_REQUEST,
    payload: reduxAction.payload,
  });
}

function* runActionSaga(
  reduxAction: ReduxAction<{
    id: string;
    paginationField: PaginationField;
  }>,
) {
  try {
    const actionId = reduxAction.payload.id;
    const isSaving = yield select(isActionSaving(actionId));
    const isDirty = yield select(isActionDirty(actionId));
    if (isSaving || isDirty) {
      if (isDirty && !isSaving) {
        yield put(updateAction({ id: actionId }));
      }
      yield take(ReduxActionTypes.UPDATE_ACTION_SUCCESS);
    }
    const actionObject = yield select(getAction, actionId);
    const jsonPathKeys = actionObject.jsonPathKeys;

    const { paginationField } = reduxAction.payload;

    const params = yield call(getActionParams, jsonPathKeys);
    const timeout = yield select(getActionTimeout, actionId);
    const appMode = yield select(getAppMode);
    const viewMode = appMode === APP_MODE.PUBLISHED;

    const datasourceUrl = _.get(
      actionObject,
      "datasource.datasourceConfiguration.url",
    );
    DebugConsole.info({
      text: "Execution started from user request",
      source: {
        type: ENTITY_TYPE.ACTION,
        name: actionObject.name,
        id: actionId,
      },
      state: {
        ...actionObject.actionConfiguration,
        ...(datasourceUrl && {
          url: datasourceUrl,
        }),
      },
    });

    const response: ActionApiResponse = yield ActionAPI.executeAction(
      {
        actionId,
        params,
        paginationField,
        viewMode,
      },
      timeout,
    );

    const isValidResponse = yield validateResponse(response);

    if (isValidResponse) {
      const payload = createActionExecutionResponse(response);

      const pageName = yield select(getCurrentPageNameByActionId, actionId);
      const eventName =
        actionObject.pluginType === PLUGIN_TYPE_API ? "RUN_API" : "RUN_QUERY";

      AnalyticsUtil.logEvent(eventName, {
        actionId,
        actionName: actionObject.name,
        pageName: pageName,
        responseTime: response.clientMeta.duration,
        apiType: "INTERNAL",
      });

      yield put({
        type: ReduxActionTypes.RUN_ACTION_SUCCESS,
        payload: { [actionId]: payload },
      });
      if (payload.isExecutionSuccess) {
        DebugConsole.info({
          logType: LOG_TYPE.ACTION_EXECUTION_SUCCESS,
          text: "Executed successfully from user request",
          timeTaken: response.clientMeta.duration,
          source: {
            type: ENTITY_TYPE.ACTION,
            name: actionObject.name,
            id: actionId,
          },
          state: {
            response: payload.body,
            request: response.data.request,
          },
        });
        AppToaster.show({
          message: "Action run successfully",
          type: ToastType.SUCCESS,
        });
      } else {
        DebugConsole.error({
          logType: LOG_TYPE.ACTION_EXECUTION_ERROR,
          text: `Execution failed with status ${response.data.statusCode}`,
          source: {
            type: ENTITY_TYPE.ACTION,
            name: actionObject.name,
            id: actionId,
          },
          message: !_.isString(payload.body)
            ? JSON.stringify(payload.body)
            : payload.body,
          state: response.data?.request ?? null,
        });
        AppToaster.show({
          message: "Action returned an error response",
          type: ToastType.WARNING,
        });
      }
    } else {
      let error = "An unexpected error occurred";
      if (response.data.body) {
        error = response.data.body.toString();
      }
      DebugConsole.error({
        logType: LOG_TYPE.ACTION_EXECUTION_ERROR,
        text: `Execution failed with status ${response.data.statusCode} `,
        source: {
          type: ENTITY_TYPE.ACTION,
          name: actionObject.name,
          id: actionId,
        },
        state: response.data?.request ?? null,
      });
      yield put({
        type: ReduxActionErrorTypes.RUN_ACTION_ERROR,
        payload: { error, id: reduxAction.payload.id },
      });
    }
  } catch (error) {
    console.error(error);
    yield put({
      type: ReduxActionErrorTypes.RUN_ACTION_ERROR,
      payload: { error, id: reduxAction.payload.id },
    });
  }
}

function* confirmRunActionSaga() {
  yield put(showRunActionConfirmModal(true));

  const { accept } = yield race({
    cancel: take(ReduxActionTypes.CANCEL_RUN_ACTION_CONFIRM_MODAL),
    accept: take(ReduxActionTypes.ACCEPT_RUN_ACTION_CONFIRM_MODAL),
  });

  return !!accept;
}

function* executePageLoadAction(pageAction: PageAction) {
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXECUTE_ACTION,
    {
      actionId: pageAction.id,
    },
    pageAction.id,
    PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
  );
  const pageId = yield select(getCurrentPageId);
  let currentApp: ApplicationPayload = yield select(getCurrentApplication);
  currentApp = currentApp || {};

  yield put(executeApiActionRequest({ id: pageAction.id }));

  // тут найбільша затримка на обрахунок. треба відправити все в пачці.
  const params: Property[] = yield call(
    getActionParams,
    pageAction.jsonPathKeys,
  );

  const appMode = yield select(getAppMode);
  const viewMode = appMode === APP_MODE.PUBLISHED;
  const executeActionRequest: ExecuteActionRequest = {
    actionId: pageAction.id,
    params,
    viewMode,
  };

  // все що вище має виконатися один раз для всіх дій

  AnalyticsUtil.logEvent("EXECUTE_ACTION", {
    type: pageAction.pluginType,
    name: pageAction.name,
    pageId: pageId,
    appId: currentApp.id,
    onPageLoad: true,
    appName: currentApp.name,
    isExampleApp: currentApp.appIsExample,
  });

  const response: ActionApiResponse = yield ActionAPI.executeAction(
    executeActionRequest,
    pageAction.timeoutInMillisecond,
  );

  if (isErrorResponse(response)) {
    let body = _.get(response, "data.body");
    if (body) {
      if (_.isObject(body)) {
        body = JSON.stringify(body);
      }
    }

    DebugConsole.error({
      logType: LOG_TYPE.ACTION_EXECUTION_ERROR,
      text: `Execution failed with status ${response.data.statusCode}`,
      source: {
        type: ENTITY_TYPE.ACTION,
        name: pageAction.name,
        id: pageAction.id,
      },
      state: response.data?.request ?? null,
      message: JSON.stringify(body),
    });

    yield put(
      executeActionError({
        actionId: pageAction.id,
        error: response.responseMeta.error,
        isPageLoad: true,
      }),
    );
    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.EXECUTE_ACTION,
      {
        failed: true,
      },
      pageAction.id,
    );
  } else {
    const payload = createActionExecutionResponse(response);
    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.EXECUTE_ACTION,
      undefined,
      pageAction.id,
    );
    // console.log("start action 3", response, pageAction);
    yield put(
      executeApiActionSuccess({
        id: pageAction.id,
        response: payload,
        isPageLoad: true,
      }),
    );
  }
}

function* executePageLoadActionBatch(pageAction: PageAction, _params: any) {
  //   for (let _i = 0; _i < _actionSet.jsonPathKeys.length; _i++) {
  //     _actionSet.jsonPathKeys[_i] = (params.find((_e:any) => _e.key == _actionSet.jsonPathKeys[_i]) as Property);
  //     console.log("kkkkkk",_i,_actionSet.jsonPathKeys[_i],  params.find((_e:any) => _e.key == _actionSet.jsonPathKeys[_i]))
  //   }

  //   console.log(_actionSet,_jsonPathKeysAll.filter((data:any)=> {_actionSet.jsonPathKeys.includes(data.key)}))
  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.EXECUTE_ACTION,
    {
      actionId: pageAction.id,
    },
    pageAction.id,
    PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
  );

  const pageId = yield select(getCurrentPageId);
  let currentApp: ApplicationPayload = yield select(getCurrentApplication);
  currentApp = currentApp || {};

  yield put(executeApiActionRequest({ id: pageAction.id }));

  // тут найбільша затримка на обрахунок. треба відправити все в пачці.
  const params: Property[] = _params.filter(
    (_e: any) => _e.key == pageAction.jsonPathKeys.find(_j => _e.key == _j),
  );

  const appMode = yield select(getAppMode);
  const viewMode = appMode === APP_MODE.PUBLISHED;
  const executeActionRequest: ExecuteActionRequest = {
    actionId: pageAction.id,
    params,
    viewMode,
  };

  // все що вище має виконатися один раз для всіх дій

  AnalyticsUtil.logEvent("EXECUTE_ACTION", {
    type: pageAction.pluginType,
    name: pageAction.name,
    pageId: pageId,
    appId: currentApp.id,
    onPageLoad: true,
    appName: currentApp.name,
    isExampleApp: currentApp.appIsExample,
  });

  const response: ActionApiResponse = yield ActionAPI.executeAction(
    executeActionRequest,
    pageAction.timeoutInMillisecond,
  );
  if (isErrorResponse(response)) {
    yield put(
      executeActionError({
        actionId: pageAction.id,
        error: response.responseMeta.error,
        isPageLoad: true,
      }),
    );
    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.EXECUTE_ACTION,
      {
        failed: true,
      },
      pageAction.id,
    );
  } else {
    const payload = createActionExecutionResponse(response);
    PerformanceTracker.stopAsyncTracking(
      PerformanceTransactionName.EXECUTE_ACTION,
      undefined,
      pageAction.id,
    );

    yield put(
      executeApiActionSuccess({
        id: pageAction.id,
        response: payload,
        isPageLoad: true,
      }),
    );
  }
}

// function* executePageLoadActionBatchValueEvaluator(pageActions: PageAction[]) {

function* executePageLoadActionsSaga(action: ReduxAction<PageAction[][]>) {
  try {
    const pageActions: any = action.payload;
    const actionCount = _.flatten(pageActions).length;
    const pageId = yield select(getCurrentPageId);
    const appMode = yield select(getAppMode);
    const viewMode = appMode === APP_MODE.PUBLISHED;
    PerformanceTracker.startAsyncTracking(
      PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
      { numActions: actionCount },
    );

    let currentApp: ApplicationPayload = yield select(getCurrentApplication);
    currentApp = currentApp || {};
    const _jsonPathKeysAll: Record<string, any> = [];
    for (const pageAction of pageActions) {
      pageAction[0].jsonPathKeys.forEach((key: any) => {
        if (!_jsonPathKeysAll.includes(key)) _jsonPathKeysAll.push(key);
      });
      //yield put(executeApiActionRequest({ id: pageAction.id }));
    }

    const params: Property[] = yield call(
      getActionParams,
      _jsonPathKeysAll as [],
    );

    const actionForAllLoad = {
      page_id: pageId,
      params: params,
      viewMode: viewMode,
    };
    if (pageActions.length !== 0) {
      yield put(
        executeApiActionRequestALL(
          pageActions.map((item: any) => {
            return item[0]._id;
          }),
        ),
      );
    }
    const responses: any = yield ActionAPI.executeActionAll(actionForAllLoad);

    if (pageActions.length !== 0) {
      yield put(
        executeApiActionSuccessAll(
          responses.map((item: any) => {
            const payload = createActionExecutionResponse(item);
            return {
              id: item.id,
              response: payload,
              isPageLoad: true,
            };
          }),
        ),
      );

      for (const item of responses) {
        if (!item.data.isExecutionSuccess) {
          AppToaster.show({
            message: "Failed to load onPageLoad actions - " + item.name,
            type: ToastType.ERROR,
          });
        }
      }
    }

    // call(executePageLoadActionBatchValueEvaluator, pageActions);
    // console.log(pageActions, params, "test params");
    // for (const actionSet of pageActions) {
    //   // Load all sets in parallel
    //   yield* yield all(
    //     actionSet.map(apiAction =>
    //       call(executePageLoadActionBatch, apiAction, params),
    //     ),
    //   );
    // }
    // PerformanceTracker.stopAsyncTracking(
    //   PerformanceTransactionName.EXECUTE_PAGE_LOAD_ACTIONS,
    // );
  } catch (e) {
    log.error(e);

    AppToaster.show({
      message: "Failed to load onPageLoad actions",
      type: ToastType.ERROR,
    });
  }
}

export function* watchActionExecutionSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.EXECUTE_PAGE_LOAD_ACTIONS,
      executePageLoadActionsSaga,
    ),
    takeEvery(ReduxActionTypes.EXECUTE_ACTION, executeAppAction),
    takeLatest(ReduxActionTypes.RUN_ACTION_REQUEST, runActionSaga),
    takeLatest(ReduxActionTypes.RUN_ACTION_INIT, runActionInitSaga),
  ]);
}
