import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip } from "@blueprintjs/core";
import { generateReactKey } from "utils/generators";
import { openServiceSetting } from "utils/openLayoutItem";
import {
  CopyServiceIcon,
  DeleteServiceIcon,
  OpenServiceIcon,
  SettingsServiceIcon,
} from "../Explorer/ActionButtons";
import { AppState } from "../../../reducers";
import ApiDatabase from "../../../api/ApiDatabase";
import { AppToaster } from "../../../components/editorComponents/ToastComponent";
import { ModalDelete } from "../../../components/designSystems/component/ModalDelete";
import {
  deleteHelmService,
  deleteUi,
} from "../../../components/designSystems/appsmith/help/FileManagerApi";
import { deleteNodeRedPost } from "../../../components/designSystems/appsmith/help/FileManagerApi";
import { ReduxActionTypes } from "../../../constants/ReduxActionConstants";

const openServiceOnDeploy = (link: string, keycloakAccessToken: string) => {
  let url = link;
  if (!url.includes("https://")) {
    url = "https://" + url;
  }

  url = url + `?access_token=${keycloakAccessToken}`;

  window.open(url, "_blank");
};

const copyLinkOnService = (link: string) => {
  let url = link;
  if (!url.includes("https://")) {
    url = "https://" + url;
  }
  navigator && navigator.clipboard.writeText(url);
  AppToaster.show({ type: "success", message: "Copied Url" });
};

const Info = styled.div`
  // width: 60px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: none;
  span {
    height: 100%;
    display: flex;
    align-items: center;
    transition: 300ms;
    &:hover {
      transition: 300ms;
      scale: 1.1;
    }
  }
  svg {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    margin-left: 2px;
  }
`;

const IconWrapp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ServiceActions = ({ currentElement }: IServiceActionsProps) => {
  const dispatch = useDispatch();
  const refModalsDelete = useRef<any>({});

  const keycloakAccessToken = useSelector(
    (state: AppState) => state.ui.users.currentUser?.keycloakAccessToken,
  );

  const currentOrg = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.orgId,
  );

  const currentApplicationId = useSelector(
    (state: AppState) => state.ui.applications.currentApplication?.id,
  );

  const noPermission = currentElement?.actionPermission === "read:services";

  const openModalDelete = useCallback(() => {
    if (refModalsDelete) {
      refModalsDelete.current.openModal();
    }
  }, []);

  const serviceActions = [
    {
      key: generateReactKey(),
      type: "OPEN_DEPLOY_SERVICE",
      title: "Open Live App",
      Icon: <OpenServiceIcon />,
      action: () =>
        openServiceOnDeploy(
          currentElement.datasourceConfiguration.url,
          keycloakAccessToken || "",
        ),
      disabled: currentElement?.disabled,
    },
    {
      key: generateReactKey(),
      type: "SERVICE_SETTINGS",
      title: "Service settings",
      Icon: <SettingsServiceIcon />,
      action: () => openServiceSetting(dispatch, currentElement),
      disabled: noPermission,
    },
    {
      key: generateReactKey(),
      type: "LINK_TO_SERVICE",
      title: "Link to the service",
      Icon: <CopyServiceIcon />,
      action: () =>
        copyLinkOnService(currentElement.datasourceConfiguration.url),
      disabled: false,
    },
    {
      key: generateReactKey(),
      type: "DELETE_SERVICE",
      title: "Delete service",
      Icon: <DeleteServiceIcon />,
      action: () => openModalDelete(),
      disabled: noPermission,
    },
  ];
  const filteredServiceActions = serviceActions.filter(action =>
    currentElement.serviceActions.includes(action.type),
  );

  const deleteService = useCallback(
    async item => {
      openModalDelete();

      const url = item?.datasourceConfiguration?.url;
      let updatedURL = url?.includes("https") ? url : `https://${url}`;

      try {
        let deleteId;
        if (item.type === "ui" || item.type === "theia") {
          const path = "/home/edit/";
          await deleteUi({
            orgId: item.organizationId,
            type: item.type,
            name: item.name,
          });

          deleteId = JSON.stringify({
            id: item.name,
            type: "nodeRed",
            props: `https://${item.datasourceConfiguration.url}${path}`,
          });

          if (item.type === "ui") updatedURL = updatedURL + path;

          if (item.type === "theia") {
            deleteId = JSON.stringify({
              id: item.name,
              type: "nodeRed",
              props: `https://${item.datasourceConfiguration.url}`,
            });
          }
        } else if (item.type === "db") {
          await ApiDatabase.dbDelete({
            application_id: currentApplicationId,
            service_label: item.datasourceConfiguration.service_label,
            id: item.id,
          });
        } else {
          if (item.type === "api") {
            await deleteNodeRedPost(
              {
                service_label: item.settings_data.serviceId,
                orgId: currentOrg,
              },
              keycloakAccessToken || "",
            );
            deleteId = JSON.stringify({
              id: item.name,
              type: "nodeRed",
              props: item.datasourceConfiguration.url,
            });
          } else {
            await deleteHelmService({
              datasourceId: item.id,
            });
            // if (item.datasourceConfiguration.url) {
            //   deleteId = JSON.stringify({
            //     id: item.name,
            //     type: "nodeRed",
            //     props: `https://${item.datasourceConfiguration.url}${item.datasourceConfiguration.path}`,
            //     icon: item.icon
            //   });
            // }
          }
        }

        dispatch({ type: ReduxActionTypes.FETCH_DATASOURCES_INIT });
        dispatch({
          type: ReduxActionTypes.REMOVE_LAYOUT_ITEM,
          payload: {
            id: deleteId,
            title: item.name,
            component: "nodeRed",
            props: updatedURL,
            icon: item?.icon,
          },
        });
      } catch (e) {}
    },
    [dispatch, keycloakAccessToken, currentOrg, currentApplicationId],
  );

  return (
    <Info className="info_collapse">
      {filteredServiceActions.map((action: any) => (
        <Tooltip content={action.title} key={action.key}>
          <IconWrapp onClick={action.action}>{action.Icon}</IconWrapp>
        </Tooltip>
      ))}
      <ModalDelete
        ref={element => (refModalsDelete.current = element)}
        name={`${currentElement?.type.toUpperCase()}  ${currentElement.name}`}
        item={currentElement}
        remove={deleteService}
      />
    </Info>
  );
};

interface IServiceActionsProps {
  currentElement: any;
}

export default ServiceActions;
